import {
  taskSaveComment,
  taskGetAllcommentsById,
  changeStatus,
} from "lib/api/service";
import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";

const AssessmentTaskModal = ({ taskInfo, setFetchLatest }) => {
  const [status, setStatus] = useState("");
  const { selectedTaskData, setIsAssessmentTaskModalOpen } = taskInfo;
  const [comments, setComments] = useState("");
  const [listsOfAllComment, setListsOfAllComment] = useState([]);
  const [errors, setErrors] = useState({});

  const [title, setTitle] = useState(selectedTaskData?.title);
  const [titleDiv, setTitleDiv] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (titleDiv) {
      inputRef.current.focus();
    }
  }, [titleDiv]);

  const commentSchema = Yup.object().shape({
    comments: Yup.string().required("Comment is required"),
  });

  const addCommentForThisTask = async () => {
    try {
      // Validate comments using Yup schema
      await commentSchema.validate({ comments }, { abortEarly: false });

      const req_value = {
        task_id: selectedTaskData?.task_id,
        comment: comments,
        commenter_name: selectedTaskData?.performer_name,
      };

      const response = await taskSaveComment(req_value);
      toast.success(response?.message);
      setComments("");
      fetchNotesById(selectedTaskData?.task_id);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Yup validation error
        const yupErrors = {};
        error.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setErrors(yupErrors);
      } else {
        console.error("Error adding comment:", error);
        toast.error(error?.message || "Something went wrong!");
      }
    }
  };
  const handleKeyUp = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      addCommentForThisTask(event);
    }
  };

  const sendMessage = () => {
    addCommentForThisTask();
  };

  useEffect(() => {
    if (selectedTaskData?.status) {
      setStatus(selectedTaskData.status);
    }
  }, [selectedTaskData]);

  useEffect(() => {
    if (selectedTaskData?.title) {
      setTitle(selectedTaskData.title);
    }
  }, [selectedTaskData]);

  useEffect(() => {
    if (selectedTaskData?.task_id) {
      fetchNotesById(selectedTaskData?.task_id);
    }
  }, [selectedTaskData?.task_id]);

  const fetchNotesById = async (selectedTaskData) => {
    try {
      if (selectedTaskData) {
        const response = await taskGetAllcommentsById(selectedTaskData);
        setListsOfAllComment(response);
        if (setFetchLatest) {
          setFetchLatest(true);
        }
      } else {
        console.error("Something went wrong!");
      }
    } catch (error) {
      console.error("Error fetching notes:" || error);
    }
  };

  if (!selectedTaskData) {
    return null;
  }

  const handleStatusChange = async (event) => {
    setStatus(event.target.value);

    const newStatus = event.target.value;
    const newTitle = title;
    const requestBody = {
      task_id: selectedTaskData?.task_id,
      newStatus: newStatus,
      title: newTitle,
    };
    try {
      const response = await changeStatus(requestBody);
      if (response.status === 200) {
        if (setFetchLatest) {
          setFetchLatest(true);
        }
        setStatus(newStatus);
        toast.success("Task status updated successfully");
      } else {
        toast.error("Failed to change status:");
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const handleSaveTitleChange = async () => {
    const newStatus = status;
    const newTitle = title;
    const requestBody = {
      task_id: selectedTaskData?.task_id,
      newStatus: newStatus,
      title: newTitle,
    };
    try {
      const response = await changeStatus(requestBody);
      if (response.status === 200) {
        if (setFetchLatest) {
          setFetchLatest(true);
        }
        toast.success("Task title updated successfully");
        taskInfo.selectedTaskData.title = newTitle;
        handleTitleDiv();
      } else {
        toast.error("Failed to change status:");
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTitleDiv = () => {
    setTitleDiv(!titleDiv);
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsAssessmentTaskModalOpen(false)}
          ></button>
        </div>
        <div className="modal-body pt-3 pb-5 px-5">
          <div>
            <h1
              style={{
                fontSize: "1.6rem",
                fontWeight: "650",
                marginBottom: "12px",
              }}
            >
              {selectedTaskData?.title}
              {/* {title} */}
              <button
                style={{
                  border: "none",
                  color: "#0b1d66",
                  backgroundColor: "white",
                }}
                onClick={handleTitleDiv}
              >
                <EditIcon />
              </button>
            </h1>
            <div
              style={{
                marginBottom: "12px",
                display: `${titleDiv ? "block" : "none"}`,
              }}
            >
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                ref={inputRef}
                style={{
                  border: "1px solid #ccc",
                  height: "35px",
                  borderRadius: "7px",
                  paddingLeft: "7px",
                  fontSize: "1.5rem",
                  width: "350px",
                }}
              />
              <button
                type="button"
                onClick={handleSaveTitleChange}
                style={{
                  border: "1px solid #ccc",
                  height: "35px",
                  borderRadius: "7px",
                  padding: "0 15px",
                  color: "white",
                  backgroundColor: "seagreen",
                  marginLeft: "10px",
                }}
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleTitleDiv}
                style={{
                  border: "1px solid #ccc",
                  height: "35px",
                  borderRadius: "7px",
                  padding: "0 15px",
                  color: "white",
                  backgroundColor: "#C62E2E",
                  marginLeft: "10px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="headingSec d-flex justify-content-between mb-4">
            <h2 className="mb-0" style={{ fontSize: "1.4rem" }}>
              {selectedTaskData.note}{" "}
              <img src="/images/tinder.png" width={15} alt="" />
            </h2>
          </div>
          <div className="row gy-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="me-2">Date created:</label>
                <p className="mb-0">{selectedTaskData.date}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="me-2">Date updated:</label>
                <p className="mb-0">{selectedTaskData.updated_date}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="me-2">Provider:</label>
                <p className="mb-0">
                  <img src={selectedTaskData.providerImage} alt="" />{" "}
                  {selectedTaskData.provider_name}{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="me-2">Performer:</label>
                <p className="mb-0">
                  <img src={selectedTaskData.performerImage} alt="" />{" "}
                  {selectedTaskData.performer_name}{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="me-2">Patient:</label>
                <p className="mb-0">
                  <img src="images/doctor-pic.png" alt="" />
                  {selectedTaskData.patient_name}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="me-2">Status:</label>
                <Form.Select
                  className="w-50"
                  aria-label="Default select example"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>
                  <option value="In-progress">In-progress</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <hr></hr>
          </div>
          <div className="col-md-12 mb-3">
            <label className="mb-2 fs-5">Leave a comment</label>
            <div className="d-flex align-items-center gap-2">
              <img src="/images/ellipse.png" width={35} alt="" />
              <input
                onKeyUp={handleKeyUp}
                type="email"
                className={`form-control ${
                  errors.comments ? "is-invalid" : ""
                }`}
                value={comments}
                name="comments"
                onChange={(e) => {
                  setComments(e.target.value);
                  setErrors({ ...errors, comments: "" });
                }}
                placeholder="Leave a comment..."
              />

              <img
                src="/images/send.svg"
                className="cursor-pointer"
                onClick={sendMessage}
                alt=""
              />
            </div>
            {errors.comments && (
              <div className="invalid-feedback">{errors.comments}</div>
            )}
          </div>
          <div className="cardPaitent">
            <div className="col-md-12">
              <label className="mb-2 fs-6">
                All comments (
                {listsOfAllComment.length > 0 && listsOfAllComment?.length}):
              </label>
            </div>
            {listsOfAllComment.length > 0 ? (
              listsOfAllComment.map((item, index) => {
                return (
                  <div key={index} className="col-md-12 mb-3">
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0">
                        <img src="/images/doctor-pic.png" alt="" /> {item.name}{" "}
                      </h6>
                    </div>
                    <p className="m-0 fs-6">
                      {item.comment} <b className="text-green">@{item.name}</b>
                    </p>
                    <span className="date">
                      {item.date} {item.time}
                    </span>
                  </div>
                );
              })
            ) : (
              <div className="no-record-found">No record found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentTaskModal;
