import { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import PsychotherapyModal from '../../../../../modals/Document/Group/Active/All/PsychotherapyModal';
import { deleteDocuments } from "lib/api/service";
import toast from "react-hot-toast";

import TableButton from 'components/Document/TableButton/TableButton';
import ScheduleNotes from "../../../../../../src/modals/Schedule/ScheduleNotes";

const Psychotherapy = ({ notes = [], noteUpdateCallback = () => {} }) => {
  
  const [isPsychotherapyModalOpen, setIsPsychotherapyModalOpen] = useState(false);
  const [selectedPsychotherapyData, setSelectedPsychotherapyData] = useState('');

  const [noteData, setNoteData] = useState({ id: 0, noteValue: '' });
  const [showEditNotesModal, setShowEditNotesModal] = useState(false);

  const handleDocumentDelete = async (id) => {
    try {
      const response = await deleteDocuments(id);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setIsPsychotherapyModalOpen(false);
        noteUpdateCallback();
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  return (
    <>
      <div id="psychotherapy">
        <div className="tab-content mt-4" id="pills-tabContent">

          <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className='tableList-2'>
              <table className="table table-borderless" style={{fontSize: '14px'}}>
                <thead>
                  <tr>
                    <th scope="col">Note name</th>
                    <th scope="col">Session Date <img src='images/short-icon.svg' alt='' /></th>
                    <th scope="col">Created <img src='images/short-icon.svg' alt='' /></th>
                    <th scope="col"> <img src='images/short-icon.svg' alt='' /></th>
                  </tr>
                </thead>
                <tbody>
                  {notes && !notes?.length && (
                    <tr>
                      <td className='mt-1'>
                        No notes created.
                      </td>
                    </tr>
                  )}

                  {notes && notes?.map(psychotherapy => (
                    <tr key={psychotherapy.id} className='align-items-center'>
                      <td>
                        <div className='d-flex'>
                          <img src='/images/doc-icon.png' alt='' width="35"/>
                          <div className='ms-3'>
                            <p className='mb-0'>
                              {psychotherapy.note}
                            </p>
                            
                            <Link className="text-decoration-none moreDetails"
                              onClick={() => {
                                setSelectedPsychotherapyData(psychotherapy);
                                setIsPsychotherapyModalOpen(true);
                              }}>
                              See more
                            </Link>
                          </div>
                        </div>
                      </td>

                      <td>
                          {psychotherapy.scheduleCreatedAt}
                      </td>

                      <td>
                        <p className='mb-0'>{psychotherapy.noteCreatedAt}</p>
                        <p style={{opacity: 0.7}}>{psychotherapy.createdTime}</p>
                      </td>

                      <td>
                        <TableButton onClick={() => {
                          setNoteData({
                            id: psychotherapy.id,
                            noteValue: psychotherapy.note
                          });
                          setShowEditNotesModal(true)
                        }} title='Edit' className='me-2'>
                          <img width={40}  src='/images/edit.svg' alt='' />
                        </TableButton>
                        <TableButton onClick={() => {
                          handleDocumentDelete(psychotherapy.id)
                        }} title='Delete'>
                          <img width={40}  src='/images/delete.svg' alt='' />
                        </TableButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ScheduleNotes
        type='edit'
        data={{
          noteId: noteData.id,
          noteValue: noteData.noteValue
        }}
        controls={{
          onClose: () => { setShowEditNotesModal(false) },
          show: showEditNotesModal,
          successCb: noteUpdateCallback
        }}
      />

      <Modal isOpen={isPsychotherapyModalOpen} onRequestClose={() => setIsPsychotherapyModalOpen(false)} className='documents-modal'>
        <PsychotherapyModal
          noteUpdateCallback={noteUpdateCallback}
          psychotherapyDetailInfo={{
          selectedPsychotherapyData: selectedPsychotherapyData,
          setIsPsychotherapyModalOpen: setIsPsychotherapyModalOpen
        }}
        />
      </Modal>

    </>
  );

};

export default Psychotherapy;

