import React, { useState } from "react";
import "./request.css";
import PatientProfile from "./PatientProfile";
import InsuranceBenefits from "./InsuranceBenefits";
import PrimaryConcerns from "./PrimaryConcerns";
import AssignedClinicians from "./AssignedClinicians";
import { saveInsuranceDetails } from "lib/api/service";
import toast from "react-hot-toast";

const RequestTab = ({
  listingallPatients,
  activeDiv,
  setActiveDiv,
  listingProfile,
}) => {
  // const [activeDiv, setActiveDiv] = useState("Patient profile");
  const showDiv = (divId) => {
    setActiveDiv(divId);
  };
  const [benefitsData, setBenefitsData] = useState({
    familyOpp: { firstValue: "", secondValue: "", percentageResult: "0" },
    individualOpp: { firstValue: "", secondValue: "", percentageResult: "0" },
    familyDeductible: {
      firstValue: "",
      secondValue: "",
      percentageResult: "0",
    },
    individualDeductible: {
      firstValue: "",
      secondValue: "",
      percentageResult: "0",
    },
  });
  const [formData, setFormData] = useState({
    coverage1: "",
    copayment1: "",
    coverage2: "",
    copayment2: "",
    coverage3: "",
    copayment3: "",
    coverage4: "",
    copayment4: "",
    coverage5: "",
    copayment5: "",
    coverage6: "",
    deductible: "",
    familyOOP1: "",
    familyOOP2: "",
    individualOOP1: "",
    individualOOP2: "",
    familyDeductible1: "",
    familyDeductible2: "",
    individualDeductible1: "",
    individualDeductible2: "",
  });

  const validateInput = (value) => {
    if (/^[0-9]+(\.[0-9]+)?$/.test(value) || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (!validateInput(value)) {
      toast.error("Invalid number");
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   // Combine formData and benefitsData
  //   const submissionData = {
  //     ...formData,
  //     familyOppPercentage: benefitsData.familyOpp.percentageResult,
  //     individualOppPercentage: benefitsData.individualOpp.percentageResult,
  //     familyDeductiblePercentage: benefitsData.familyDeductible.percentageResult,
  //     individualDeductiblePercentage: benefitsData.individualDeductible.percentageResult,
  //   };

  //   // API call to submit the data
  //   fetch("/api/saveInsuranceData", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(submissionData),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("Successfully submitted", data);
  //     })
  //     .catch((error) => {
  //       console.error("Error submitting the data", error);
  //     });
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData || Object.keys(formData).length === 0) {
      toast.error("Enter Insurance Details");
      return;
    }

    const benefitsDataArray = [
      {
        category: "familyOpp",
        percentageResult: benefitsData.familyOpp?.percentageResult || "0",
        firstValue: benefitsData.familyOpp?.firstValue || "0",
        secondValue: benefitsData.familyOpp?.secondValue || "0",
      },
      {
        category: "individualOpp",
        percentageResult: benefitsData.individualOpp?.percentageResult || "0",
        firstValue: benefitsData.individualOpp?.firstValue || "0",
        secondValue: benefitsData.individualOpp?.secondValue || "0",
      },
      {
        category: "familyDeductible",
        percentageResult:
          benefitsData.familyDeductible?.percentageResult || "0",
        firstValue: benefitsData.familyDeductible?.firstValue || "0",
        secondValue: benefitsData.familyDeductible?.secondValue || "0",
      },
      {
        category: "individualDeductible",
        percentageResult:
          benefitsData.individualDeductible?.percentageResult || "0",
        firstValue: benefitsData.individualDeductible?.firstValue || "0",
        secondValue: benefitsData.individualDeductible?.secondValue || "0",
      },
    ];

    const insuranceBenefitPayload = {
      insurancebenefit: {
        ...formData,
        benefitsDataArray,
      },
    };

    try {
      const patientId = listingallPatients[0]?.id;
      const response = await saveInsuranceDetails(
        patientId,
        insuranceBenefitPayload
      );

      if (response?.message) {
        toast.success(response.message);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving insurance details");
    }
  };

  return (
    <div className="bg-white">
      <div className="main-content">
        <div className="px-3">
          <section>
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-pills navTabUI"
                  id="pills-tab"
                  role="tablist"
                >
                  <li
                    className="nav-item flex-fill rounded-start-3"
                    role="presentation"
                  >
                    <button
                      className="nav-link active py-2"
                      id="pills-single-tab"
                      data-bs-toggle="pill"
                      onClick={() => showDiv("Patient profile")}
                      data-bs-target="#pills-single"
                      type="button"
                      role="tab"
                      aria-controls="pills-single"
                      aria-selected="true"
                    >
                      Patient profile
                    </button>
                  </li>
                  <li
                    className="nav-item flex-fill rounded-end-3"
                    role="presentation"
                  >
                    <button
                      className="nav-link py-2"
                      id="pills-reoccurring-tab"
                      data-bs-toggle="pill"
                      onClick={() => showDiv("Insurance Benefits")}
                      data-bs-target="#pills-reoccurring"
                      type="button"
                      role="tab"
                      aria-controls="pills-reoccurring"
                      aria-selected="false"
                    >
                      Insurance Benefits
                    </button>
                  </li>
                  <li
                    className="nav-item flex-fill rounded-end-3"
                    role="presentation"
                  >
                    <button
                      className="nav-link py-2"
                      id="pills-reoccurring-tab"
                      data-bs-toggle="pill"
                      onClick={() => showDiv("Primary concerns")}
                      data-bs-target="#pills-reoccurring"
                      type="button"
                      role="tab"
                      aria-controls="pills-reoccurring"
                      aria-selected="false"
                    >
                      Primary concerns
                    </button>
                  </li>
                  <li
                    className="nav-item flex-fill rounded-end-3"
                    role="presentation"
                  >
                    <button
                      className="nav-link py-2"
                      id="pills-reoccurring-tab"
                      data-bs-toggle="pill"
                      onClick={() => showDiv("Assigned clinicians")}
                      data-bs-target="#pills-reoccurring"
                      type="button"
                      role="tab"
                      aria-controls="pills-reoccurring"
                      aria-selected="false"
                    >
                      Assigned clinicians
                    </button>
                  </li>
                </ul>
              </div>
              {activeDiv === "Patient profile" && (
                <PatientProfile listingallPatients={listingallPatients} />
              )}
              {activeDiv === "Insurance Benefits" && (
                <form onSubmit={handleSubmit}>
                  <InsuranceBenefits
                    formData={formData}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    setFormData={setFormData}
                    listingallPatients={listingallPatients}
                    benefitsData={benefitsData}
                    setBenefitsData={setBenefitsData}
                    listingProfile={listingProfile}
                  />
                  <button
                    type="submit"
                    style={{ float: "right" }}
                    className="btn text-center mt-4 btn-primary px-5 btnSize rounded-3"
                  >
                    Save Insurance Details
                  </button>
                </form>
              )}
              {activeDiv === "Primary concerns" && (
                <PrimaryConcerns listingallPatients={listingallPatients} />
              )}
              {activeDiv === "Assigned clinicians" && (
                <AssignedClinicians allPatients={listingallPatients} />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RequestTab;
