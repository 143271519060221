import { useState, useEffect } from "react";
import "./Individual.css";
import { useParams } from "react-router-dom";
import Psychotherapy from "../../../../components/Document/Individual/Psychotherapy/Psychotherapy";
import ClinicalTask from "../../../../components/Document/Individual/Clinical/ClinicalTask";
import Other from "../../../../components/Document/Individual/Other/Other";
import Header from "../../../../components/Header/Header";
import Layout from "components/layout/layout";
import ProfileAvatar from "components/common/DBTracker/ProfileAvatar";
import { fetchNotesByUser } from "lib/api/service";

const Individual = () => {
  const { patientId } = useParams();
  const [activeDiv, setActiveDiv] = useState("psychotherapy");
  const [notes, setNotes] = useState([]);
  const [selectedPsychotherapyId, setSelectedPsychotherapyId] = useState(null); // New state

  const userRole = localStorage.getItem("userRole");

  const fetchNotesById = async () => {
    try {
      if (patientId) {
        const response = await fetchNotesByUser({
          patientID: patientId,
          noteType: "therapist",
        });
        setNotes(response?.documentation);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  useEffect(() => {
    if (userRole === 'SuperAdmin') {
      setActiveDiv('clinical');
    }
  }, []);

  useEffect(() => {
    fetchNotesById();
  }, [patientId]);

  const showDiv = (divId) => {
    setActiveDiv(divId);
  };

  const setPsychotherapyActive = () => {
    setActiveDiv("clinical");
  };

  const handlePsychotherapySelect = (id) => {
    setSelectedPsychotherapyId(id);
    setPsychotherapyActive();
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Patient Profile"} />

        <div className="main-content">
          <div className="px-3">
            <section>
              <div className="row">
                <div className="col-md-12">
                  <div className="bg-white rounded-3 p-3">
                    <div className="rounded-3 py-2 px-3 topBar mb-4">
                      <div className="row align-items-center">
                        <div className="col-md-4 px-1">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <ProfileAvatar
                                imageSrc={notes?.imageSrc}
                                patient_name={notes?.patient_name}
                              />
                            </div>
                            <div className="flex-grow-1 ms-4">
                              <div className="d-flex justify-content-between">
                                <h4>{notes?.patient_name}</h4>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <label className="fw-normal">
                                    {notes?.totalnotes} Note(s) |{" "}
                                    <span>
                                      {notes?.documents || 0} Document(s)
                                    </span>
                                  </label>
                                </div>
                                <div className="col-12">
                                  <label className="fw-normal text-secondary">
                                    Last added: <span>{notes?.last_update}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md">
                          <div className="row">
                            <ul
                              className="nav nav-pills justify-content-end navTabUI"
                              id="pills-tab"
                              role="tablist"
                            >
                              {/*Since only Therapist can control this tab, Patient won't be having access to
                              as such using Doctor role to show buttons*/}
                              {userRole === 'Doctor' && (
                                <>
                                  <li className="nav-item bg-light rounded-start-3">
                                    <button
                                      onClick={() => showDiv("psychotherapy")}
                                      className={`nav-link px-4 py-2 w-100 ${activeDiv === "psychotherapy"
                                        ? "active"
                                        : ""
                                        }`}
                                      id="pills-single-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-single"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-single"
                                      aria-selected="true"
                                    >
                                      My Psychotherapy Notes
                                    </button>
                                  </li>

                                  <li className="nav-item bg-light rounded-end-3">
                                    <button
                                      onClick={() => showDiv("clinical")}
                                      className={`nav-link px-4 py-2 w-100 ${activeDiv === "clinical" ? "active" : ""
                                        }`}
                                      id="pills-single-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-single"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-single"
                                      aria-selected="true"
                                    >
                                      My Clinical Documentation
                                    </button>
                                  </li>

                                  <li className="nav-item bg-light rounded-end-3">
                                    <button
                                      onClick={() => showDiv("other")}
                                      className={`nav-link px-4 py-2 w-100 ${activeDiv === "other" ? "active" : ""
                                        }`}
                                      id="pills-single-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-single"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-single"
                                      aria-selected="true"
                                    >
                                      Other Clinical's Documentation
                                    </button>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {activeDiv === "psychotherapy" && (
                      <Psychotherapy
                        data={notes}
                        setPsychotherapyActive={setPsychotherapyActive}
                        handlePsychotherapySelect={handlePsychotherapySelect} // Pass the handler
                        noteUpdateCallback={fetchNotesById}
                      />
                    )}
                    {activeDiv === "clinical" && <ClinicalTask />}
                    {activeDiv === "other" && <Other />}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Individual;
