import styled from 'styled-components';

const TableButton = styled.button`
background-color: transparent;
border: none;
padding: 0;
box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.1);
border-radius: 5px;
background-color: transparent;

&:disabled {
  background-color: transparent;
  box-shadow: none;
}
`;

export default TableButton;