import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";
import GroupOtherModal from "../../../../../modals/Document/Indivisual/Other/GroupOtherModal";
import { documentationPatientNotes } from "lib/api/service";
import { formatDateWithMonth } from "utils";

const GroupNotes = () => {
  const { patientId } = useParams();

  const groupData = [
    {
      name: "LoBri_Nov28psych",
      date: "Nov 28, 2023  2:02PM",
      sessionDate: "Nov 28, 2023  6:00PM-7:00PM",
      time: "2:02PM",
      duration: "1h 45min",
      provider: "Walter Pearlman",
      procedureCode: "9095",
      providerLicense: "LCSW-S #59734",
      typeGroup: "Dialectical Behavioral Therapy",
      providerImage: "../../images/doctor-pic.png",
      notes:
        "Patient participated in DBT group, patient showed distress during process when other...",
    },
    {
      name: "LoBri_Nov28psych",
      date: "Nov 28, 2023  2:02PM",
      sessionDate: "Nov 28, 2023  6:00PM-7:00PM",
      time: "3:02PM",
      duration: "3h 45min",
      provider: "Dr. Johnes",
      procedureCode: "9095",
      providerLicense: "LCSW-S #59734",
      typeGroup: "Dialectical Behavioral Therapy",
      providerImage: "../../images/doctor-pic.png",
      notes:
        "Participated in DBT group, patient showed distress during process when other...",
    },
  ];

  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [selectedGroupData, setSelectedGroupData] = useState("");
  const [clinicalData, setclinicalData] = useState([]);

  useEffect(() => {
    if (patientId) {
      const numericPatientId = Number(patientId);
      fetchNotesById(numericPatientId);
    }
  }, [patientId]);

  const fetchNotesById = async (patientId) => {
    try {
      if (patientId) {
        const response = await documentationPatientNotes({
          patientID: patientId,
          noteType: "group",
        });
        setclinicalData(response?.documentation?.documentation);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };
  return (
    <>
      <div id="psychotherapy">
        <div className="tab-content mt-4" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab">
            <div className="tableList-2">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Note name</th>
                    <th scope="col">
                      Provider <img src="../../images/short-icon.svg" alt="" />
                    </th>
                    <th scope="col">
                      Created <img src="../../images/short-icon.svg" alt="" />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {clinicalData.map((group) => (
                    <tr key={group.id}>
                      <td>
                        <div className="d-flex">
                          <img src="../../images/note.svg" alt="" />
                          <div className="ms-3">
                            <label>{`${
                              group.provider_name
                            }_${formatDateWithMonth(
                              group.createdate
                            )}psych`}</label>
                            <span className="mb-0 w-100 d-block">
                            {group.psychotherapy_note}{" "}
                            </span>
                            <p className="mb-0">
                            {`${
                              group.provider_name
                            }_${formatDateWithMonth(
                              group.createdate
                            )}psych`}
                              {group.notes}
                            </p>
                            <Link
                              className="text-decoration-none moreDetails"
                              onClick={() => {
                                setSelectedGroupData(group);
                                setIsGroupModalOpen(true);
                              }}>
                              See more
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h6 className="mb-0">
                          <img src={group.providerImage} alt="" />
                          {group.provider_name}
                        </h6>
                      </td>

                      <td>
                        {group.createdate} <br />
                        <span>{group.createtime}</span>
                      </td>

                      <td>
                        <img src="../../images/download.svg" alt="" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isGroupModalOpen}
        onRequestClose={() => setIsGroupModalOpen(false)}>
        <GroupOtherModal
          groupInfo={{
            selectedGroupData: selectedGroupData,
            setIsGroupModalOpen: setIsGroupModalOpen,
          }}
        />
      </Modal>
    </>
  );
};

export default GroupNotes;
