import React from "react";
import "./Treatment.css";
const Treatment = () => {
  return (
    <div id="treatmentPlan">
      <div className="headingTable cardPaitent">
        <div className="row">
          <div className="col">
            <h1 className="mb-0">Treatment plan</h1>
          </div>
          <div className="col">
            <div className="row justify-content-end align-items-center">
              <div class="d-flex col-md-auto">
                <label for="staticEmail" class="me-2 col-form-label mb-0">
                  Date:
                </label>
                <div className="col-md">
                  <input type="date" class="form-control" />
                </div>
              </div>
              <div className="col-md-auto">
                <button type="button" className="btn btnEdit">
                  <i className="far fa-edit"></i> Edit plan
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <label>
              Date : <span>Nov 6 2023</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Diagnosis (ICD-10) :{" "}
              <span>
                F33.0 Major Depressive Disorder, severe F41.1 Generalized
                Anxiety Disorder
              </span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Current medications : <span>100mg of Zoloft 1x Daily</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              DOB : <span>12. 21. 1993</span>
            </label>
          </div>
          <div className="col-12 mb-2 py-1">
            <label>
              Patient signed : <span>Nov 8 2023</span>
            </label>
            <button
              type="button"
              className="btn btnEdit ms-3 bg-transparent px-0"
            >
              Send to patient
            </button>
          </div>
          <div className="col-12">
            <label className="text-green">
              The target date (6 months): 01.24.2024
            </label>
          </div>
        </div>
        <div className="manifest">
          <div className="row mb-3">
            <div className="col-md-4">
              <label>Existing Problem</label>
            </div>
            <div className="col-md-8">
              <label>Manifested by</label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>
                <span>
                  Evaluation Tool(s) used (attach to plan) results and dates of
                  meetings/evaluations
                </span>
              </label>
            </div>
            <div className="col-md-8">
              <label>
                <span>
                  See attached Scared Assessment completed on 9/6/2021 which was
                  positive for GAD and School Avoidance
                </span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>
                <span>Manifestations</span>
              </label>
            </div>
            <div className="col-md-8">
              <label>
                <span>
                  Persistent worry about small things, Restless energy, Physical
                  somatic symptoms, sleep problems, Intense fear or worry,
                  Avoidance of social situations, and avoidance of specific
                  places (School)
                </span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>Smart Goal</label>
            </div>
            <div className="col-md-8">
              <label>
                <span>
                  “I want to worry less and feel more confident in decision
                  making”. Jane’s goal is to decrease her overall worry from an
                  8 to a 5 on a 10 point scale. Jane’s overall anxiety will be
                  measured at the start of each session and goal is expected to
                  be achieved in 6 months. LCSW will assist client in meeting
                  her goal by having client participate in Assertiveness
                  training, improve communication skills and participate in CBT
                  to target anxiety.
                </span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>
                <span>Manifestations</span>
              </label>
            </div>
            <div className="col-md-8">
              <label>
                <span>
                  Persistent worry about small things, Restless energy, Physical
                  somatic symptoms, sleep problems, Intense fear or worry,
                  Avoidance of social situations, and avoidance of specific
                  places (School)
                </span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>Action Steps/Objectives</label>
            </div>
            <div className="col-md-8">
              <label>
                <span>
                  Client will identify and clarify nature of fears and symptoms
                  associated with generalized anxiety.
                </span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>Therapist Interventions</label>
            </div>
            <div className="col-md-8">
              <label className="w-100 mb-3">
                <span>
                  LCSW will provide therapy to help Client expose and extinguish
                  irrational beliefs and conclusions that contribute to anxiety.
                </span>
              </label>
              <label className="w-100 mb-3">
                <span>
                  LCSW/LPC will conduct individual therapy to help Client better
                  understand psychological causes of anxiety.
                </span>
              </label>
              <label className="w-100 mb-3">
                <span>
                  LCSW/LPC will assist Client in developing reality based,
                  positive cognitive messages that will increase self-confidence
                  and thereby decrease anxiety.
                </span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>Services for patient</label>
            </div>
            <div className="col-md-8">
              <label>
                <span>
                  Individual Therapy (1x Weekly) <br />
                  De-escalation/Emotional (As Needed) <br />
                  Regulation <br />
                  Group Therapy (Social Skills) (1x monthly)
                </span>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label>Transition Plan</label>
            </div>
            <div className="col-md-8">
              <label className="w-100 mb-3">
                <span className="w-100 d-block">
                  <b>Expected Treatment Length</b>
                </span>
                <span className="w-100">
                  Expected length of treatment at this time is 6 months.
                </span>
              </label>
              <label className="w-100 mb-3">
                <span className="w-100 d-block">
                  <b>Anticipated Posted DC Services</b>
                </span>
                <span className="w-100">
                  Upon discharge client will receive a list of referrals for
                  continued care and will be encouraged to check-in with their
                  PCP.
                </span>
              </label>
              <label className="w-100 mb-3">
                <span className="w-100 d-block">
                  <b>Plan for Transition/Discharge</b>
                </span>
                <span className="w-100">
                  Once Client begins to make progress and reach goals LCSW will
                  begin to implement step-down service and work to decrease the
                  frequency of sessions.
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treatment;
