import React, { useState } from 'react';
import OtherTaskModal from '../../../../../modals/Document/Indivisual/Other/OtherTaskModal';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

const OtherTask = () => {

  const  otherClinicalData = [
    {
      name: "LoBri_Nov28psych",
      date: "Jul 20, 2023",
      time: "2:02PM",
      duration: "1h 45min",
      provider:"Dr. Alvares",
      providerImage:"../../images/doctor-pic.png",
      notes: "Patient participated in DBT group, patient showed distress during process when other..."
    },
    {
      name: "LoBri_Nov28psych",
      date: "Mar 2, 2023",
      time: "3:02PM",
      duration: "3h 45min",
      provider:"Dr. Johnes",
      providerImage:"../../images/doctor-pic.png",
      notes: "Participated in DBT group, patient showed distress during process when other..."
    },
  ];  

  const [ isOtherClinicalModalOpen, setIsOtherClinicalModalOpen ] = useState(false);
  const [selectedOtherClinicalData, setSelectedOtherClinicalData ] = useState('');

                return (
                  <>
                    <div id="appointments">
                      <div className="tab-content mt-4" id="pills-tabContent">

                        <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                          <div className='tableList-2'>
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col">Notes</th>
                                  <th scope="col">Provider <img src='../../images/short-icon.svg' alt='' /></th>
                                  <th scope="col">Created <img src='../../images/short-icon.svg' alt='' /></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                               
                              { otherClinicalData.map( otherClinical => ( 
                                <tr>
                                   <td>
                                   <div className='d-flex'>
                                   <img src='../../images/note.svg' alt='' />
                                     <div className='ms-3'>
                                     
                                     <label>{otherClinical.name }</label>
                                     <span className='mb-0 w-100 d-block'>{otherClinical.notes} </span>

                                    <Link className="text-decoration-none moreDetails" 
                                    onClick={() => {
                                      setSelectedOtherClinicalData( otherClinical );
                                      setIsOtherClinicalModalOpen(true);
                                    }}
                                    >See more</Link>
                                      </div>
                                      </div>
                                    
                                  </td>
                                  <td>
                                    <h6 className='mb-0'><img src={otherClinical.providerImage} alt='' />{otherClinical.provider}</h6>
                                  </td>

                                  <td>
                                    {otherClinical.date} <br /><span>{otherClinical.time}</span>
                                  </td>

                                  <td>
                                    <img src='../../images/download.svg' alt='' />
                                  </td>
                                  
                                 
                                </tr>
                              ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Modal isOpen={isOtherClinicalModalOpen} onRequestClose={() => setIsOtherClinicalModalOpen(false)} >
                      <OtherTaskModal
                      otherClinicalInfo={{
                        setIsOtherClinicalModalOpen: setIsOtherClinicalModalOpen,
                        selectedOtherClinicalData: selectedOtherClinicalData
                      }}
                      />
                    </Modal>

</>
  );

};

export default OtherTask;

