import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useNavigate } from "react-router-dom";
import { getScheduleData } from "../../lib/api/service";
import { mapSchedulesToCalendarEvents } from "../../utils";
import { useLocalStorageGet } from "../../utils/hooks/useLoacalStorage";

import { useEffect, useState } from "react";

import { TextWrapper } from "components/atoms/cards/EventCards";

const getCurrentDateWithTime = (hours, minutes) => {
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date.toISOString();
};

function renderEventContent(eventInfo) {
  const { event } = eventInfo;

  return (
    <div
      className="box_border"
      style={{ borderLeft: event.extendedProps.borderLeft }}
    >
      <TextWrapper>
        <p><b>{event?.extendedProps?.starttime}</b></p>
        <p>{event.title}</p>
      </TextWrapper>
    </div>
  );
}

const DashboardList = () => {
  const {
    rows: { email },
  } = useLocalStorageGet("userDetails");

  const [eventsdata, setEventsData] = useState({ data: [], eventsdata: [] });

  const fetchData = async () => {
    try {
      const response = await getScheduleData({ email: email });
      if (response && response.schedules) {
        const res = mapSchedulesToCalendarEvents(response.schedules);
        setEventsData({ data: response.schedules, eventsdata: res });
      } else {
        console.error("No schedules found in the response", response);
      }
    } catch (error) {
      console.error("Error fetching schedule data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div className="calendar_div">
        <h6 className="btn_primary" onClick={() => navigate("/schedule")}>
          View all
        </h6>
      </div>
      <div>
        <FullCalendar
          plugins={[timeGridPlugin]}
          locale="en"
          initialView="timeGridDay"
          headerToolbar={{
            left: "title",
            center: "",
            right: "",
          }}
          titleFormat={{
            month: "short",
            day: "numeric",
            year: "numeric",
            weekday: "long",
          }}
          timeZone="UTC"
          events={eventsdata?.eventsdata}
          eventContent={renderEventContent}
          editable={true}
          eventResizableFromStart={true}
          droppable={true}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
          }}
        />
      </div>
    </>
  );
};

export default DashboardList;
