import { useState } from 'react';
import ProfileAvatar from "components/common/DBTracker/ProfileAvatar";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ContextMenuButton = styled.button`
background: none;
border: none;
`;

const FolderCard = ({ 
  pathResource = 'individual',
  redirectAllowed = true,
  data,
  showNotes,
  showContextMenu,
  menuItems = [] }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (!data) return null;

  const { id, name, imageSrc, totalnotes, last_update, active, createdAt } = data;

  const createdDate = createdAt || last_update;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    if (item.handler) {
      item.handler(id, active);
    }
    setAnchorEl(null);
  };

  const RedirectComponent = (props) => {

    if (redirectAllowed) {
      return <Link {...props}>{props.children}</Link>
    }

    return <div>{props.children}</div>
  }

  return (
    <div className="col-lg-3" key={id}>
      <div className="bg-white rounded-3 bgFolder">
        <div className="text-end w-100">

          {!showContextMenu && (<div className="pt-4"></div>)}

          {showContextMenu && (
            <>
              <ContextMenuButton onClick={handleClick}>
                <i className="fas fa-ellipsis-h"></i>
              </ContextMenuButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {menuItems?.filter((item) => item?.showItem(data))
                .map((item) => {
                  const { label, id } = item;
                  return (
                    <MenuItem key={id} onClick={() => handleClose(item)} disabled={item?.disabled(data) || false}>
                      {label || item.getLabel(data)}
                    </MenuItem>
                  )
                })}
              </Menu>
            </>)}

        </div>

        <RedirectComponent
          to={`/profile/${pathResource}/${data?.id}`}
          className="text-decoration-none"
          state={data}
        >
          <div className="d-flex mt-2 align-items-center mb-2 gap-3">
            <div className="">
              <ProfileAvatar patient_name={name} imageSrc={imageSrc} />
            </div>
            <h4 className="m-0">{name}</h4>
          </div>
        </RedirectComponent>

        <div className="row row-cols-2">

          {showNotes && (
            <div className="col-12">
              <label className="fw-normal">{totalnotes} Notes</label>
            </div>
          )}

          <div className="col-12">
            <label className="fw-normal text-secondary">
              Last added: {createdDate}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderCard;
