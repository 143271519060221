import { useState, useEffect } from "react";
import "./Group.css";
import { useParams } from "react-router-dom";
import Psychotherapy from "../../../../components/Document/Group/Active/Psychotherapy/Psychotherapy";
import ClinicalTask from "../../../../components/Document/Group/Active/Clinical/ClinicalTask";
import Header from "../../../../components/Header/Header";
import Layout from "components/layout/layout";

import { fetchGroupNotes, fetchGroupDocumentation } from '../../../../lib/api/service';

const GroupTabs = ({ activeDiv, showDiv }) => {
  return (
    <div className="col-md">
      <div className="row">
        <ul
          class="nav nav-pills justify-content-end navTabUI"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item bg-light rounded-start-3">
            <button
              className={`nav-link px-4 py-2 w-100 ${activeDiv === "psychotherapy"
                ? "active"
                : ""
                }`}
              onClick={() => showDiv("psychotherapy")}
              id="pills-single-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-single"
              type="button"
              role="tab"
              aria-controls="pills-single"
              aria-selected="true"
            >
              Group Psychotherapy Notes
            </button>
          </li>

          <li className='nav-item bg-light rounded-end-3'>
            <button
              onClick={() => showDiv('other')}
              className={`nav-link px-4 py-2 w-100 ${activeDiv === 'other' ? 'active' : ''}`}
              id="pills-single-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-single"
              type="button"
              role="tab"
              aria-controls="pills-single"
              aria-selected="true">Group Documentation</button>
          </li>
        </ul>
      </div>
    </div>
  )
}

const Group = () => {
  const { groupId } = useParams();

  const userRole = localStorage.getItem("userRole");

  const [activeDiv, setActiveDiv] = useState("psychotherapy");

  const showDiv = (divId) => {
    setActiveDiv(divId);
  };

  const [groupData, setGroupData] = useState({
    name: '',
    notes: [],
    documentations: [],
    createdAt: null
  });

  const fetchGroupNotesFunc = async () => {
    const _groupData = await fetchGroupNotes(groupId);
    if (_groupData) {
      setGroupData(_groupData);
    }
  }

  const fetchGroupDocumentationFunc = async () => {
    const _groupData = await fetchGroupDocumentation(groupId);
    if (_groupData) {
      setGroupData(_groupData);
    }
  }

  useEffect(() => {
    if (userRole === 'SuperAdmin') {
      //fetch clinical documentation
      setActiveDiv('clinical');
      fetchGroupDocumentationFunc();
    } else if (userRole === 'Doctor') {
      if (activeDiv === 'other') {
        fetchGroupDocumentationFunc();
      } else {
        fetchGroupNotesFunc();
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDiv]);

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Patient Profile"} />

        <div className="main-content">
          <div className="px-3">
            <section>
              <div className="row">
                <div className="col-md-12">
                  {/* <div className='bg-white rounded-3 p-3 cardPaitent'> */}
                  <div className="bg-white rounded-3 p-3">
                    <div className="rounded-3 py-2 px-3 topBar mb-4">
                      <div className="row align-items-center">
                        <div className="col-md-4 px-1">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <img
                                src="/images/iop.svg"
                                alt="Profile Avatar"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                            <div className="flex-grow-1 ms-4">
                              <div className="d-flex justify-content-between">
                                <h4>{groupData.name}</h4>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <label className="fw-normal text-secondary">
                                    Last added: <span>{groupData.createdAt}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Admins will only see Clinical Documentation */}
                        {userRole === 'Doctor' && (
                         <GroupTabs {...{activeDiv, showDiv}}/>
                        )}
                      </div>
                    </div>
                    {activeDiv === "psychotherapy" && <Psychotherapy 
                      noteUpdateCallback={fetchGroupNotesFunc}
                      notes={groupData.notes} />}
                    {activeDiv === "other" && <ClinicalTask documentations={groupData.documentations}/>}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Group;
