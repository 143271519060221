import styled from 'styled-components';

const StatusTag = styled.div`
width: 82px;
height: 30px;
padding: 7px 6px;
gap: 10px;
border-radius: 5px;
text-transform: capitalize;
font-family: Open Sans;
font-size: 12px;
font-weight: 600;
line-height: 18px;
text-align: center;
`;

const IncompleteTag = styled(StatusTag)`
background: rgba(254, 163, 36, 0.1);
color: #FEA324;
`;
const CompleteTag = styled(StatusTag)`
background: rgba(0, 187, 97, 0.1);
color: #00BB61;
`;
const UnstartedTag = styled(StatusTag)`
background: rgba(255, 36, 36, 0.1);
color: #FF2424;
`;

export { IncompleteTag, CompleteTag, UnstartedTag };