import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Patients from "./pages/Patients/Patients";
import Login from "./pages/Login/Login";
import Signup from "./pages/Login/Signup";
import PatientProfile from "./pages/Patients/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import Discharge from "./pages/Patients/Discharge/Discharge";
import NotificationsList from "./pages/Notifications/Notifications";
import "./App.css";
import { ProfileProvider } from "./context/ProfileProvider";
import { DischargeProvider } from "./context/DischargeProvider";
import NewPatient from "./pages/Patients/NewPatient/NewPatient";
import { PatientIntakeProvider } from "./context/PatientIntakeProvider";
import { Toaster } from "react-hot-toast";
import Document from "./pages/Document/Document";
import Individual from "./pages/Document/Profile/Individual/Individual";
import Group from "./pages/Document/Profile/Group/Group";
import { PrivateRoute } from "./components/PrivateRoute";
import LoginPrivateRoute from "./components/LoginPrivateRoute";
import { DocumentUploadProvider } from "./context/DocumentUploadProvider";
import { MedicationProvider } from "./context/MedicationProvider";
import DirectCallApp from "DirectCallApp";
import GroupCallApp from "GroupCallApp";
import { AssessmentsProvider } from "context/AssessmentsProvider";
import Schedule from "./pages/Schedule/Schedule";
import ScheduleAssissment from "pages/Schedule/ScheduleAssissment";
import SchedulePostAssissment from "pages/Schedule/SchedulePostAssissment";
import PatientRequests from "pages/Patients/Requests/PatientRequests";
import AssessmentsMain from "pages/Patients/Assessments/AssessmentsMain";
import DBTTracker from "pages/Patients/DBTTracker/DBTTracker";
import Billing from "pages/Admin/Billing/Billing";
import PatientBilling from "pages/Patients/PatientBilling/PatientBilling";
import Dashboard from "pages/Dashboard/Dashboard";
import AdminPatients from "pages/AdminPatients/AdminPatients";
import Messages from "pages/Messages/Messages";
import TherapistPatient from "pages/TherapistPatient/TherapistPatient";
import PatientsSignUp from "pages/PatientsSignUp/PatientsSignUp";
import ForgotPassword from "pages/Login/ForgotPassword";
import PatientsDashboard from "pages/Patients/Dashboard/PatientsDashboard";
import UserManagement from "pages/UserManagement/UserManagement";
import TherapistDashboard from "pages/Admin/Dashboard/TherapistDashboard";
import ResetPassword from "pages/Login/ResetPassword";
import PatientAssessment from "components/Patient/PatientAssessment/PatientAssessment";
import { SocketProvider } from "context/chatContext/SocketContext";
import { MantineProvider } from "@mantine/core";
import PaperWork from "pages/Patients/Discharge/Dischargepaperwork/PaperWork";
import IntakePaperWork from "pages/Patients/NewPatient/IntakePaperWork/IntakePaperWork";
const App = () => {
  return (
    <div className="content">
      <>
        <ProfileProvider>
          <DocumentUploadProvider>
            <PatientIntakeProvider>
              <DischargeProvider>
                <MedicationProvider>
                  <AssessmentsProvider>
                    <Router>
                      <Toaster />
                      <Routes>
                        {/* <Route
                          path="/chat"
                          element={
                           <>
                           <MantineProvider>
                            <SocketProvider>
                              <MessengerChat />
                            </SocketProvider>
                            </MantineProvider>
                           </>
                          }
                        /> */}
                        <Route
                          path="/login"
                          element={
                            <LoginPrivateRoute>
                              <Login />
                            </LoginPrivateRoute>
                          }
                        />
                        <Route
                          path="/signup"
                          element={
                            <LoginPrivateRoute>
                              <Signup />
                            </LoginPrivateRoute>
                          }
                        />

                        {/* <Route
                          path="/chat"
                          element={
                            <>
                              <MessengerChat />
                            </>
                          }
                        /> */}
                        {/* MessengerChat */}
                        <Route
                          path="/patients-signup"
                          element={
                            <LoginPrivateRoute>
                              <PatientsSignUp />
                            </LoginPrivateRoute>
                          }
                        />
                        <Route
                          path="/forgot-password"
                          element={
                            <LoginPrivateRoute>
                              <ForgotPassword />
                            </LoginPrivateRoute>
                          }
                        />
                        <Route
                          path="/reset-password"
                          element={
                            <LoginPrivateRoute>
                              <ResetPassword />
                            </LoginPrivateRoute>
                          }
                        />
                        <Route
                          path="/"
                          element={
                            <PrivateRoute>
                              <Dashboard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/therapist-dashboard"
                          element={
                            <PrivateRoute>
                              <TherapistDashboard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/patients-dashboard"
                          element={
                            <PrivateRoute>
                              <PatientsDashboard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/patients"
                          element={
                            <PrivateRoute>
                              <Patients />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/therapist-patients"
                          element={
                            <PrivateRoute>
                              <TherapistPatient />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/all-patients"
                          element={
                            <PrivateRoute>
                              <AdminPatients />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/direct-call"
                          element={
                            <PrivateRoute>
                              <DirectCallApp />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/group-call"
                          element={
                            <PrivateRoute>
                              <GroupCallApp />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/:patientId"
                          element={
                            <PrivateRoute>
                              <PatientProfile />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile"
                          element={
                            <PrivateRoute>
                              <PatientProfile />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/schedule"
                          element={
                            <PrivateRoute>
                              <Schedule />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/schudle-assisment/:id"
                          element={
                            <PrivateRoute>
                              <ScheduleAssissment />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/schudle-post-assisment/:id"
                          element={
                            <PrivateRoute>
                              <SchedulePostAssissment />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path="/settings"
                          element={
                            <PrivateRoute>
                              <Settings />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/discharge/:patientId/*"
                          element={
                            <PrivateRoute>
                              <Discharge />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/dischargepaperwork/:patientId/*"
                          element={
                            <PrivateRoute>
                              <PaperWork />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/newPatient/*"
                          element={
                            <PrivateRoute>
                              <NewPatient />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/newPatient/:patientId/*"
                          element={
                            <PrivateRoute>
                              <NewPatient />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/inatkepaperwork/:patientId/*"
                          element={
                            <PrivateRoute>
                              <IntakePaperWork />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/notifications"
                          element={
                            <PrivateRoute>
                              <NotificationsList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/document"
                          element={
                            <PrivateRoute>
                              <Document />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/individual/:patientId"
                          element={
                            <PrivateRoute>
                              <Individual />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/groups/:groupId"
                          element={
                            <PrivateRoute>
                              <Group />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/requests"
                          element={
                            <PrivateRoute>
                              <PatientRequests />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/assessments"
                          element={
                            <PrivateRoute>
                              <AssessmentsMain />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/patients-assessments"
                          element={
                            <PrivateRoute>
                              <PatientAssessment />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/diarycard"
                          element={
                            <PrivateRoute>
                              <DBTTracker />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/adminBilling"
                          element={
                            <PrivateRoute>
                              <Billing />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/patientBilling"
                          element={
                            <PrivateRoute>
                              <PatientBilling />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/messages"
                          element={
                            <PrivateRoute>
                              <SocketProvider>
                                <Messages />
                              </SocketProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/usermanagement"
                          element={
                            <PrivateRoute>
                              <UserManagement />
                            </PrivateRoute>
                          }
                        />

                        {/* Admin Routing */}
                      </Routes>
                    </Router>
                  </AssessmentsProvider>
                </MedicationProvider>
              </DischargeProvider>
            </PatientIntakeProvider>
          </DocumentUploadProvider>
        </ProfileProvider>
      </>
    </div>
  );
};
export default App;
