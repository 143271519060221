import React, { useEffect, useMemo, useState } from "react";
import { Button, Image } from "react-bootstrap";
import Modal from "react-modal";
import "./Messages.css";
import user from "../../assets/images/user_online.png";
import Calender from "../../assets/images/calender.png";
import SeheduleAppointmentModal from "modals/MessageModal/SeheduleAppointmentModal";
import axios from "axios";
import AddNewMessageModal from "modals/MessageModal/AddNewMessageModal";
import { deleteGroupRoom } from "pages/lib/apis";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const MessagesUserHeader = ({
  deleteCb,
  activeTab,
  userName,
  msgData,
  checkMsgType,
  allPartcipant,
  fetchgroup,
  setFetchgroup,
  setMsgData,
  socket,
  roomId,
  onlineUsers,
  userChatOpenId,
  groupCreator
}) => {
  const userData = localStorage.getItem("sbCalls");
  const englishNameUser = localStorage.getItem("name");
  const userId = JSON.parse(userData)?.userId;
  const token = localStorage.getItem("token");
  const [doctor, setDoctor] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [patient, setPatient] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [groupMessage, setGroupMessage] = useState();
  const [openSeheduleAppointmentModal, setOpenSeheduleAppointmentModal] =
    useState(false);
  const [openAddMessageModal, setOpenAddMessageModal] = useState(false);

  const isUserOnline = (userId) => onlineUsers.has(userId);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Open menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = async (e) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/users/allpatientsdoctors?search=${e?.target?.value ?? ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const doctors = response.data.doctors.map((doctor) => ({
        ...doctor,
        type: "Doctor",
      }));

      const patients = response.data.patients.map((patient) => ({
        ...patient,
        type: "Patient",
      }));
      const admins = response.data.superadmin.map((patient) => ({
        ...patient,
        type: "superadmin",
      }));

      setDoctor(doctors);
      setAdmin(admins)
      setPatient(patients);
      setAllUser([...response.data.doctors, ...response.data.patients]);
    } catch (e) {
      console.error(e);
    }
  };
  const doctorData = useMemo(() => {
    return doctor?.map((d) => {
      return {
        value: [d?.id, `${d.firstName} ${d.lastName}`],
        label: `${d.firstName} ${d.lastName}`,
      };
    });
  }, [doctor]);

  const handleButtonClick = async () => {
    const response = await deleteGroupRoom(token, roomId);
    handleMenuClose();
    if (response.status == '200') {
      deleteCb();
    }

  };

  const patientData = useMemo(() => {
    return patient?.map((d) => {
      return {
        value: [d?.id, `${d.firstName} ${d.lastName}`],
        label: `${d.firstName} ${d.lastName}`,
      };
    });
  }, [patient]);
  const adminData = useMemo(() => {
    return admin?.map((d) => {
      return {
        value: [d?.id, `${d.fullname}`],
        label: `${d.fullname}`,
      };
    });
  }, [admin]);

  const combinedData = useMemo(() => {
    const combined = [];
    if (doctorData) {
      combined.push(...doctorData);
    }
    if (patientData) {
      combined.push(...patientData);
    }
    if(adminData){
      combined.push(...adminData);
    }
    return combined;
  }, [doctorData, patientData, adminData]);

  useEffect(() => {
    handleSearch();
  }, []);

  const userRole = localStorage.getItem("userRole");

  return (
    <>
      <div className="chat_header">
        {msgData && (
          <>
            <div className="position-relative agent_logo">
              {checkMsgType === "groupMsg" ? (
                <img
                  src="/images/GroupProfile.png"
                  className="img-fluid"
                  alt="agent-online"
                />
              ) : (
                <img src={user} className="img-fluid" alt="agent-online" />
              )}
            </div>
            <div className="flex-grow-1 pl-3 chat_agent">
              <h4 className="mb-0 bold-text">{userName ?? "John Doe"}</h4>
              {checkMsgType === "groupMsg" ? (
                <h5
                  onClick={() => {
                    setOpenAddMessageModal(true);
                    setGroupMessage(allPartcipant);
                    // <p>{isUserOnline(userChatOpenId) ? 'Online' : 'Offline'}</p>
                  }}
                  className="cursor-pointer"
                >
                  {allPartcipant?.participants?.length} participants
                </h5>
              ) : (
                <p>{isUserOnline(userChatOpenId) ? "Online" : "Offline"}</p>
              )}
            </div>
            <div className="chat_bedroom d-flex gap-3">
              {activeTab === "THERAPISTS" &&
                !(checkMsgType === "groupMsg" && userRole === "Patient") && (
                  <button
                    variant="light"
                    className="btn_div"
                    onClick={() => setOpenSeheduleAppointmentModal(true)}
                  >
                    <Image src={Calender} alt="img" />{" "}
                    <span className="btn_text">Schedule appointment</span>
                  </button>
                )}

              {userId===groupCreator && 
              <>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>

              {/* Material-UI Menu */}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {/* Menu Items */}
                <MenuItem onClick={handleButtonClick}>Delete</MenuItem>
              </Menu></>
              }
              
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={openSeheduleAppointmentModal}
        onRequestClose={() => setOpenSeheduleAppointmentModal(false)}
      >
        <SeheduleAppointmentModal
          title="Schedule appointment"
          assessmentInfo={{
            setOpenSeheduleAppointmentModal: setOpenSeheduleAppointmentModal,
          }}
        />
      </Modal>

      <Modal
        isOpen={openAddMessageModal}
        onRequestClose={() => setOpenAddMessageModal(false)}
      >
        <AddNewMessageModal
          assessmentInfo={{
            setOpenAddMessageModal: setOpenAddMessageModal,
          }}
          patientDataUser={patientData}
          therapistData={doctorData}
          SuperAdminData={adminData}
          handleSearch={handleSearch}
          activeTabChat={activeTab}
          fetchgroup={fetchgroup}
          setFetchgroup={setFetchgroup}
          roomId={roomId}
          setMsgData={setMsgData}
          socket={socket}
          title={"Update Group"}
          groupMessage={groupMessage}
          allData={combinedData}
        />
      </Modal>
    </>
  );
};

export default MessagesUserHeader;
