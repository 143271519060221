import Nav from "react-bootstrap/Nav";
import "../../App.css";

const MessageTabs = ({ tabs, handleSelect, activeTab }) => {
  const userRole = localStorage.getItem("userRole");
  return (
    <>
       
      <Nav
          variant="tabs"
          activeKey={activeTab}
          onSelect={handleSelect}
          className="w-100 border-0"
        >
          {tabs.filter((tab) => userRole !== "Patient" || tab.eventKey !== "PATIENTS").map((tab) => (
            <Nav.Item key={tab.eventKey}>
              <Nav.Link
                eventKey={tab.eventKey}
                className={`tabs_nav ${
                  activeTab === tab.eventKey ? "active-tab" : "default_tabs"
                }`}
              >
                {tab.label}
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
     
    </>
  );
};

export default MessageTabs;
