export const options = [
  {
    value: "Clinical Consultations",
    label: "Clinical Consultations",
    color: "#5955B3",
  },
  {
    value: "Individual Therapy",
    label: "Individual Therapy",
    color: "#00BB61",
  },
  { value: "Insurance Meeting", label: "Insurance Meeting", color: "#4264D0" },
  {
    value: "Clinical Team Meeting",
    label: "Clinical Team Meeting",
    color: "#FF6760",
  },
  { value: "Group Therapy", label: "Group Therapy", color: "#FFC739" },
];
