import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import RootModal from "modals/RootModal";
import "../../pages/Schedule/Schedule.css";
import { addNotes, updateNote } from "lib/api/service";
import toast from "react-hot-toast";
import { useLocalStorageGet } from "utils/hooks/useLoacalStorage";
import { useEffect, useState } from "react";

// Define the validation schema
const schema = yup.object().shape({
  notes: yup.string().required("Notes cannot be empty"),
});

const ScheduleNotes = ({
  type = 'create',
  data = {},
  controls = {}
  }) => {
  const { scheduleId, noteId, noteValue, updatedData } = data;
  const { onClose, show, successCb } = controls;

  const { rows: { email, Name } } = useLocalStorageGet("userDetails");
  const [awaitingAPIResponse, setAwaitingAPIResponse] = useState(false);
  
  // Initialize react-hook-form
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (type === 'edit') {
      setValue('notes', noteValue);
    }
  }, [noteValue]);

  const updateNotesFunc = async (data) => {
    setAwaitingAPIResponse(true);

    const response = await updateNote({
      id: noteId,
      note: data.notes
    });

    setAwaitingAPIResponse(false);

    if (response.message) {
      if (successCb) { successCb(); }
      toast.success(response.message);
      onClose();
    }

  }

  // Function to handle form submission
  const createNotes = async (data) => {
    const onlyPatients = updatedData?.participants.filter(({type}) => type === 'patient') || [];
    setAwaitingAPIResponse(true);

    const response = await addNotes({
      scheduleId,
      name: Name,
      email: email,
      patients: onlyPatients,
      psychotherapy_note: data.notes,
    });

    setAwaitingAPIResponse(false);

    if (response.message) {
      toast.success(response.message);
      onClose();
    }
  };

  // Define footer buttons for the modal
  const footerButtons = [
    {
      label: "Cancel",
      variant: "secondary",
      onClick: onClose,
      classname: "notes_close_btn",
    },
    {
      label: type === 'create' ? 'Create Note' : 'Update Note',
      variant: "primary",
      onClick: type === 'create' ? 
        handleSubmit(createNotes) :
        handleSubmit(updateNotesFunc) ,
      classname: "notes_create_btn",
      disabled: awaitingAPIResponse
    },
  ];

  return (
    <RootModal show={show} handleClose={onClose} isHeader footerButtons={footerButtons}>
      <div>
        <Form.Control
          as="textarea"
          {...register("notes")}
          style={{ minHeight: "300px" }}
          isInvalid={!!errors.notes}
        />
        {errors.notes && (
          <Form.Text className="text-danger" >
            {errors.notes.message}
          </Form.Text>
        )}
      </div>
    </RootModal>
  );
};

export default ScheduleNotes;
