import React, { useEffect, useState } from "react";
import Header from "components/Header/Header";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import DBTracker from "components/Patient/DBTracker/DBTracker";
import Modal from "react-modal";
import Layout from "components/layout/layout";
import DBSuccessModal from "modals/DBSuccessModal";
import { addDbtCurrentTrack, addDbtSaveTrack } from "lib/api/service";
import toast from "react-hot-toast";
import CommonRadioButton from "utils/CommonRadioButton";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const DBTTracker = () => {
  const [nestedTab, setNestedTab] = useState("nestedTab1");

  const [sliderValueOne, setSliderValueOne] = useState({});
  const [sliderValueTwo, setSliderValueTwo] = useState({});

  const [getDBTTrack, setGetDBTTrack] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState({}); // Object to hold the state for each item

  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState({});
  const [selectedOptionsOne, setSelectedOptionsOne] = useState({});
  const [selectedOptionsTwo, setSelectedOptionsTwo] = useState({});
  const [selectedStatuses, setSelectedStatuses] = useState({}); // Object to hold the status for each item

  const [active, setActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState(checkboxData);

  const handleCheckboxChange = (index) => (event) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = event.target.checked;

    setCheckboxes(updatedCheckboxes);
  };
  const handleNestedTabChange = (tabId) => {
    setNestedTab(tabId);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const removeSliderArray = [
    "relaxation",
    "joyful_activity",
    "medication_compliance",
    "social_interaction",
  ];

  const handleChange = (index, key) => (event) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [index]: event.target.value,
    }));
    if (removeSliderArray.includes(key)) {
      setSelectedStatuses((prevOptions) => ({
        ...prevOptions,
        [index]: event.target.value,
      }));
    }
  };

  const handleChangeOne = (index) => (event) => {
    setSelectedOptionsOne((prevOptions) => ({
      ...prevOptions,
      [index]: event.target.value,
    }));
  };

  const handleStatusChange = (index) => (event) => {
    setSelectedStatuses((prevStatuses) => ({
      ...prevStatuses,
      [index]: event.target.value,
    }));
  };

  // const handleStatusChangeForRemoveSlider = (index) => {
  //   setSelectedStatuses((prevOptions) => ({
  //     ...prevOptions,
  //     [index]: selectedOptions[index],
  //   }));
  // };

  function extractNumberFromTime(timeString) {
    // Define a regular expression to match numbers before "minutes" or "hours"
    const match = timeString.match(/(\d+)(?:\s*(minutes|hours))/);

    if (match) {
      return parseInt(match[1], 10); // Return the numeric part
    }

    return null; // Return null if no match is found
  }
  const renderInputComponent = (field, index) => {
    switch (field.key) {
      case "sleep":
        return (
          <Box sx={{ width: 200 }}>
            <select
              className="form-select"
              value={
                selectedStatuses[index] ?? extractNumberFromTime(field.value)
              }
              onChange={handleStatusChange(index)}
            >
              {selectValues?.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Box>
        );

      case "nightmares":
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={Number(field?.value)}
              getAriaValueText={(e) => {
                valuetext(e ?? 0);
              }}
              valueLabelFormat={valuetext}
              // step={5}
              step={1}
              onChange={(event) => {
                setSelectedOptionsIndex((prevOptions) => ({
                  ...prevOptions,
                  [index]: event.target.value,
                }));
                setSelectedStatuses((prevStatuses) => ({
                  ...prevStatuses,
                  [index]: event.target.value ?? 0,
                }));
              }}
              valueLabelDisplay="auto"
              marks={marks}
              // color="info"
              color={`${
                selectedOptions[index] && selectedStatuses[index] > 0
                  ? "success"
                  : "info"
              }`}
              min={0}
              max={5}
            />
          </Box>
        );

      case "exercise":
        return (
          <Box sx={{ width: 200 }}>
            <select
              className="form-select"
              value={
                selectedStatuses[index] ?? extractNumberFromTime(field.value)
              }
              onChange={handleStatusChange(index)}
            >
              {exerciseTime.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Box>
        );

      case "meditation_minutes":
        return (
          <Box sx={{ width: 200 }}>
            <select
              className="form-select"
              value={
                selectedStatuses[index] ?? extractNumberFromTime(field.value)
              }
              onChange={handleStatusChange(index)}
            >
              {meditationTime?.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Box>
        );

      case "reached_out_for_support":
        return (
          <Box>
            <FormGroup
              sx={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }}
            >
              {checkboxes?.map((item, idx) => {
                return (
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        checked={item.checked}
                        onChange={handleCheckboxChange(idx)}
                      />
                    }
                    label={item.label}
                  />
                );
              })}
            </FormGroup>
          </Box>
        );

      case "stress":
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={Number(field?.value)}
              getAriaValueText={(e) => {
                valuetext(e ?? 0);
              }}
              valueLabelFormat={valuetext}
              onChange={(event, value) => {
                setSelectedOptionsIndex((prevOptions) => ({
                  ...prevOptions,
                  [index]: event.target.value,
                }));
                setSelectedStatuses((prevStatuses) => ({
                  ...prevStatuses,
                  [index]: event.target.value ?? 0,
                }));
              }}
              // step={5}
              step={1}
              valueLabelDisplay="auto"
              marks={marks}
              // color="info"
              color={`${
                selectedOptions[index] && selectedStatuses[index] > 0
                  ? "success"
                  : "info"
              }`}
              min={0}
              max={5}
            />
          </Box>
        );

      case "physical_pain":
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={Number(field?.value)}
              getAriaValueText={(e) => {
                valuetext(e ?? 0);
              }}
              onChange={(event, value) => {
                setSelectedOptionsIndex((prevOptions) => ({
                  ...prevOptions,
                  [index]: event.target.value,
                }));
                setSelectedStatuses((prevStatuses) => ({
                  ...prevStatuses,
                  [index]: event.target.value ?? 0,
                }));
              }}
              step={1}
              // step={5}
              valueLabelDisplay="auto"
              marks={marks}
              // color="info"
              color={`${
                selectedOptions[index] && selectedStatuses[index] > 0
                  ? "success"
                  : "info"
              }`}
              min={0}
              max={5}
            />
          </Box>
        );

      case "relaxation":
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={Number(field?.value)}
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
              onChange={(event, value) => {
                // setSelectedOptionsIndex((prevOptions) => ({
                //   ...prevOptions,
                //   [index]: event.target.value,
                // }));
                // setSelectedStatuses((prevStatuses) => ({
                //   ...prevStatuses,
                //   [index]: event.target.value,
                // }));
              }}
              // step={5}
              step={1}
              valueLabelDisplay="auto"
              marks={marks}
              color="info"
              min={0}
              max={5}
            />
          </Box>
        );

      case "social_interaction":
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={Number(field?.value)}
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
              onChange={(event, value) => {
                // setSelectedOptionsIndex((prevOptions) => ({
                //   ...prevOptions,
                //   [index]: event.target.value,
                // }));
                // setSelectedStatuses((prevStatuses) => ({
                //   ...prevStatuses,
                //   [index]: event.target.value,
                // }));
              }}
              // step={5}
              step={1}
              valueLabelDisplay="auto"
              marks={marks}
              color="info"
              min={0}
              max={5}
            />
          </Box>
        );

      case "joyful_activity":
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={Number(field?.value)}
              getAriaValueText={valuetext}
              onChange={(event, value) => {
                // setSelectedOptionsIndex((prevOptions) => ({
                //   ...prevOptions,
                //   [index]: event.target.value,
                // }));
                // setSelectedStatuses((prevStatuses) => ({
                //   ...prevStatuses,
                //   [index]: event.target.value,
                // }));
              }}
              step={5}
              valueLabelDisplay="auto"
              marks={marks}
              color="info"
            />
          </Box>
        );

      case "medication_compliance":
        return (
          <Box sx={{ width: 200 }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={Number(field?.value)}
              getAriaValueText={valuetext}
              onChange={(event, value) => {
                // setSelectedOptionsIndex((prevOptions) => ({
                //   ...prevOptions,
                //   [index]: event.target.value,
                // }));
                // setSelectedStatuses((prevStatuses) => ({
                //   ...prevStatuses,
                //   [index]: event.target.value,
                // }));
              }}
              step={5}
              valueLabelDisplay="auto"
              marks={marks}
              color="info"
            />
          </Box>
        );

      default:
        return null;
    }
  };

  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  // const marks = [
  //   {
  //     value: 0,
  //     label: "0",
  //   },
  //   {
  //     value: 20,
  //     label: "1",
  //   },
  //   {
  //     value: 40,
  //     label: "2",
  //   },
  //   {
  //     value: 60,
  //     label: "3",
  //   },
  //   {
  //     value: 80,
  //     label: "4",
  //   },
  //   {
  //     value: 100,
  //     label: "5",
  //   },
  // ];

  const marks = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const fieldKeyDuration = ["sleep", "exercise", "meditation_minutes"];

  // const removeSliderArray = [
  //   "relaxation",
  //   "joyful_activity",
  //   "medication_compliance",
  //   "social_interaction",
  // ];

  const behaviorStatus = [
    "relaxation",
    "joyful_activity",
    "medication_compliance",
    "social_interaction",
    "reached_out_for_support",
  ];

  useEffect(() => {
    getAssigned();
  }, []);

  const getAssigned = async () => {
    try {
      const defaultData = await addDbtCurrentTrack({
        currentDate: formattedDate,
      });

      // setGetDBTTrack(defaultData);
      if (defaultData) {
        const defaultOptions = defaultData?.dbtFields?.[1]?.fields?.reduce(
          (acc, item, index) => {
            acc[index] = item?.value > 0 ? "Yes" : "No";
            return acc;
          },
          {}
        );

        const defaultOptionsTwo = defaultData?.dbtFields?.[1]?.fields?.reduce(
          (acc, item, index) => {
            acc[index] = item?.value > 0 ? "Yes" : "No";
            return acc;
          },
          {}
        );

        defaultData?.dbtFields?.[0].fields?.map((item, index) => {
          setSelectedStatuses((prevOptions) => ({
            ...prevOptions,
            [index]: "",
          }));
          item.value = "";
        });

        defaultData?.dbtFields?.[1]?.fields?.map((item, index) => {
          // acc[index] = "";

          setSliderValueOne((prevOptions) => ({
            ...prevOptions,
            // [index]: item?.value,
            [index]: "",
          }));
          // return acc;

          item.value = "";
        }, {});

        setGetDBTTrack(defaultData);

        // defaultData?.dbtFields?.[2]?.fields?.reduce((acc, item, index) => {
        //   acc[index] = item?.value;

        //   setSliderValueTwo((prevOptions) => ({
        //     ...prevOptions,
        //     [index]: item?.value,
        //   }));
        //   return acc;
        // }, {});

        defaultData?.dbtFields?.[0]?.fields?.reduce((acc, item, index) => {
          if (item?.key === "reached_out_for_support" && item?.value != 0) {
            const labelsFromData = JSON?.parse(item?.value)?.map((label) =>
              label?.toLowerCase()
            );

            const baseCheckboxes = [
              { label: "Friends", checked: false },
              { label: "Family", checked: false },
              { label: "Treatment team", checked: false },
            ];

            const updatedCheckboxes = baseCheckboxes.map((checkboxItem) => ({
              ...checkboxItem,
              checked: labelsFromData.includes(
                checkboxItem.label.toLowerCase()
              ),
            }));

            setCheckboxes(updatedCheckboxes);
          }

          const value = item?.value;
          const isZero = value === "0 minutes" || value === "0 hours";
          // const isZero = value === "0" || value === "0";
          const isGreaterThanZero = Number(value) > 0 || value !== "[]";

          // setSelectedOptions((prevOptions) => ({
          //   ...prevOptions,
          //   [index]: isZero ? "No" : isGreaterThanZero ? "Yes" : "No",
          // }));

          return acc;
        }, {});

        // setSelectedOptionsTwo(defaultOptionsTwo);

        // setSelectedOptionsOne(defaultOptions);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  function extractTimeValue(timeString) {
    // Check if the timeString is a simple numeric value
    if (!isNaN(timeString)) {
      return parseInt(timeString, 10);
    }

    // Regular expressions to extract numbers of hours and minutes
    const hoursMatch = timeString.match(/(\d+)\s*hour/);
    const minutesMatch = timeString.match(/(\d+)\s*minute/);

    // Extract the number of hours or minutes, defaulting to 0 if not found
    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    // Return the highest value found, defaulting to 0 if none are found
    if (hours > 0) return hours;
    if (minutes > 0) return minutes;
    return 0;
  }

  // Function to convert the data into the desired format
  const convertToDesiredFormat = (dataOne, dataTwo, dataThree) => {
    // Helper function to format fields based on prefix
    const formatFields = (fields, prefix) => {
      return fields.reduce((acc, field) => {
        // Use key to format the value
        let value = field?.value;
        const dataCheck = extractTimeValue(value);

        // console.log(dataCheck);

        // You can define custom logic here for each key if needed
        if (prefix === "behaviors") {
          switch (field.key) {
            case "sleep":
              // value = `${dataCheck} ${dataCheck <= 19 ? "hours" : "minutes"}`; // Example formatting
              value = value && `${value} hours`;
              break;
            case "exercise":
              // value = `${dataCheck} ${dataCheck <= 19 ? "hours" : "minutes"}`; // Example formatting
              value = value && `${value} minutes`;
              break;
            case "meditation_minutes":
              // value = `${dataCheck} ${dataCheck <= 19 ? "hours" : "minutes"}`; // Example formatting
              value = value && `${value} minutes`;
              break;
            case "reached_out_for_support":
              const transformedData = checkboxes
                ?.filter((item) => item.checked) // Filter items where checked is true
                ?.map((item) => item.label.toLowerCase()); // Convert labels to lowercase

              value = transformedData; // Join the transformed data with commas
              break;
            default:
              value = value; // Keep the original value for other cases
          }
        }
        // Format the key and add to the result
        acc[`${prefix}_${field.key}`] = value;
        return acc;
      }, {});
    };

    // Combine and format all data
    return {
      date_value: formattedDate,
      ...formatFields(dataOne.fields, "behaviors"),
      ...formatFields(dataTwo.fields, "urges"),
      ...formatFields(dataThree.fields, "emotions"),
    };
  };

  const handleSubmitData = async () => {
    const transformBehaviorsData = (
      data,
      statuses,
      groupType,
      selectedOptionsOne
    ) => {
      // Find the "behaviors" type section
      const behaviorsGroup = data.find((group) => group.type === groupType);

      if (!behaviorsGroup) {
        return null; // Return null if "behaviors" section is not found
      }

      // Update fields based on selectedStatuses
      let updatedFields = {};

      if (behaviorsGroup.type !== "urges") {
        updatedFields = behaviorsGroup.fields.map((field, index) => {
          const selectedOptionValue = selectedOptionsOne
            ? selectedOptionsOne[index]
            : undefined;

          // Determine new value
          const statusValue = statuses[index];

          const newValue =
            selectedOptionValue === "Yes"
              ? statusValue === "Yes"
                ? "Yes"
                : statusValue !== undefined
                ? statusValue
                : field.value
              : selectedOptionValue === "No"
              ? statusValue === "No"
                ? "No"
                : "0"
              : field.value;

          // const newValue =
          //   selectedOptionValue === "Yes"
          //     ? statusValue !== undefined
          //       ? Number(statusValue).toString()
          //       : field.value
          //     : selectedOptionValue === "No"
          //     ? "0"
          //     : field.value;

          return { ...field, value: newValue };
        });
      } else {
        updatedFields = behaviorsGroup.fields.map((field, index) => {
          const selectedOptionValue = selectedOptionsOne
            ? selectedOptionsOne[index]
            : undefined;

          const statusValue = statuses[index];

          const newValue =
            selectedOptionValue !== undefined
              ? `${selectedOptionValue}, ${statusValue}`
              : field.value ?? "no response";

          return { ...field, value: newValue };
        });
      }

      // const updatedFields = behaviorsGroup.fields.map((field, index) => {
      //   const selectedOptionValue = selectedOptionsOne
      //     ? selectedOptionsOne[index]
      //     : undefined;

      //   console.log(selectedOptionsOne);
      //   // Determine new value
      //   const statusValue = statuses[index];
      //   console.log(statusValue);

      //   const newValue =
      //     selectedOptionValue === "Yes"
      //       ? statusValue === "Yes"
      //         ? "Yes"
      //         : statusValue !== undefined
      //         ? Number(statusValue).toString()
      //         : field.value
      //       : selectedOptionValue === "No"
      //       ? statusValue === "No"
      //         ? "No"
      //         : "0"
      //       : field.value;

      //   // const newValue =
      //   //   selectedOptionValue === "Yes"
      //   //     ? statusValue !== undefined
      //   //       ? Number(statusValue).toString()
      //   //       : field.value
      //   //     : selectedOptionValue === "No"
      //   //     ? "0"
      //   //     : field.value;

      //   return { ...field, value: newValue };
      // });

      // Return updated "behaviors" section

      return {
        type: groupType,
        fields: updatedFields,
      };
    };

    const clearPreviousStates = () => {
      setSelectedOptions({});
      setSelectedStatuses({});
      setSelectedOptionsOne({});
      setSliderValueOne({});
      setSelectedOptionsTwo({});
      setSliderValueTwo({});
    };

    // Perform the transformation
    const transformedDataOne = transformBehaviorsData(
      getDBTTrack?.dbtFields,
      selectedStatuses,
      "behaviors",
      selectedOptions
    );
    const transformedDataTwo = transformBehaviorsData(
      getDBTTrack?.dbtFields,
      sliderValueOne,
      "urges",
      selectedOptionsOne
    );

    const transformedDatathree = transformBehaviorsData(
      getDBTTrack?.dbtFields,
      sliderValueTwo,
      "emotions",
      selectedOptionsTwo
    );

    const formattedData = convertToDesiredFormat(
      transformedDataOne,
      transformedDataTwo,
      transformedDatathree
    );

    // console.log(transformedDataOne);
    // console.log(formattedData);

    const response = await addDbtSaveTrack(formattedData);
    toast.success(response?.message);
    setOpenModal(true);
    getAssigned();
    clearPreviousStates();
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"DBT tracker"} />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3">
            <div id="clinical">
              <div className="headingTable mb-4 px-3">
                <div className="main-content">
                  <div className="w-100 border-bottom mb-3 d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      {active ? (
                        ""
                      ) : (
                        <>
                          <button
                            className="border-0 bg-transparent ps-0"
                            onClick={() => handleNestedTabChange("nestedTab1")}
                          >
                            <div className="col-md">
                              <ul
                                class="nav nav-pills mb-0 dbSkillTab gap-2"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li class="nav-item" role="presentation">
                                  <button
                                    className={`nav-link ${
                                      nestedTab === "nestedTab1" ? "active" : ""
                                    }`}
                                    id="pills-diryCard-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-diryCard"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-diryCard"
                                    aria-selected={nestedTab === "nestedTab1"}
                                  >
                                    DIARY CARD
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </button>
                          {/* <button
                            className="border-0 bg-transparent"
                            onClick={() => handleNestedTabChange("nestedTab2")}
                          >
                            <div className="col-md">
                              <ul
                                class="nav nav-pills mb-0 dbSkillTab gap-2"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li class="nav-item" role="presentation">
                                  <button
                                    className={`nav-link ${nestedTab === "nestedTab2" ? "active" : ""
                                      }`}
                                    id="pills-skill-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-skill"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-skill"
                                    aria-selected={nestedTab === "nestedTab2"}
                                  >
                                    SKILLS & COMMENTS
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </button> */}
                        </>
                      )}
                    </div>
                    <div className="title-dbttracker d-flex gap-1rem">
                      <div
                        className="d-flex align-items-center"
                        style={{
                          padding: "0px 10px",
                          backgroundColor: "#F8F9FE",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src="/images/dbttracker.svg"
                          width={16}
                          height={16}
                        />
                        <h1
                          onClick={() => setActive(!active)}
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#4264D0",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                        >
                          View my DBT tracker
                        </h1>
                      </div>
                      <button
                        type="button"
                        // onClick={() => setOpenModal(true)}
                        onClick={handleSubmitData}
                        className="btn btn-primary px-5 btnSize rounded-3"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>

                {/* nestedTab1 */}

                {nestedTab === "nestedTab1" && (
                  <>
                    {active ? (
                      <DBTracker />
                    ) : (
                      <>
                        <div class="tab-content">
                          <div
                            class="tab-pane fade show active"
                            aria-labelledby="pills-diryCard-tab"
                          >
                            <div class="table-responsive">
                              <table class="table table-bordered tableCustmization dbt-tracker-table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>{formattedDate}</th>
                                    <th style={{ width: "23%" }}>
                                      Your response
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {getDBTTrack?.dbtFields?.[0]?.fields?.map(
                                      (field, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <div className="text-capitalize">
                                                {field?.key.includes("_")
                                                  ? field?.key.replace(
                                                      /_/g,
                                                      " "
                                                    )
                                                  : field?.key.includes(
                                                      "meditation_minutes"
                                                    )
                                                  ? field?.key.replace(
                                                      /meditation_minutes/,
                                                      "meditation"
                                                    )
                                                  : field?.key}
                                                <br />
                                                {!removeSliderArray.includes(
                                                  field?.key
                                                ) ? (
                                                  fieldKeyDuration.includes(
                                                    field?.key
                                                  ) ? (
                                                    field?.key === "sleep" ? (
                                                      <span>
                                                        duration (hours)
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        duration (minutes)
                                                      </span>
                                                    )
                                                  ) : field?.key !==
                                                    "reached_out_for_support" ? (
                                                    <span>
                                                      yes/no, rate from 1 to 5
                                                    </span>
                                                  ) : (
                                                    <></>
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                                {/* <span>duration (hours)</span> */}
                                              </div>
                                            </td>
                                            <td className=" w-50">
                                              <div className="row align-items-center">
                                                <div className="col-md-4 d-flex">
                                                  <CommonRadioButton
                                                    options={options}
                                                    name={`my-radio-group-${index}`}
                                                    row={true}
                                                    value={
                                                      selectedOptions[index] ||
                                                      ""
                                                    }
                                                    onChange={handleChange(
                                                      index,
                                                      field?.key
                                                    )}
                                                  />
                                                </div>
                                                {selectedOptions[index] ===
                                                  "Yes" && (
                                                  <div className="col-md-4">
                                                    <div className="col-md-4">
                                                      {!removeSliderArray.includes(
                                                        field?.key
                                                      ) ? (
                                                        renderInputComponent(
                                                          field,
                                                          index
                                                        )
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {/* {renderInputComponent(
                                                        field,
                                                        index
                                                      )} */}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </td>
                                            <td>
                                              {!selectedOptions[index] ? (
                                                "No response"
                                              ) : (
                                                <>
                                                  <img
                                                    src={
                                                      selectedOptions[index] ===
                                                      "Yes"
                                                        ? "../../images/checkgreen.svg"
                                                        : "../../images/cros.svg"
                                                    }
                                                    width={11}
                                                    height={11}
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  />
                                                  {selectedOptions[index] ===
                                                  "Yes" ? (
                                                    <>
                                                      <small
                                                        style={{
                                                          color: "#00BB61",
                                                          fontWeight: "500",
                                                        }}
                                                      >
                                                        Yes
                                                      </small>
                                                      {!removeSliderArray.includes(
                                                        field?.key
                                                      ) ? (
                                                        field?.key ===
                                                        "reached_out_for_support" ? (
                                                          <>
                                                            {checkboxes?.map(
                                                              (item) =>
                                                                item.checked && (
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "#00BB61",
                                                                      padding:
                                                                        "0 1px",
                                                                      fontStyle:
                                                                        "normal",
                                                                      fontWeight:
                                                                        "550",
                                                                      fontStyle:
                                                                        "italic",
                                                                    }}
                                                                  >
                                                                    {`, ${item.label}`}
                                                                  </span>
                                                                )
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {selectedStatuses[
                                                              index
                                                            ] ? (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "#00BB61",
                                                                  paddingLeft:
                                                                    "1px",
                                                                  fontStyle:
                                                                    "normal",
                                                                }}
                                                              >
                                                                {`, ${
                                                                  selectedStatuses[
                                                                    index
                                                                  ] || 0
                                                                } ${
                                                                  field?.key ===
                                                                  "sleep"
                                                                    ? "hours"
                                                                    : field?.key ===
                                                                        "exercise" ||
                                                                      field?.key ===
                                                                        "meditation_minutes"
                                                                    ? "minutes"
                                                                    : ""
                                                                }`}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "#00BB61",
                                                                  paddingLeft:
                                                                    "2px",
                                                                  fontStyle:
                                                                    "normal",
                                                                }}
                                                              >
                                                                , 0
                                                              </span>
                                                            )}
                                                          </>
                                                        )
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <small
                                                      style={{ color: "red" }}
                                                    >
                                                      No
                                                    </small>
                                                  )}
                                                </>
                                              )}
                                              {/* {selectedOptions[index] && (
                                                <>
                                                  <img
                                                    src={
                                                      selectedOptions[index] ===
                                                      "Yes"
                                                        ? "../../images/checkgreen.svg"
                                                        : "../../images/cros.svg"
                                                    }
                                                    width={11}
                                                    height={11}
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  />
                                                  {selectedOptions[index] ===
                                                    "Yes" &&
                                                  (selectedStatuses[index] ||
                                                    selectedOptionsIndex[
                                                      index
                                                    ]) ? (
                                                    <small>{`Yes, ${
                                                      selectedOptionsIndex[
                                                        index
                                                      ] &&
                                                      selectedOptions[index]
                                                        ? selectedOptionsIndex[
                                                            index
                                                          ] / 20
                                                        : selectedStatuses[
                                                            index
                                                          ] &&
                                                          selectedStatuses[
                                                            index
                                                          ]
                                                    } ${
                                                      !selectedOptionsIndex[
                                                        index
                                                      ] &&
                                                      selectedOptions[index]
                                                        ? selectedStatuses[
                                                            index
                                                          ] >= 20
                                                          ? "minutes"
                                                          : "hours"
                                                        : ""
                                                    } `}</small>
                                                  ) : (
                                                    <small
                                                      style={{
                                                        color:
                                                          selectedOptions[
                                                            index
                                                          ] === "Yes"
                                                            ? "green"
                                                            : "red",
                                                      }}
                                                    >
                                                      {selectedOptions[index]}
                                                    </small>
                                                  )}

                                                  {!selectedStatuses[index] &&
                                                    !selectedOptionsIndex[
                                                      index
                                                    ] && (
                                                      <small>
                                                        {typeof field?.value ===
                                                          "number" &&
                                                        field.value > 0
                                                          ? selectedOptions[
                                                              index
                                                            ] === "Yes"
                                                            ? `, ${
                                                                field?.value /
                                                                20
                                                              }`
                                                            : ""
                                                          : field?.value &&
                                                            field?.value !==
                                                              "[]"
                                                          ? (() => {
                                                              try {
                                                                let parsedValue;
                                                                if (
                                                                  typeof field?.value ===
                                                                  "string"
                                                                ) {
                                                                  // Check if the string is a JSON array
                                                                  if (
                                                                    field.value
                                                                      .trim()
                                                                      .startsWith(
                                                                        "["
                                                                      ) &&
                                                                    field.value
                                                                      .trim()
                                                                      .endsWith(
                                                                        "]"
                                                                      )
                                                                  ) {
                                                                    try {
                                                                      parsedValue =
                                                                        JSON?.parse(
                                                                          field?.value
                                                                        );
                                                                    } catch (error) {
                                                                      console.error(
                                                                        "Error parsing JSON:",
                                                                        error
                                                                      );
                                                                      parsedValue =
                                                                        field.value;
                                                                    }
                                                                  } else {
                                                                    parsedValue =
                                                                      field.value;
                                                                  }

                                                                  const displayValue =
                                                                    selectedOptions[
                                                                      index
                                                                    ] === "Yes"
                                                                      ? Array.isArray(
                                                                          parsedValue
                                                                        )
                                                                        ? parsedValue.join(
                                                                            ", "
                                                                          ) // Convert array to comma-separated string
                                                                        : parsedValue
                                                                      : "";

                                                                  return selectedOptions[
                                                                    index
                                                                  ] === "Yes"
                                                                    ? `, ${displayValue}`
                                                                    : "";
                                                                } else {
                                                                  return "";
                                                                }
                                                              } catch (e) {
                                                                console.error(
                                                                  "Error handling value:",
                                                                  e
                                                                );
                                                                return "";
                                                              }
                                                            })()
                                                          : ""}
                                                      </small>
                                                    )}
                                                </>
                                              )} */}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                    {/* Usges table */}
                                    {getDBTTrack?.dbtFields?.[1]?.fields?.map(
                                      (field, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <div className="text-capitalize">
                                                {field?.key.includes("_")
                                                  ? field?.key.replace(
                                                      /_/g,
                                                      " "
                                                    )
                                                  : field?.key}
                                                <br />
                                                <span>
                                                  yes/no, rate from 1 to 5
                                                </span>
                                              </div>
                                            </td>
                                            <td className=" w-50">
                                              <div className="row align-items-center">
                                                <div className="col-md-4 d-flex">
                                                  <CommonRadioButton
                                                    options={options}
                                                    name={`my-radio-group-${index}`}
                                                    row={true}
                                                    value={
                                                      selectedOptionsOne[
                                                        index
                                                      ] || ""
                                                      // selectedOptionsOne[
                                                      //   index
                                                      // ] || true
                                                    }
                                                    onChange={handleChangeOne(
                                                      index
                                                    )}
                                                  />
                                                </div>

                                                <div className="col-md-4">
                                                  <Box sx={{ width: 200 }}>
                                                    {selectedOptionsOne[
                                                      index
                                                    ] && (
                                                      <Slider
                                                        aria-label="Custom marks"
                                                        defaultValue={Number(
                                                          field?.value
                                                        )}
                                                        getAriaValueText={(
                                                          e
                                                        ) => {
                                                          valuetext(e ?? 0);
                                                        }}
                                                        onChange={(e) => {
                                                          !selectedOptionsOne[
                                                            index
                                                          ]
                                                            ? toast.error(
                                                                "Please select a response."
                                                              )
                                                            : setSliderValueOne(
                                                                (
                                                                  prevOptions
                                                                ) => ({
                                                                  ...prevOptions,
                                                                  [index]:
                                                                    e?.target
                                                                      ?.value ??
                                                                    0,
                                                                })
                                                              );
                                                        }}
                                                        // step={5}
                                                        step={1}
                                                        valueLabelDisplay="auto"
                                                        marks={marks}
                                                        // color="info"
                                                        color={
                                                          selectedOptionsOne[
                                                            index
                                                          ] === "Yes"
                                                            ? "success"
                                                            : selectedOptionsOne[
                                                                index
                                                              ] === "No"
                                                            ? "error"
                                                            : "info"
                                                        }
                                                        min={0}
                                                        max={5}
                                                      />
                                                    )}
                                                  </Box>
                                                  {/* <Box sx={{ width: 200 }}>
                                                    {selectedOptionsOne[
                                                      index
                                                    ] === "Yes" && (
                                                      <Slider
                                                        aria-label="Custom marks"
                                                        defaultValue={Number(
                                                          field?.value
                                                        )}
                                                        getAriaValueText={(
                                                          e
                                                        ) => {
                                                          valuetext(e ?? 0);
                                                        }}
                                                        onChange={(e) => {
                                                          setSliderValueOne(
                                                            (prevOptions) => ({
                                                              ...prevOptions,
                                                              [index]:
                                                                e?.target
                                                                  ?.value ?? 0,
                                                            })
                                                          );
                                                        }}
                                                        step={5}
                                                        valueLabelDisplay="auto"
                                                        marks={marks}
                                                        color="info"
                                                      />
                                                    )}
                                                  </Box> */}
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              {!selectedOptionsOne[index] ? (
                                                "No response"
                                              ) : (
                                                <>
                                                  <img
                                                    src={
                                                      selectedOptionsOne[
                                                        index
                                                      ] === "Yes"
                                                        ? "../../images/checkgreen.svg"
                                                        : "../../images/cros.svg"
                                                    }
                                                    width={11}
                                                    height={11}
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  />
                                                  {selectedOptionsOne[index] ===
                                                  "Yes" ? (
                                                    <small
                                                      style={{
                                                        color: "#00BB61",
                                                        fontWeight: "500",
                                                      }}
                                                    >{`Yes, ${
                                                      sliderValueOne[index] || 0
                                                      // sliderValueOne[index] ===
                                                      //   0
                                                      //   ? sliderValueOne[index]
                                                      //   : field?.value
                                                    }`}</small>
                                                  ) : (
                                                    <small
                                                      style={{
                                                        color: "red",
                                                        fontWeight: "500",
                                                      }}
                                                    >{`No, ${
                                                      sliderValueOne[index] || 0
                                                      // sliderValueOne[index] ===
                                                      //   0
                                                      //   ? sliderValueOne[index]
                                                      //   : field?.value
                                                    }`}</small>
                                                  )}
                                                </>
                                              )}

                                              {/* <>
                                                <img
                                                  src={
                                                    selectedOptionsOne[
                                                      index
                                                    ] === "Yes"
                                                      ? "../../images/checkgreen.svg"
                                                      : "../../images/cros.svg"
                                                  }
                                                  width={11}
                                                  height={11}
                                                  style={{ marginRight: "5px" }}
                                                />

                                                {selectedOptionsOne[index] ===
                                                  "Yes" &&
                                                sliderValueOne[index] ? (
                                                  <small>{`Yes, ${
                                                    sliderValueOne[index] ||
                                                    sliderValueOne[index] === 0
                                                      ? sliderValueOne[index] /
                                                        20
                                                      : field?.value * 20
                                                  }`}</small>
                                                ) : (
                                                  <small
                                                    style={{
                                                      color:
                                                        selectedOptionsOne[
                                                          index
                                                        ] === "Yes"
                                                          ? "green"
                                                          : "red",
                                                    }}
                                                  >
                                                    {selectedOptionsOne[index]}
                                                  </small>
                                                )}
                                              </> */}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}

                                    {/* Emotions table */}
                                    {getDBTTrack?.dbtFields?.[2]?.fields?.map(
                                      (field, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <div className="text-capitalize">
                                                {field?.key.includes("_")
                                                  ? field?.key.replace(
                                                      /_/g,
                                                      " "
                                                    )
                                                  : field?.key}
                                                <br />
                                                <span>rate from 0 to 5</span>
                                              </div>
                                            </td>
                                            <td className="text-green">
                                              <div className="row align-items-center">
                                                <div className="col-md-4">
                                                  <Box
                                                    sx={{
                                                      width: 400,
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    <Slider
                                                      aria-label="Custom marks"
                                                      value={
                                                        sliderValueTwo[index] ||
                                                        0
                                                      }
                                                      // defaultValue={Number(
                                                      //   field?.value
                                                      // )}
                                                      getAriaValueText={(e) => {
                                                        valuetext(e);
                                                      }}
                                                      onChange={(e) => {
                                                        setSliderValueTwo(
                                                          (prevOptions) => ({
                                                            ...prevOptions,
                                                            [index]:
                                                              e?.target?.value,
                                                          })
                                                        );
                                                        setSelectedOptionsTwo(
                                                          (prevOptions) => ({
                                                            ...prevOptions,
                                                            [index]: "Yes",
                                                          })
                                                        );
                                                      }}
                                                      // step={5}
                                                      step={1}
                                                      valueLabelDisplay="auto"
                                                      marks={marks}
                                                      // color="info"
                                                      color={`${
                                                        sliderValueTwo[index] &&
                                                        sliderValueTwo[index] >
                                                          0
                                                          ? "success"
                                                          : "info"
                                                      }`}
                                                      min={0}
                                                      max={5}
                                                    />
                                                  </Box>
                                                  {/* <Box sx={{ width: 400 }}>
                                                    <Slider
                                                      aria-label="Custom marks"
                                                      defaultValue={Number(
                                                        field?.value
                                                      )}
                                                      getAriaValueText={(e) => {
                                                        valuetext(e);
                                                      }}
                                                      onChange={(e) => {
                                                        setSliderValueTwo(
                                                          (prevOptions) => ({
                                                            ...prevOptions,
                                                            [index]:
                                                              e?.target?.value,
                                                          })
                                                        );
                                                      }}
                                                      step={5}
                                                      valueLabelDisplay="auto"
                                                      marks={marks}
                                                      color="info"
                                                    />
                                                  </Box> */}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="">
                                              {sliderValueTwo[index] &&
                                              sliderValueTwo[index] > 0 ? (
                                                <label
                                                  style={{ color: "#00BB61" }}
                                                >
                                                  {sliderValueTwo[index] || 0}
                                                </label>
                                              ) : (
                                                <label
                                                  style={{ color: "royalBlue" }}
                                                >
                                                  {0}
                                                </label>
                                              )}
                                              {/* <label>
                                               {
                                                  sliderValueTwo[index] || 0
                                                   sliderValueTwo[index] === 0
                                                    ? sliderValueTwo[index]
                                                    : field?.value
                                               }
                                               </label> */}
                                              {/* <label>
                                                {sliderValueTwo[index] ||
                                                sliderValueTwo[index] === 0
                                                  ? sliderValueTwo[index] / 20
                                                  : field?.value / 20}{" "}
                                              </label> */}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* nestedTab2 */}

                {nestedTab === "nestedTab2" && (
                  <>
                    {active ? (
                      <DBTracker />
                    ) : (
                      <>
                        <div class="tab-content">
                          <div
                            class="tab-pane fade show active"
                            aria-labelledby="pills-diryCard-tab"
                          >
                            <div class="table-responsive">
                              <table class="table border tableCustmization dbt-tracker-table">
                                <tbody>
                                  <tr>
                                    <th colSpan={2}>Date</th>
                                    <th>Aug 28, 2023</th>
                                    <th>Your response</th>
                                  </tr>
                                  <tr>
                                    <td rowSpan={3} className="BreakWords">
                                      CORE MINDFULNESS
                                    </td>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Observe & Describe
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Nonjudgmental Stance
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Loving Kindness (open hands + half
                                        smile)
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr
                                    style={{
                                      width: "100%",
                                      height: "1px",
                                      border: "1px solid #8F99B0",
                                    }}
                                  ></tr>
                                  <tr>
                                    <td rowSpan={5} className="BreakWords">
                                      EMOTION REGULATION
                                    </td>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Opposite
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Accumulating Positive Emotions
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Cope Ahead
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        PLEASE Skills
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Managing Extreme Emotions
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr
                                    style={{
                                      width: "100%",
                                      height: "1px",
                                      border: "1px solid #8F99B0",
                                    }}
                                  ></tr>
                                  <tr>
                                    <td rowSpan={3} className="BreakWords">
                                      DISTRESS TOLERANCE
                                    </td>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        TIP Skills
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Distract with Wise Mind ACCEPTS
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        IMPROVE the Moment
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr
                                    style={{
                                      width: "100%",
                                      height: "1px",
                                      border: "1px solid #8F99B0",
                                    }}
                                  ></tr>
                                  <tr>
                                    <td rowSpan={5} className="BreakWords">
                                      INTERERSONAL EFFECTIVENESS
                                    </td>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Objective Effectiveness: DEAR MAN
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Relationship Effectiveness:GIVE
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Options for Intensity
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Prioritizing Goals
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="DBT_Table">
                                        <input
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          checked
                                        />
                                        Validating others
                                      </div>
                                      <span>rate efficiency from 0 to 5</span>
                                    </td>
                                    <td className="text-green">
                                      <div className="row align-items-center">
                                        <div className="col-md-4">
                                          <Box sx={{ width: 400 }}>
                                            <Slider
                                              aria-label="Custom marks"
                                              defaultValue={80}
                                              getAriaValueText={valuetext}
                                              step={5}
                                              valueLabelDisplay="auto"
                                              marks={marks}
                                              color="info"
                                            />
                                          </Box>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src="../../images/checkgreen.svg"
                                        width={11}
                                        height={11}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <small>Yes, 3</small>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* Fixed Section bottom */}

                {/* {nestedTab === "nestedTab1" || nestedTab === "nestedTab2" ? (
                  <>
                    {!active ? (
                      <div
                        style={{
                          position: "fixed",
                          left: 0,
                          bottom: 0,
                          width: "92%",
                          textAlign: "left",
                          backgroundColor: "#fff",
                          marginLeft: "auto",
                          right: 0,
                          padding: "0px 24px",
                          boxShadow: "0px -2px 14px 0px #0000001A",
                        }}
                      >
                        <div className="row">
                          <div className="col-8">
                            <div className="row my-4">
                              <div className="col-md-12">
                                <p
                                  style={{
                                    color: "#8F99B0",
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Leave a comment about skills effectiveness:
                                </p>
                                <textarea
                                  id="w3review"
                                  name="w3review"
                                  rows="4"
                                  cols="50"
                                  style={{
                                    color: "#8F99B0",
                                    fontSize: "12px",
                                  }}
                                  placeholder="Saw my family and there was an argument, I felt
                  anxious (5) and did not sleep well at night."
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="row my-4">
                              <div className="col-md-12">
                                <p
                                  style={{
                                    color: "#8F99B0",
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Selected skills:
                                </p>
                                <label>
                                  Describe{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>{" "}
                                  Nonjudmentally{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>
                                  Mindfully{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>
                                </label>
                                <label>
                                  Surf waves of emotions{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>{" "}
                                  Observe{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>
                                  experience{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>
                                </label>
                                <label>
                                  Mindful of Positives{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>{" "}
                                  Tired{" "}
                                  <span
                                    style={{
                                      color: "#5B6680",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    (Yes, 3);
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        style={customStyles}
        onRequestClose={() => setOpenModal(false)}
      >
        <DBSuccessModal
          assessmentInfo={{
            setOpenModal: setOpenModal,
          }}
        />
      </Modal>
    </Layout>
  );
};

export default DBTTracker;

const options = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const selectValues = [
  { value: "", label: "Please select" },
  // { value: "0", label: "0 hours" },
  { value: "1", label: "1 hours" },
  { value: "2", label: "2 hours" },
  { value: "3", label: "3 hours" },
  { value: "4", label: "4 hours" },
  { value: "5", label: "5 hours" },
  { value: "6", label: "6 hours" },
  { value: "7", label: "7 hours" },
  { value: "8", label: "8 hours" },
  { value: "9", label: "9 hours" },
  { value: "10", label: "10 hours" },
  { value: "11", label: "11 hours" },
  { value: "12+", label: "12+ hours" },
];

const exerciseTime = [
  { value: "", label: "Please select" },
  { value: "15", label: "15 minutes" },
  { value: "30", label: "30 minutes" },
  { value: "45", label: "45 minutes" },
  { value: "60", label: "60 minutes" },
  { value: "75", label: "75 minutes" },
  { value: "90", label: "90 minutes" },
  { value: "120", label: "120 minutes" },
  { value: "135+", label: "135+ minutes" },
];

const meditationTime = [
  { value: "", label: "Please select" },
  { value: "5", label: "5 minutes" },
  { value: "10", label: "10 minutes" },
  { value: "15", label: "15 minutes" },
  { value: "20", label: "20 minutes" },
  { value: "30", label: "30 minutes" },
  { value: "45", label: "45 minutes" },
  { value: "60", label: "60 minutes" },
  { value: "75+", label: "75+ minutes" },
];

const checkboxData = [
  { label: "Friends", checked: false },
  { label: "Family", checked: false },
  { label: "Treatment team", checked: false },
];
