import React, { useEffect, useMemo, useState } from "react";
import MessageTabs from "components/tabs/MessageTabs";
import "../Card.css";
import { Form, Image } from "react-bootstrap";
import axios from "axios";
import { SendMessage, createGroup, updateGroup } from "pages/lib/apis";

const AddNewMessageModal = ({ assessmentInfo,  activeTabChat, fetchgroup, setFetchgroup, roomId, setMsgData, socket, title, groupMessage,patientDataUser, therapistData, SuperAdminData, allData}) => {

const result = {};

groupMessage?.participants?.forEach((item, index) => {
    // Check in therapistData
    const therapistMatch = therapistData.find(dataItem => dataItem.value[0] === item.id);
    if (therapistMatch) {
        result[`${therapistData.indexOf(therapistMatch)}-${item.id}`] = true;
    }

    // Check in patientData
    const patientMatch = patientDataUser.find(dataItem => dataItem.value[0] === item.id);
    if (patientMatch) {
        result[`${patientDataUser?.indexOf(patientMatch)}-${item.id}`] = true;
    }
    const adminMatch = SuperAdminData.find(dataItem => dataItem.value[0] === item.id);
    if (adminMatch) {
        result[`${SuperAdminData?.indexOf(adminMatch)}-${item.id}`] = true;
    }
});

  const userData = localStorage.getItem("sbCalls");
  const username = localStorage.getItem("name");
  const userId = JSON.parse(userData)?.userId;
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");
  const [activeTab, setActiveTab] = useState("Therapists");

const [patient, setPatient] = useState([]);
const [doctor, setDoctor] = useState([]);
const [superAdmin, setSuperAdmin] = useState([]);
// const [allUser, setAllUser] = useState([]);
const [groupName, setGroupName] = useState(groupMessage?.groupName ?? '');
const [message, setMessage] = useState('');
const [selectedUsers, setSelectedUsers] = useState(result ?? {});

const { setOpenAddMessageModal } = assessmentInfo;

const tabs = [
  { eventKey: "Therapists", label: "Therapists" },
  { eventKey: "Patients", label: "Patients" },
  { eventKey: "Admin", label: "Admin" },
];

const userRoleFlag = userRole === "Patient";

const handleSelect = (selectedKey) => {
  setActiveTab(selectedKey);
};

const handleGroupInput = (e) => {
  setGroupName(e.target.value);
};


const handleSearch = async (e) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/users/allpatientsdoctors?search=${e?.target?.value ?? ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const doctors = response.data.doctors.map((doctor) => ({
      ...doctor,
      type: "Doctor",
    }));

    const patients = response.data.patients.map((patient) => ({
      ...patient,
      type: "Patient",
    }));
    const superadmin = response.data.superadmin.map((admin) => ({
      ...admin,
      type: "superadmin",
    }));

    setDoctor(doctors);
    setPatient(patients);
    setSuperAdmin(superadmin);
    // setAllUser([...response.data.doctors, ...response.data.patients]);
  } catch (e) {
    console.error(e);
  }
};

const doctorData = useMemo(() => {
  return doctor?.map((d) => {
    return {
      value: [d?.id, `${d.firstName} ${d.lastName}`],
      label: `${d.firstName} ${d.lastName}`,
    };
  });
}, [doctor]);

const patientData = useMemo(() => {
  return patient?.map((d) => {
    return {
      value: [d?.id, `${d.firstName} ${d.lastName}`],
      label: `${d.firstName} ${d.lastName}`,
    };
  });
}, [patient]);
const superadminData = useMemo(() => {
  return superAdmin?.map((d) => {
    return {
      value: [d?.id, `${d.fullname}`],
      label: `${d.fullname}`,
    };
  });
}, [superAdmin]);

const combinedData = useMemo(() => {
  const combined = [];
  if (doctorData) {
    combined.push(...doctorData);
  }
  if (patientData) {
    combined.push(...patientData);
  }
  if(superadminData) {
    combined.push(...superadminData);
  }
  return combined;
}, [doctorData, patientData, superadminData]);

useEffect(() => {
  handleSearch();
}, []);



const handleCheckboxChange = (index, item) => {
  const key = `${index}-${item.value[0]}`;
  setSelectedUsers((prev) => ({
    ...prev,
    [key]: !prev[key],
  }));
};



const handleSubmitButton = async () => {
  if(fetchgroup){
    setFetchgroup(false);
  }
  const selectedUserData = Object.keys(selectedUsers)
    .filter(key => selectedUsers[key])
    .map(key => key.split('-')[1]);

  const matchedEntries = combinedData
    ?.filter(entry => selectedUserData?.includes(entry?.value[0]?.toString()))
    .map(entry => ({
      id: entry?.value[0],
      name: entry?.value[1]
    }));

  try {
    if (matchedEntries) {
      const transformedParticipants = matchedEntries.map(entry => ({
        id: entry.id,
        name: entry.name
      }));
      transformedParticipants.push({
        id: userId,
        name: username,
      });
      const transformedUpdateFields = {
        participants: transformedParticipants,
        createdBy: {
          id: userId,
          name: username,
        },
        isGroup: true,
        groupName: groupName,
        groupType: activeTabChat
      };
      



      const res = await createGroup(token, transformedUpdateFields);

      if (res?.data?.error) {
        console.error();
      } else {
        if (message && message.trim() ) {
          const resSendMessage = await SendMessage({
            message: message,
            attachment: [],
            threadId: res?.data?.newRoom?.id,
            senderId: String(userId),
            senderName: username,
            participants: transformedParticipants,
            isRead: false,
            isDelete: false,
            createdAt: new Date(),
          });
    
          if (resSendMessage.data) {
            localStorage?.removeItem(roomId);
            socket?.emit('send_msg', {
              id: resSendMessage.data.id,
              formSelf: true,
              message: message,
              attachment: [],
              threadId: res?.data?.newRoom?.id,
              senderId: String(userId),
              senderName: username,
              participants: transformedParticipants,
              isRead: false,
              isDelete: false,
              createdAt: new Date(),
            });
    
            setMsgData((prevMsgData) => {
              if (!Array.isArray(prevMsgData)) {
                console.error("prevMsgData is not an array", prevMsgData);
                return prevMsgData;
              }
              return [
                ...prevMsgData,
                {
                  id: resSendMessage.data.id,
                  message: message,
                  attachment: [],
                  threadId: res?.data?.newRoom?.id,
                  senderId: String(userId),
                  senderName: username,
                  participants: transformedParticipants,
                  isRead: false,
                  isDelete: false,
                  createdAt: new Date(),
                },
              ];
            });
    
          }
        }
        setFetchgroup(true);
        setOpenAddMessageModal(false);
      }
    }
  } catch (e) {
    console.error(e);
  }
};


const handleUpdateGroup = async () => {
  if(fetchgroup){
    setFetchgroup(false);
  }


// Extract the keys where the value is true
const trueKeys = Object.keys(selectedUsers).filter(key => selectedUsers[key]);

// Extract the ids from the keys where value is true
const ids = trueKeys.map(key => parseInt(key.split('-')[1]));

// Filter out the matching objects from allData
const selectUpdatedUser = allData
    .filter(item => ids.includes(item.value[0]))
    .map(item => ({
        id: item.value[0],
        name: item.value[1]
    }));

  const updateGroupRes = await updateGroup(token, {id: groupMessage?.id, groupName: groupName, participants: selectUpdatedUser});

  if(updateGroupRes?.data?.error){
    console.error();
  }else{
    setOpenAddMessageModal(false);
    setFetchgroup(true);
  }

};

  return (
    <>
          <div className="modal-dialog modal-dialog-centered modal_width">
      <div className="modal-content">
        <div className="heading-close-button" >
          <div className="headingSec">
            <h2 className="mb-10">{title}</h2>
          </div>
          <div className="modal-header close-modal-button border-bottom-0 ">
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpenAddMessageModal(false)}
            ></button>
          </div>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          <div>
            <div className="input-group user-input-group modal_custominp w-100">
              <span className="input-group-text border-end-0" id="basic-addon1">
                <i className="far fa-users"></i>
              </span>
              <input
                type="text"
                onChange={handleGroupInput}
                value={groupName}
                className="form-control ps-0 border-start-0"
                placeholder="Enter group name ....."
                aria-label="Group Name"
                aria-describedby="basic-addon1"
                disabled={userRoleFlag}
              />
            </div>
          </div>
          <div className="d-flex mt-3 align-items-center gap-3 mb-2">
            <MessageTabs
              tabs={tabs}
              handleSelect={handleSelect}
              activeTab={activeTab}
            />
          </div>
          <div className="mb-3">
            <div className="input-group modal_custominp w-100">
              <span className="input-group-text border-end-0" id="basic-addon1">
                <i className="far fa-search"></i>
              </span>
              <input
                type="text"
                onChange={handleSearch}
                className="form-control ps-0 border-start-0"
                placeholder={`Search ${activeTab === "Therapists" ? "therapists" : "patients"}...`}
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
          <div className="patientList">
            <div className="users-list-css">
              {activeTab === "Therapists" && (
                <ul className="p-0 gap-3 d-grid">
                  {doctorData.map((item, index) => {
                    const key = `${index}-${item.value[0]}`;
                    return (
                      <li key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="form-check">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              checked={selectedUsers[key] || false}
                              onChange={() => handleCheckboxChange(index, item)}
                              id={`flexCheckDefault-${key}`}
                              disabled={userRoleFlag}
                            />
                            <Image
                              src="/images/avatar.png"
                              className="me-2"
                              width={30}
                              alt=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`flexCheckDefault-${key}`}
                            >
                              {item.label}
                            </label>
                          </div>
                          {selectedUsers[key] && (
                            <span className="selected text-success">
                              <i className="far fa-check"></i> Selected
                            </span>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              {activeTab === "Patients" && (
                <ul className="p-0 gap-3 d-grid">
                  {patientData.map((item, index) => {
                    const key = `${index}-${item.value[0]}`;
                    return (
                      <li key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="form-check">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              checked={selectedUsers[key] || false}
                              onChange={() => handleCheckboxChange(index, item)}
                              id={`flexCheckDefault-${key}`}
                            />
                            <Image
                              // src={item.image}
                              src="/images/avatar.png"
                              className="me-2"
                              width={30}
                              alt=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`flexCheckDefault-${key}`}
                            >
                              {item.label}
                            </label>
                          </div>
                          {selectedUsers[key] && (
                            <span className="selected text-success">
                              <i className="far fa-check"></i> Selected
                            </span>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              {activeTab === "Admin" && (
                <ul className="p-0 gap-3 d-grid">
                  {superadminData.map((item, index) => {
                    const key = `${index}-${item.value[0]}`;
                    return (
                      <li key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="form-check">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              checked={selectedUsers[key] || false}
                              onChange={() => handleCheckboxChange(index, item)}
                              id={`flexCheckDefault-${key}`}
                            />
                            <Image
                              // src={item.image}
                              src="/images/avatar.png"
                              className="me-2"
                              width={30}
                              alt=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`flexCheckDefault-${key}`}
                            >
                              {item.label}
                            </label>
                          </div>
                          {selectedUsers[key] && (
                            <span className="selected text-success">
                              <i className="far fa-check"></i> Selected
                            </span>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            {title === "Create Group" && <div>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Message:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>}
            <div className="gap-4 button-css-All d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-outline-secondary px-5 btnSize rounded-3"
                onClick={() => setOpenAddMessageModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary px-5 btnSize rounded-3"
                onClick={title === "Create Group" ? handleSubmitButton : handleUpdateGroup}
                disabled={userRoleFlag}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    </>
   
  );
};

export default AddNewMessageModal;
