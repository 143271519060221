import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import ClinicalTaskModal from '../../../../modals/Document/Indivisual/All/ClinicalTaskModal';
import { patientDocumentations } from "lib/api/service";
import TableButton from 'components/Document/TableButton/TableButton';
import { IncompleteTag, CompleteTag, UnstartedTag } from 'components/Document/StatusTag/StatusTag';

const ClinicalTask = () => {
  const token = localStorage.getItem("token");

  const { patientId } = useParams();
  const [clinicalData, setclinicalData] = useState([]);
  const [isClinicalTaskModalOpen, setIsClinicalTaskModalOpen] = useState(false);
  const [selectedClinicalData, setSelectedClinicalData] = useState('');

  const fetchData = () => {
    if (patientId) {
      const numericPatientId = Number(patientId);
      fetchNotesById(numericPatientId);
    }
  }

  useEffect(() => {
    fetchData();
  }, [patientId]);

  const fetchNotesById = async (patientId) => {
    try {
      if (patientId) {
        const response = await patientDocumentations(patientId);
        setclinicalData(response?.documentation);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  return (
    <>
      <div id="clinical">

        <div className="tab-content mt-4" id="pills-tabContent">

          <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className='tableList-2'>
              <table className="table table-borderless" style={{ fontSize: '14px' }}>
                <thead>
                  <tr>
                    <th scope="col">Document Name <img src='../../images/short-icon.svg' alt='' /> </th>
                    <th scope="col">Provider <img src='../../images/short-icon.svg' alt='' /></th>
                    <th scope="col">Session Date <img src='../../images/short-icon.svg' alt='' /></th>
                    <th scope="col">Created <img src='../../images/short-icon.svg' alt='' /></th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>

                  {clinicalData.map(clinicalData => (
                    <tr key={clinicalData.id} className='align-items-center'>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img src="../../images/doc-icon.png" alt="..." />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <span className='curser-pointer'
                              onClick={() => {
                                setSelectedClinicalData(clinicalData);
                                setIsClinicalTaskModalOpen(true);
                              }}
                            >
                              {clinicalData.name}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td><p className='mb-0'><img src={clinicalData.providerImage} alt='' /> {clinicalData.name}</p></td>
                      <td><p className='mb-0'>{clinicalData.scheduleCreatedAt}</p></td>
                      <td>
                        <p className='mb-0'> {clinicalData.createdate} <br /><span>{clinicalData.createtime}</span></p>
                      </td>

                      <td>
                        {clinicalData.status === 'incomplete' && <IncompleteTag>Incomplete</IncompleteTag>}
                        {clinicalData.status === 'completed' && <CompleteTag>Complete</CompleteTag>}
                        {clinicalData.status === 'unstarted' && <UnstartedTag>Unstarted</UnstartedTag>}
                      </td>

                      <td>
                        {/* <img src='../../images/edit.svg' alt='' /> */}
                        <TableButton title='Download' onClick={() => {
                          window.open(`${process.env.REACT_APP_BASE_API_URL}/documentation/download/${clinicalData.id}?token=${token}`, '_blank');
                        }}><img src='../../images/download.svg' alt='' />
                        </TableButton>
                        {/* <img src='../../images/delete.svg' alt='' /> */}
                      </td>

                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isClinicalTaskModalOpen} onRequestClose={() => setIsClinicalTaskModalOpen(false)} >
        <ClinicalTaskModal
          successCb={fetchData}
          clinicalInfo={{
            selectedClinicalData: selectedClinicalData,
            setIsClinicalTaskModalOpen: setIsClinicalTaskModalOpen
          }}

        />

      </Modal>

    </>
  );

};

export default ClinicalTask;
