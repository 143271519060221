import React, { useState } from "react";
import Header from "../../components/Header/Header";
import "../../pages/Schedule/Schedule.css";
import Layout from "components/layout/layout";
import toast from "react-hot-toast";
import { preaAndPostSessionAssessment } from "lib/api/service";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import MySlider from "./MySlider";

const ScheduleAssissment = () => {
  const [inputResponse, setInputResponse] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const meetLink = location.state?.meetLink;
  let { id } = useParams();

  const [questionnaireData, setQuestionnaireData] = useState([
    {
      id: 1,
      question:
        "1. Rate your current anxiety<span style='color: orangered; font-weight: 600'>*</span>  (10 - the worst anxiety possible; 0 - not anxious)",
      // response: 0,
      // patientResponse: "1",
      value: 0,
    },
    {
      id: 2,
      question: `2. Rate your current depression level?<span style='color: orangered; font-weight: 600'>*</span>  (10 - unbearable depression; 0 - no depression) `,
      // response: 0,
      // patientResponse: "2",
      value: 0,
    },
    {
      id: 3,
      question:
        "3.Have you tried to implement any therapy skills outside of treatment, if so on a scale of 1-10 how effective was the skill for helping you cope with the situation?<span style='color: orangered; font-weight: 600'>*</span> (10 - extremely effective; 0 - no effect)",
      // response: 0,
      // patientResponse: "3",
      value: 0,
    },
    {
      id: 4,
      question:
        "4. How willing to engage in treatment are you today?<span style='color: orangered; font-weight: 600'>*</span> (10 - fully engaged; 0 - no engagement)",
      // response: 0,
      // patientResponse: "4",
      value: 0,
    },
    // {
    //   id: 5,
    //   question: "5. What do you want to work on or discuss today?",
    //   // response: 0,
    //   // patientResponse: "5",
    //   value: 0,
    // },
  ]);

  const handleResponseChange = (index, event) => {
    const updatedQuestionnaireData = [...questionnaireData];
    updatedQuestionnaireData[index].response = parseInt(event.target.value);
    updatedQuestionnaireData[index].patientResponse = calculatePatientResponse(
      updatedQuestionnaireData[index].response
    );
    setQuestionnaireData(updatedQuestionnaireData);
  };

  const calculatePatientResponse = (value) => {
    if (value <= 20) {
      return "0";
    } else if (value <= 40) {
      return "7";
    } else if (value <= 60) {
      return "Yes, 2";
    } else if (value <= 80) {
      return "8";
    } else {
      return "I want to work on reducing my anxiety.";
    }
  };

  const handleSubmit = async () => {
    const payload = {
      scheduleId: id,
      pre_anxiety: questionnaireData[0].value,
      pre_depression: questionnaireData[1].value,
      pre_therapy: questionnaireData[2].value,
      pre_engage: questionnaireData[3].value,
      // pre_discussion: questionnaireData[4].response,
      pre_discussion: inputResponse,
    };

    try {
      const response = await preaAndPostSessionAssessment(payload);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        // setTimeout(() => {
        //   navigate("/schedule");
        // }, 2000);
      } else {
        toast.error("Failed to submit data");
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const joinMeeting = () => {
    window.open(meetLink, "_blank");
  };

  const handleInputResponse = (event) => {
    setInputResponse(event.target.value);
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={""} />
        <div className="main-content px-3 schedule">
          <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4"></div>
          <div className="modal-body pt-0 pb-5 px-4">
            <div className="d-flex justify-content-between mt-2 mb-3">
              <div>
                <h3 className="mb-1 session-assessment">
                  Pre-session assessment
                </h3>
              </div>
              <button
                type="button"
                className="px-5 py-2 w-10 btn-assessment"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <div className="tableUI">
              <table className="table table-bordered">
                <tbody>
                  {questionnaireData.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <p
                          className="mb-0"
                          style={{ fontSize: "0.9rem", color: "#18357a" }}
                          dangerouslySetInnerHTML={{ __html: data.question }}
                        >
                          {/* {data.question} */}
                        </p>
                        {/* <div className="range-slider px-4 mt-2">
                          <input
                            className="range-slider__range w-50"
                            type="range"
                            min="1"
                            max="100"
                            value={data.response}
                            onChange={(event) =>
                              handleResponseChange(index, event)
                            }
                          />
                          <div className="row mt-1 w-50">
                            <div className="col">
                              <p>1</p>
                            </div>
                            <div className="col text-center">
                              <p>2</p>
                            </div>
                            <div className="col text-center">
                              <p>3</p>
                            </div>
                            <div className="col text-center">
                              <p>4</p>
                            </div>
                            <div className="col text-end">
                              <p>5</p>
                            </div>
                            <div className="col text-end">
                              <p>6</p>
                            </div>
                            <div className="col text-end">
                              <p>7</p>
                            </div>
                            <div className="col text-end">
                              <p>8</p>
                            </div>
                            <div className="col text-end">
                              <p>9</p>
                            </div>
                            <div className="col text-end">
                              <p>10</p>
                            </div>
                          </div>
                        </div> */}
                        <div
                          style={{
                            marginTop: "12px",
                            marginLeft: "20px",
                            marginBottom: "12px",
                          }}
                        >
                          <MySlider
                            _id={data.id}
                            sliderValue={data.value}
                            setQuestionnaireData={setQuestionnaireData}
                            questionnaireData={questionnaireData}
                          />
                        </div>
                      </td>
                      <td style={{ width: "22%" }}>
                        <p
                          style={{
                            fontSize: "0.95rem",
                            marginBottom: "5px",
                            color: "#18357a",
                          }}
                        >
                          Patient’s response
                        </p>
                        {/* <br /> <b>{calculatePatientResponse(data.response)}</b> */}
                        <b
                          style={{
                            fontSize: "0.925rem",
                            fontWeight: "500",
                            color: "royalblue",
                          }}
                        >
                          {data.value > 0 ? (
                            <span style={{ color: "#009B4C" }}>
                              {`Yes, ${data.value}`}
                            </span>
                          ) : (
                            data.value
                          )}
                        </b>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <p style={{ fontSize: "0.9rem", color: "#18357a" }}>
                        5. What do you want to work on or discuss today?
                      </p>
                      <input
                        placeholder="Enter your response"
                        onChange={handleInputResponse}
                        style={{
                          width: "430px",
                          marginLeft: "15px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          paddingLeft: "5px",
                          caretColor: "grey",
                          fontSize: "0.95rem",
                          color: "303030",
                          marginBottom: "22px",
                        }}
                      />
                    </td>
                    <td
                      style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.95rem",
                          marginBottom: "5px",
                          color: "#18357a",
                          textWrap: "wrap",
                        }}
                      >
                        Patient’s response
                      </p>
                      <p
                        style={{
                          fontSize: "1rem",
                          fontWeight: "450",
                          color: "#009B4C",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "0 6px",
                        }}
                      >
                        {inputResponse}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 mb-3 modal-body pt-0 pb-5 px-4">
            <div className="headingSec">
              <h3 className="mb-1 session-assessment">Clinical Consultation</h3>
              <p>Thursday, 6 July 2023 02:30-03:30PM</p>
            </div>
            <div className="white-rounded-footer">
              <div className="bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end gap-4">
                <button
                  type="button"
                  onClick={() => navigate("/schedule")}
                  className="px-5 py-2 w-10 cancelButton"
                >
                  {" "}
                  cancel{" "}
                </button>
                <button
                  type="button"
                  onClick={joinMeeting}
                  className="px-5 py-2 w-10 btn-assessment"
                >
                  {" "}
                  Join Appointment{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScheduleAssissment;
