import FolderCard from "components/atoms/cards/FolderCard";
import styled from 'styled-components';
import { IconEdit } from '@tabler/icons-react';
import { Button } from "react-bootstrap";

const CreateGroupButton = styled(Button)`
  color: #00BB61;
  background-color: #E6F8EF !important;
  border: 0;

  &:focus {
    color: revert;
  }

  &:hover {
    color: #00BB61;
  }
`;

const DocumentTab = ({ activeTab, nestedTab, handleNestedTabChange, setShowModal, users, groups, contextMenu }) => {

    const userRole = localStorage.getItem("userRole");

    return (
        <div className="row">
            {activeTab === "Groups" && (
                <div>
                    <div className="nested-tabs">
                        <div className="w-100 border-bottom mb-5 d-flex justify-content-between">
                            <div>
                                <button
                                    className="border-0 bg-transparent ps-0"
                                    onClick={() =>
                                        handleNestedTabChange("active")
                                    }
                                >
                                    <div className="col-md">
                                        <ul
                                            class="nav nav-pills mb-0 dbSkillTab gap-2"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            <li class="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${nestedTab === "active"
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    id="pills-diryCard-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-diryCard"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-diryCard"
                                                    aria-selected={
                                                        nestedTab === "active"
                                                    }
                                                >
                                                    ACTIVE GROUPS
                                                    {groups && groups?.active && (
                                                        <>{' '}({groups?.active?.length})</>
                                                    )}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </button>

                                <button
                                    className="border-0 bg-transparent"
                                    onClick={() =>
                                        handleNestedTabChange("archived")
                                    }
                                >
                                    <div className="col-md">
                                        <ul
                                            class="nav nav-pills mb-0 dbSkillTab gap-2"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            <li class="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${nestedTab === "archived"
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    id="pills-skill-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-skill"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-skill"
                                                    aria-selected={
                                                        nestedTab === "archived"
                                                    }
                                                >
                                                    ARCHIVED GROUPS
                                                    {groups && groups?.archived && (
                                                        <>{' '}({groups?.archived?.length})</>
                                                    )}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </button>
                            </div>
                            <div>
                                {/* userRole is being fetched from browser storage in existing implementation as compared
                                to decoding it and then accessing it, the same is used over here as well */}
                                {userRole === 'SuperAdmin' && activeTab === 'Groups' && (
                                    <CreateGroupButton onClick={() => {
                                        setShowModal(true);
                                    }}>
                                        <IconEdit color="#00BB61" /> Create Group
                                    </CreateGroupButton>
                                )}
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">

                        {nestedTab === "active" && (
                            <div
                                class="tab-pane fade show active"
                                aria-labelledby="pills-diryCard-tab"
                            >
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-single"
                                        role="tabpanel"
                                        aria-labelledby="pills-single-tab"
                                    >
                                        <div className="px-3">
                                            <div className="row mt-5">
                                                {
                                                    groups?.active?.map(
                                                        (items, index) => (
                                                            <FolderCard
                                                                pathResource='groups'
                                                                data={items}
                                                                key={index}
                                                                showContextMenu={userRole === 'SuperAdmin'}
                                                                menuItems={contextMenu}
                                                            />
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {nestedTab === "archived" && (
                            <div
                                class="tab-pane fade show active"
                                aria-labelledby="pills-diryCard-tab"
                            >
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-single"
                                        role="tabpanel"
                                        aria-labelledby="pills-single-tab"
                                    >
                                        <div className="px-3">
                                            <div className="row mt-5">
                                                {
                                                    groups?.archived?.map(
                                                        (items, index) => (
                                                            <FolderCard
                                                                pathResource='groups'
                                                                data={items}
                                                                key={index}
                                                                showContextMenu={userRole === 'SuperAdmin'}
                                                                menuItems={contextMenu}
                                                            />
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            )}
            {activeTab === 'Individual' && (
                <div>
                    <div className="nested-tabs">
                        <div className="w-100 border-bottom mb-5">
                            <button
                                className="border-0 bg-transparent ps-0"
                                onClick={() =>
                                    handleNestedTabChange("current")
                                }
                            >
                                <div className="col-md">
                                    <ul
                                        class="nav nav-pills mb-0 dbSkillTab gap-2"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li class="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${nestedTab === "current"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                id="pills-diryCard-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-diryCard"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-diryCard"
                                                aria-selected={
                                                    nestedTab === "current"
                                                }
                                            >
                                                CURRENT
                                                {users && users?.current &&(
                                                        <>{' '}({users?.current?.length})</>
                                                    )}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </button>

                            <button
                                className="border-0 bg-transparent"
                                onClick={() =>
                                    handleNestedTabChange("discharged")
                                }
                            >
                                <div className="col-md">
                                    <ul
                                        class="nav nav-pills mb-0 dbSkillTab gap-2"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li class="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${nestedTab === "discharged"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                id="pills-skill-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-skill"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-skill"
                                                aria-selected={
                                                    nestedTab === "discharged"
                                                }
                                            >
                                                DISCHARGED
                                                {users && users?.disCharged &&(
                                                        <>{' '}({users?.disCharged?.length})</>
                                                    )}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </button>

                        </div>
                    </div>

                    {nestedTab === "current" && (
                        <div class="tab-content">
                            <div
                                class="tab-pane fade show active"
                                aria-labelledby="pills-diryCard-tab"
                            >
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-single"
                                        role="tabpanel"
                                        aria-labelledby="pills-single-tab"
                                    >
                                        <div className="px-3">
                                            <div className="row mt-5">
                                                {
                                                    users?.current?.map(
                                                        (items, index) => (
                                                            <FolderCard pathResource='individual' data={items} key={index} showNotes={true} />
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {nestedTab === "discharged" && (
                        <div class="tab-content">
                            <div
                                class="tab-pane fade show active"
                                aria-labelledby="pills-diryCard-tab"
                            >
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-single"
                                        role="tabpanel"
                                        aria-labelledby="pills-single-tab"
                                    >
                                        <div className="px-3">
                                            <div className="row mt-5">
                                                {
                                                    users?.disCharged?.map(
                                                        (items, index) => (
                                                            <FolderCard pathResource='individual' data={items} key={index} showNotes={true} />
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            )}
        </div>
    );

};

export default DocumentTab;