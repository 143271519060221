const transformProviderData = (data) => {
    const transformedData = data.map(({ id, firstName, lastName, email }) => ({
        id,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        email,
        // Using below property only because of reusing existing autocomplete dropdown
        isAvailable: true
      }));

    return transformedData;
};

const transformPatientData = (data) => {
    const transformedData = data.map(({ id, firstName, lastName, email }) => ({
        id,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
        name: `${firstName} ${lastName}`,
        email,
        // Using below property only because of reusing existing autocomplete dropdown
        isAvailable: true
      }));

      return transformedData;
}

export { transformProviderData, transformPatientData };