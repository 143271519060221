import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Form } from "react-bootstrap";
import { getPerformerLists, taskassessmentSave } from "lib/api/service";
import { addnewTaskSchema } from "utils/schemas";
import { handleKeyDown } from "utils/inputSpace";
import toast from "react-hot-toast";

const AddNewTaskModal = ({ closeModal, patientId, setFetchLatest }) => {
  const providerValue = localStorage.getItem("name") || "";
  const [performList, setPerformList] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (patientId) {
      fetchNotesById(patientId);
    }
  }, [patientId]);

  const fetchNotesById = async (patientId) => {
    try {
      if (patientId) {
        const response = await getPerformerLists(patientId);
        setPerformList(response?.results);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };
  const { register, handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(addnewTaskSchema),
    defaultValues: {
      provider: providerValue,
    },
  });

  const { errors = {} } = formState;

  useEffect(() => {
    setValue("provider", providerValue);
  }, [providerValue, setValue]);

  const onSubmit = async (data) => {
    const req_value = {
      title: data?.title,
      status: data?.status,
      provider_name: data?.provider,
      performer_id: data?.performer,
      performer_name:
        performList.find((item) => item.doctor_id === data.performer)
          ?.doctor_name || "",
      patient_id: patientId,
      note: data?.discription,
      date_created: formatDate(data?.created),
      due_date: formatDate(data?.dueDate),
    };

    try {
      if (req_value) {
        const response = await taskassessmentSave(req_value);
        if (setFetchLatest) {
          setFetchLatest(true);
        }
        toast.success(response?.message);
        handleClose();
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
      toast.error(error?.message);
    }
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 justify-content-end mb-3">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4 cardPaitent">
          <div className="headingSec mb-3">
            <h2 className="mb-0">Add a new task</h2>
          </div>

          <Row className="mb-3 mt-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Title:</Form.Label>
                  <Form.Control
                    id="title"
                    name="title"
                    {...register("title")}
                    // as="text"
                    // onKeyDown={handleKeyDown}
                    // rows={1}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Task description:</Form.Label>
                  <Form.Control
                    {...register("discription")}
                    id="discription"
                    name="discription"
                    as="textarea"
                    onKeyDown={handleKeyDown}
                    rows={3}
                  />
                </Form.Group>
                <div className="text-danger">{errors.discription?.message}</div>
                <Form.Group>
                  <Form.Label>Status:</Form.Label>
                  <Form.Select
                    id="status"
                    name="status"
                    {...register("status")}
                  >
                    <option value="">Select status</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                    <option value="in-progress">In-progress</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Performer: </Form.Label>
                    <Form.Control
                      as="select"
                      {...register("performer")}
                      name="performer"
                    >
                      <option value="Select status">Select performer</option>
                      {performList?.map((item, index) => (
                        <option key={index} value={item.doctor_id}>
                          {item.doctor_name}
                        </option>
                      ))}
                    </Form.Control>
                    <div className="text-danger">
                      {errors.performer?.message}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Provider:</Form.Label>
                    <Form.Control
                      {...register("provider")}
                      type="text"
                      name="provider"
                    />
                    <div className="text-danger">
                      {errors.provider?.message}
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date Created:</Form.Label>
                    <Form.Control
                      {...register("created")}
                      type="date"
                      id="created"
                      name="created"
                      min={new Date().toISOString().split("T")[0]}
                    />
                    <div className="text-danger">{errors.created?.message}</div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Due by:</Form.Label>
                    <Form.Control
                      {...register("dueDate")}
                      type="date"
                      id="dueDate"
                      name="dueDate"
                      min={new Date().toISOString().split("T")[0]}
                    />
                    <div className="text-danger">{errors.dueDate?.message}</div>
                  </Form.Group>
                </Col>
              </Row>

              <div className="gap-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary px-5 btnSize rounded-3"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary px-5 btnSize rounded-3"
                >
                  Add a new task
                </button>
              </div>
            </Form>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddNewTaskModal;
