import { Navbar, ListGroup } from "react-bootstrap";
import AdminDashboardTaskTable from "../../table/AdminDashboardTaskTable";
import { useNavigate } from "react-router-dom";

const DashboardTaskComponent = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white rounded-top-3 mt-4 p-3 box_radius"
      style={{ height: "427px", overflow: "auto" }}
    >
      <div className="modal-content">
        <Navbar className="modal-header p-2 border-bottom-0">
          <Navbar.Brand>
            <h2 className="bold-text blue-text">Request</h2>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="p-2 pb-0">
              <button
                className="text-button view_all_btn"
                onClick={() => navigate("/requests")}
              >
                View all
              </button>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <ListGroup variant="flush" className="p-0 gap-2 d-grid ul_class">
          <ListGroup.Item className="d-flex flex-wrap align-items-center p-0">
            <span className="px-2">Recent Pending request</span>
          </ListGroup.Item>
        </ListGroup>

        <div className="modal-body">
          <AdminDashboardTaskTable />
        </div>
      </div>
    </div>
  );
};

export default DashboardTaskComponent;
