import React from 'react';

const PsychotherapyModal = ( { psychotherapyDetailInfo  } ) => {

  const { selectedPsychotherapyData,patient_data } = psychotherapyDetailInfo;

  if (!selectedPsychotherapyData) {
    return null;
  }

  return (
        <div className="">
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Date created:</label>
              <p className="mb-0">{ selectedPsychotherapyData.created } <span>{ selectedPsychotherapyData.time }</span></p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Provider:</label>
              <p className="mb-0">{ selectedPsychotherapyData.provider }</p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Patient:</label>
              <p className="mb-0"><img src={ selectedPsychotherapyData.providerImage } alt="" />{patient_data?.patient_name}</p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Patient DOB:</label>
              <p className="mb-0">
                <img src={ selectedPsychotherapyData.providerImage } alt="" />{patient_data?.dob}
              </p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <label className="me-2">Note: </label>
            <p className="mb-2">
              {selectedPsychotherapyData?.description}
            </p>
          </div>
        </div>

  );
};

export default PsychotherapyModal;
