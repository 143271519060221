import React from 'react';
import toast from "react-hot-toast";
import { createDocument } from "lib/api/service";

const PsychotherapyModal = ( { psychotherapyDetailInfo, noteUpdateCallback  } ) => {

  const { selectedPsychotherapyData, setIsPsychotherapyModalOpen } = psychotherapyDetailInfo;

  const [submittingData, setSubmittingData] = React.useState(false);

  if (!selectedPsychotherapyData) {
    return null;
  }

  const handleConvertClick = async () => {
    setSubmittingData(true);

    const response = await createDocument(null, { id: selectedPsychotherapyData.id });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setIsPsychotherapyModalOpen(false);
        noteUpdateCallback();
      } else {
        toast.error(response?.data?.message);
      }
    
    setIsPsychotherapyModalOpen(false)
    setSubmittingData(false);
  }

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
      <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setIsPsychotherapyModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Date created: </label>
              <p className="mb-0">{ selectedPsychotherapyData.noteCreatedAt }
                {' '}
                <span>{ selectedPsychotherapyData.createdTime	 }</span>
              </p>
            </div>
          </div>

          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Patient: </label>
              <p className="mb-0">
                <img src={ selectedPsychotherapyData.providerImage } alt="" />
                {selectedPsychotherapyData.patients.map((p) => p.name).join(', ')	 }
              </p>
            </div>
          </div>

          <div className="col-md-12 mb-2">
          <label className="me-2">Note: </label>
            <p>{selectedPsychotherapyData.note}</p>
          </div>

          <div className='row mb-3 mt-3'>
            <div className='col-md-12 mt-3'>
              <div class="gap-4 d-flex justify-content-center">
                <button type="button" class="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setIsPsychotherapyModalOpen(false)}>Cancel</button>
                <button type="button" class="btn btn-primary px-5 btnSize rounded-3"
                  disabled={selectedPsychotherapyData.doctype !== 'note' || submittingData}
                  onClick={() => handleConvertClick(false)}>
                    Convert to documentation
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PsychotherapyModal;
