import React, { useEffect, useState, useContext } from "react";
import AppointmentModal from "../../../modals/AppointmentModal";
import ScheduleAppointmentModal from "../../../modals/ScheduleAppointmentModal";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { ProfileContext } from "../../../context/ProfileProvider";

const Appointment = ({ patientId }) => {
  const [formValues, setFormValues] = useState({});
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [isAppointmentModalOpen, setIsAppointmentDetailModalOpen] =
    useState(false);
  const [selectedAppointmentDetailData, setSelectedAppointmentDetailData] =
    useState("");
  const [isScheduleAppointmentModalOpen, setIsScheduleAppointmentModalOpen] =
    useState(false);
  const [selectedScheduleAppointmentData, setSelectedScheduleAppointmentData] =
    useState({});
  const [open, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { profile, getProfile } = useContext(ProfileContext);

  useEffect(() => {
    setAppoitments();
  }, [profile?.user_id, patientId]);

  const setAppoitments = () => {
    if (!profile?.user_id) {
      return null;
    }
    const get_req_value = {
      clinicianId: profile?.user_id,
      patientId: patientId,
      task: "getAppointmentData",
    };
    const queryParams = new URLSearchParams(get_req_value).toString();
    const apiUrl = `${apiEndpoint}?${queryParams}`;

    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setAppointments(data.query_result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const apiEndpoint =
    "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query";
  const headers = {
    "Content-Type": "application/json",
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCurrentAppointment((prevData) => ({
      ...prevData,
      [name]: type == "checkbox" ? (checked == true ? "on" : "off") : value,
    }));
  };

  const createOrUpdateAppoitment = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));
    setFormValues(data);
    let type_of = data["typeOfRequest"];
    delete data["typeOfRequest"];

    const req_value = {
      task: "appointmentSetData",
      query_dict: JSON.stringify(data),
      typeOfRequest: type_of || "INSERT",
    };

    fetch(apiEndpoint, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(req_value),
    })
      .then((response) => {
        setIsSubmitted((prev) => !prev);
        setIsScheduleAppointmentModalOpen(false);
        return response.json();
      })
      .then((data) => {
        updateAppointment();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    handleClose();
  };
  useEffect(() => {
    getProfile();
    if (isSubmitted) {
      setAppoitments();
    }
  }, [isSubmitted, patientId]);

  const updateAppointment = () => {
    setAppoitments();
  };

  const handleDeleteClick = (appointmentId, patientId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this task?"
    );
    if (isConfirmed) {
      const req_value = {
        task: "appointmentSetData",
        query_dict: JSON.stringify({
          appointmentId: appointmentId,
          clinicianId: profile?.user_id,
          patientId: patientId,
        }),
        typeOfRequest: "DELETE", // 'DELETE', 'INSERT', or 'UPDATE'
      };
      fetch(apiEndpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(req_value),
      })
        .then((response) => {
          setIsSubmitted((prev) => !prev);
          return response.json();
        })
        .then((data) => {
          updateAppointment();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div id="appointments">
        <div className="headingTable">
          <div className="row">
            <div className="col">
              <h1 className="mb-0">Appointments</h1>
            </div>
            <div className="col-auto">
              <div className="d-md-flex">

                <div
                  className="btn-group btn-group-lg ms-2 gridList nav nav-pills"
                  id="pills-tab"
                  role="group"
                >
                  <button
                    type="button"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    className="btn btn-primary nav-link py-0 px-2 rounded-start-2 bg-light border-0"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="24"
                      viewBox="0 0 21 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5392_372)">
                        <path
                          d="M5.5 22.2597V1.74076M20 5.93781H1M20 10.1349H1M20 14.332H1M20 18.529H1M1.5 22.7261H19.5C19.7761 22.7261 20 22.5022 20 22.2261V1.77442C20 1.49827 19.7761 1.27441 19.5 1.27441H1.5C1.22386 1.27441 1 1.49827 1 1.77442V22.2261C1 22.5022 1.22386 22.7261 1.5 22.7261Z"
                          stroke="#4264D0"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5392_372">
                          <rect
                            width="21"
                            height="23"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary nav-link py-0 px-2 rounded-end-2 active bg-light border-0"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5392_374)">
                        <path
                          d="M22.5 0.5C22.7761 0.5 23 0.723858 23 1V4C23 4.27614 22.7761 4.5 22.5 4.5H0.5C0.223858 4.5 0 4.27614 0 4V1C0 0.723858 0.223858 0.5 0.5 0.5H22.5Z"
                          fill="#4264D0"
                        />
                        <path
                          d="M22.5 6.5C22.7761 6.5 23 6.72386 23 7V10C23 10.2761 22.7761 10.5 22.5 10.5H0.5C0.223858 10.5 0 10.2761 0 10V7C0 6.72386 0.223858 6.5 0.5 6.5H22.5Z"
                          fill="#4264D0"
                        />
                        <path
                          d="M22.5 12.5C22.7761 12.5 23 12.7239 23 13V16C23 16.2761 22.7761 16.5 22.5 16.5H0.5C0.223858 16.5 0 16.2761 0 16V13C0 12.7239 0.223858 12.5 0.5 12.5H22.5Z"
                          fill="#4264D0"
                        />
                        <path
                          d="M22.5 18.5C22.7761 18.5 23 18.7239 23 19V22C23 22.2761 22.7761 22.5 22.5 22.5H0.5C0.223858 22.5 0 22.2761 0 22V19C0 18.7239 0.223858 18.5 0.5 18.5H22.5Z"
                          fill="#4264D0"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5392_374">
                          <rect
                            width="23"
                            height="22"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content mt-4" id="pills-tabContent">
          <div
            className="tab-pane fade"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="bg-light rounded-3 p-5 text-center border-bottom dateBG">
              <i className="far fa-long-arrow-left pe-3"></i> Nov 28 2023,
              Tuesday <i className="far fa-long-arrow-right ps-3"></i>
            </div>
            <div className="row mt-3">
              <div className="col-md-7">
                <ul className="p-0 appointMent-list">
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                    </div>
                  </li>
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                    </div>
                  </li>
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                      <h3 className="m-0 w-75 h-100 ps-2 py-1 blueBg text-white">
                        <label className="text-white">04:00PM-05:00PM</label>{" "}
                        <br />
                        <span className="text-white">
                          Dr. Jones{" "}
                          <p className="m-0 d-inline-block text-white">1x1</p>
                        </span>
                      </h3>
                    </div>
                  </li>
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                    </div>
                  </li>
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                      <h3 className="m-0 w-75 h-100 ps-2 py-1 successBg">
                        <label>04:00PM-05:00PM</label> <br />
                        <span>
                          Dr. Jones <p className="m-0 d-inline-block">1x1</p>
                        </span>
                      </h3>
                    </div>
                  </li>
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                      <h3 className="m-0 w-75 h-100 ps-2 py-1 dangerBg">
                        <label>04:00PM-05:00PM</label> <br />
                        <span>
                          Dr. Jones <p className="m-0 d-inline-block">1x1</p>
                        </span>
                      </h3>
                    </div>
                  </li>
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                      <h3 className="m-0 w-75 h-100 ps-2 py-1 alertBg">
                        <label>04:00PM-05:00PM</label> <br />
                        <span>
                          Dr. Jones <p className="m-0 d-inline-block">1x1</p>
                        </span>
                      </h3>
                    </div>
                  </li>
                  <li className="border-top w-100">
                    <div className="d-flex">
                      <span className="px-4 w-25 pb-3 pt-1">11:00AM</span>
                      <h3 className="m-0 w-75 h-100 ps-2 py-1 alertBg">
                        <label>04:00PM-05:00PM</label> <br />
                        <span>
                          Dr. Jones <p className="m-0 d-inline-block">1x1</p>
                        </span>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-5">
                <div className="bg-light p-3 roundedCustom cardPaitent">
                  <div className="d-flex justify-content-between">
                    <Link className="text-decoration-none">
                      <h4 className="mb-1">Dr. Williams 1x1 </h4>
                    </Link>
                    <i className="fas fa-ellipsis-h"></i>
                  </div>
                  <p className="mb-0">Tuesday, Nov 28 2023 02:30-03:30PM</p>
                  <h3 className="m-0 w-auto ps-2 successBg mt-2 d-inline-block pe-2 py-1">
                    <p className="m-0">Individual Appointment</p>
                  </h3>
                  <div className="participants mt-4">
                    <h3>Participants:</h3>
                    <ul className="p-0 gap-2 d-grid">
                      <li className="d-flex flex-wrap align-items-center">
                        <i className="far fa-check"></i>
                        <img
                          src="images/ellipse-5.png"
                          className="mx-2"
                          width={25}
                          alt=""
                        />
                        <span>Lori Briggs</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <i className="far fa-check"></i>
                        <img
                          src="images/ellipse-6.png"
                          className="mx-2"
                          width={25}
                          alt=""
                        />
                        <span>Dr. Williams</span>
                        <img
                          src="images/send-message.png"
                          className="mx-2"
                          alt=""
                        />
                        <h6 className="mb-0">Send message</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="tableList">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">
                      Date <img src="images/short-icon.svg" alt="" />
                    </th>
                    <th scope="col">
                      Duration <img src="images/short-icon.svg" alt="" />
                    </th>
                    <th scope="col">
                      Provider <img src="images/short-icon.svg" alt="" />
                    </th>
                    <th scope="col">Notes</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {appointments.map((appointment, index) => (
                    <tr key={index}>
                      <td>
                        {appointment.dateValue} <br />
                        <span>{appointment.timeValue}</span>
                      </td>
                      <td>{appointment.duration}</td>
                      <td>
                        <h6 className="mb-0">
                          <img src={appointment.providerImage} alt="" />
                          {appointment.participants}
                        </h6>
                      </td>
                      <td>
                        <p className="mb-0">
                          {appointment.appointmentDescription}
                        </p>
                        <Link
                          className="text-decoration-none moreDetails"
                          onClick={() => {
                            setSelectedAppointmentDetailData(appointment);
                            setIsAppointmentDetailModalOpen(true);
                          }}
                        >
                          See more
                        </Link>
                      </td>

                      <td>
                        <button
                          type="button"
                          className={`btn`}
                          onClick={() =>
                            handleDeleteClick(
                              appointment?.appointmentId,
                              appointment.patientId
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        </button>

                        <button
                          type="button"
                          className={`btn`}
                          onClick={() => {
                            setCurrentAppointment(appointment);
                            setIsScheduleAppointmentModalOpen(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pen"
                            viewBox="0 0 16 16"
                          >
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isAppointmentModalOpen}
        onRequestClose={() => setIsAppointmentDetailModalOpen(false)}
      >
        <AppointmentModal
          appointmentDetailInfo={{
            selectedAppointmentDetailData: selectedAppointmentDetailData,
            setIsAppointmentDetailModalOpen: setIsAppointmentDetailModalOpen,
          }}
        />
      </Modal>

      <Modal
        isOpen={isScheduleAppointmentModalOpen}
        onRequestClose={() => setIsScheduleAppointmentModalOpen(false)}
      >
        <ScheduleAppointmentModal
          scheduleAppointmenInfo={{
            selectedScheduleAppointmentData: selectedScheduleAppointmentData,
            setIsScheduleAppointmentModalOpen:
              setIsScheduleAppointmentModalOpen,
            updateAppointment: updateAppointment,
            patientId: patientId,
            currentAppointment: currentAppointment,
            clinicianId: profile?.user_id,
          }}
          createOrUpdateAppoitment={createOrUpdateAppoitment}
          handleChange={handleChange}
        />
      </Modal>
    </>
  );
};

export default Appointment;
