import React, { createContext, useContext, useState, ReactNode } from "react";
import { io, Socket } from "socket.io-client";

interface SocketContextProps {
  socket: Socket | undefined;
  loading: boolean;
  error: Error | null;
  disconnectSocket: () => void;
  setSocketConnection: any;
}

const socketContext = createContext<SocketContextProps | undefined>(undefined);

interface SocketProviderProps {
  children?: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const setSocketConnection = (userId: any, username: any) => {
    try {
      const socketInstance = io(`${process.env.REACT_APP_SOCKET_API}`, {
        auth: {
          userId: userId,
          username: username,
        },
        // transports: ['polling','websocket', 'webtransport'],
        // upgrade: true,
      });
      setSocket(socketInstance);
    } catch (errData) {
      console.log("Socket connection error:", errData);
      setError(errData as Error);
      setLoading(false);
    }
  };

  const disconnectSocket = () => {
    if (socket) {
      socket?.disconnect();
    }
  };

  return (
    <socketContext.Provider
      value={{ socket, loading, error, disconnectSocket, setSocketConnection }}
    >
      {children}
    </socketContext.Provider>
  );
};

export const useSocketContext = (): SocketContextProps => {
  const context = useContext(socketContext);
  if (!context) {
    throw new Error("useSocketContext must be used within a SocketProvider");
  }
  return context;
};
