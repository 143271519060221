import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddNewTaskModal from "../../../modals/AddNewTaskModal";
import AssessmentTaskModal from "../../../modals/AssessmentTaskModal";
import Modal from "react-modal";
import { taskassessmentGetAssignedTask } from "lib/api/service";

const Task = () => {
  const [selectedStatus, setSelectedStatus] = useState("Select status");
  const [isAssessmentTaskModalOpen, setIsAssessmentTaskModalOpen] =
    useState(false);
  const [isAddNewTaskModalModalOpen, setAddNewTaskModalOpen] = useState(false);
  const [selectedTaskData, setSelectedTaskData] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [fetchLatest, setFetchLatest] = useState(false);

  const setAddNewTaskCloseModal = () => {
    setAddNewTaskModalOpen(false);
  };

  const [tasks, setTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const patientId = pathParts[pathParts.length - 1];

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedStatus(value);
  };

  useEffect(() => {
    updateTasks();
    if (fetchLatest) {
      setFetchLatest(false);
    }
  }, [fetchLatest]);

  const updateTasks = async () => {
    try {
      if (patientId) {
        const response = await taskassessmentGetAssignedTask(patientId);
        setTasks(response);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  return (
    <>
      <div id="task">
        <div className="headingTable mb-3">
          <div className="row align-items-center">
            <div className="col-auto">
              <h1 className="mb-0">Tasks</h1>
            </div>
            <div className="col">
              <div className="row justify-content-end">
                <div className="col-md-3 pe-md-0">
                  <select
                    className="form-select h-auto"
                    defaultValue="Select status"
                    value={selectedStatus}
                    onChange={handleChange}
                  >
                    <option value="Select status" disabled>
                      Select status
                    </option>
                    <option value="Select status">All</option>
                    <option value="pending">Pending</option>
                    <option value="In-progress">In-Progress</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <button
                    type="button"
                    className="btn btn-success border-0"
                    onClick={() => {
                      setAddNewTaskModalOpen(true);
                      setCurrentTask(null);
                    }}
                  >
                    <i className="fas fa-list-alt"></i> Add new task
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content mt-4" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="tableList">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "12%" }}>
                      Title
                    </th>
                    <th scope="col" style={{ width: "17%" }}>
                      Description
                    </th>
                    <th scope="col" style={{ width: "16%" }}>
                      Provider
                    </th>
                    <th scope="col" style={{ width: "16%" }}>
                      Created <img src="images/short-icon.svg" alt="" />
                    </th>
                    <th scope="col" style={{ width: "16%" }}>
                      Performer
                    </th>
                    <th scope="col" style={{ width: "16%" }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tasks && Array.isArray(tasks) && tasks.length > 0 ? (
                    tasks
                      .filter(
                        (task) =>
                          selectedStatus === "Select status" ||
                          task.status === selectedStatus
                      )
                      .map((task, index) => (
                        <tr key={index}>
                          <td>
                            <h6
                              className="mb-0"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: "4px",
                                marginBottom: "4px",
                              }}
                            >
                              {task?.title}
                            </h6>
                          </td>
                          <td>
                            <span
                              className="curser-pointer"
                              onClick={() => {
                                setSelectedTaskData(task);
                                setIsAssessmentTaskModalOpen(true);
                              }}
                            >
                              {task.note.length > 10
                                ? `${task.note.substring(0, 12)}...`
                                : task.note}{" "}
                              <img src="/images/tinder.png" width={14} alt="" />{" "}
                              <br />
                              <img src="/images/message.svg" alt="" />
                              <span className="comment p-1">
                                {task.comment_count} comments
                              </span>
                            </span>
                          </td>
                          <td>
                            <h6 className="mb-0">
                              <img src={task.providerImage} alt="" />
                              {task.provider_name}
                            </h6>
                          </td>
                          <td>
                            <h6 className="mb-0"> {task.date}</h6>
                          </td>
                          <td>
                            <h6 className="mb-0">
                              <img src={task.performerImage} alt="" />
                              {task.performer_name}
                            </h6>
                          </td>
                          <td>
                            <button
                              type="button"
                              className={`btn text-capitalize ${
                                task.status === "pending"
                                  ? "pendingBtn"
                                  : task.status === "completed"
                                  ? "completeBtn"
                                  : "inProgressBtn"
                              }`}
                            >
                              {task.status}
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center py-5">
                        No task assessments found for this patient
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isAddNewTaskModalModalOpen}
        onRequestClose={() => setAddNewTaskModalOpen(false)}
      >
        <AddNewTaskModal
          closeModal={setAddNewTaskCloseModal}
          updateTasks={updateTasks}
          patientId={patientId}
          taskId={selectedTaskId}
          currentTask={currentTask}
          setFetchLatest={setFetchLatest}
          fetchLatest={fetchLatest}
        />
      </Modal>

      <Modal
        isOpen={isAssessmentTaskModalOpen}
        onRequestClose={() => setIsAssessmentTaskModalOpen(false)}
      >
        <AssessmentTaskModal
          taskInfo={{
            selectedTaskData: selectedTaskData,
            setIsAssessmentTaskModalOpen: setIsAssessmentTaskModalOpen,
          }}
          setFetchLatest={setFetchLatest}
          fetchLatest={fetchLatest}
        />
      </Modal>
    </>
  );
};

export default Task;
