import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
// import PsychotherapyOtherModal from '../../../../../modals/Document/Other/PsychotherapyOtherModal';
import PsychotherapyOtherModal from "../../../../../modals/Document/Indivisual/Other/PsychotherapyOtherModal";
import { formatDateWithMonth } from "utils";
import TableButton from "components/Document/TableButton/TableButton";

const Psychotherapy = ({ clinicalData }) => {
  const [isPsychotherapyModalOpen, setIsPsychotherapyModalOpen] =
    useState(false);
  const [selectedPsychotherapyData, setSelectedPsychotherapyData] =
    useState("");

  const token = localStorage.getItem("token");

  return (
    <>
      <div id="psychotherapy">
        <div className="tab-content mt-4" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab">
            <div className="tableList-2">
              <table className="table table-borderless" style={{fontSize: '14px'}}>
                <thead>
                  <tr>
                    <th scope="col">Note name</th>
                    <th scope="col">
                      Provider <img src="../../images/short-icon.svg" alt="" />
                    </th>
                    <th scope="col">
                      Created <img src="../../images/short-icon.svg" alt="" />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {clinicalData?.map((psychotherapy) => (
                    <tr key={psychotherapy.id}>
                      <td className="">
                        <div className="d-flex">
                          <img src="../../images/note.svg" alt="" />
                          <div className="ms-3">
                            <label>{`${
                              psychotherapy.provider_name
                            }_${formatDateWithMonth(
                              psychotherapy.createdate
                            )}psych`}</label>
                            <p className="mb-0 w-100 d-block text-break">
                              {psychotherapy.psychotherapy_note}{" "}
                            </p>
                            <Link
                              className="text-decoration-none moreDetails"
                              onClick={() => {
                                setSelectedPsychotherapyData(psychotherapy);
                                setIsPsychotherapyModalOpen(true);
                              }}>
                              See more
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h6 className="mb-0">
                          <img src={psychotherapy.providerImage} alt="" />
                          {psychotherapy.provider_name}
                        </h6>
                      </td>
                      <td>
                        {psychotherapy.createdate} <br />
                        <span>{psychotherapy.createtime}</span>
                      </td>
                      <td>
                        <TableButton title="Download" onClick={() => {
                          window.open(`${process.env.REACT_APP_BASE_API_URL}/documentation/download/${psychotherapy.id}?token=${token}`, '_blank');
                        }}>
                          <img src="../../images/download.svg" alt="" />
                        </TableButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isPsychotherapyModalOpen}
        onRequestClose={() => setIsPsychotherapyModalOpen(false)}>
        <PsychotherapyOtherModal
          psychotherapyDetailInfo={{
            selectedPsychotherapyData: selectedPsychotherapyData,
            setIsPsychotherapyModalOpen: setIsPsychotherapyModalOpen,
          }}
        />
      </Modal>
    </>
  );
};

export default Psychotherapy;
