import React, { useEffect, useState } from "react";
import "./Cards.css";
import { Card, Row, Col, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const CardsComponent = () => {
  const navigate = useNavigate();
  const [allPatientLists, setAllPatientLists] = useState([]);
  const [userData, setUserData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [randomUser, setRandomUser] = useState("/images/patients.svg");

  const token = localStorage.getItem("token");

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    if (storedData) {
      setUserData(storedData.rows);
    }
  }, []);

  const acceptPatientRequest = async () => {
    try {
      const data = {
        doctorEmail: userData?.email,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/assignuser/allassingpatients`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllPatientLists(response?.data?.results);
      setDataLoaded(true);
    } catch (error) {
      console.error(error?.response?.data?.message || "Error assigning user");
    }
  };

  useEffect(() => {
    if (userData?.email && !dataLoaded) {
      acceptPatientRequest();
    }
  }, [userData?.email, dataLoaded]);

  const calculateAge = (patientDob) => {
    const today = new Date();
    const birthDate = new Date(patientDob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <Row xs={1} sm={1} md={1} lg={2} xl={3}>
      {allPatientLists.length === 0 ? (
        <h5> No record found</h5>
      ) : (
        allPatientLists.map((child, index) => (
          <Col key={index}>
            <Card className="therapy-card mb-5">
              <Card.Body>
                <div className="col-md-12 mb-1">
                  <div className="d-flex align-items-center">
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Image
                          className="profile-img"
                          src={"/images/avatar.png"}
                          alt="profile-icon"
                          roundedCircle
                        />
                      </Col>
                      <Col>
                        <Row>
                          <Col className="d-flex justify-content-between p-0">
                            <h4 className="dashboard_name">{`Hi ${child?.patient_name}`}</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} className="p-0">
                            <div className="d-flex align-items-center">
                              <label className="me-2 label_text">
                                Patient №:
                              </label>
                              <p className="mb-0 text_size">
                                {child?.patient_id}
                              </p>
                            </div>
                          </Col>
                          <Col md={6} className="p-0">
                            <div className="d-flex align-items-center">
                              <label className="me-2 label_text">LOC:</label>
                              <p className="mb-0 text_size">{"IOP"}</p>
                            </div>
                          </Col>
                          <Col md={6} className="p-0">
                            <div className="d-flex align-items-center">
                              <label className="me-2 label_text">Age:</label>
                              <p className="mb-0 text_size">
                                {`${calculateAge(child?.dob)} years`}
                              </p>
                            </div>
                          </Col>
                          <Col md={6} className="p-0">
                            <div className="d-flex align-items-center">
                              <label className="me-2 label_text">DOB:</label>
                              <p className="mb-0 text_size">{child?.dob}</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="psychiarist_div">
                    <div className="upper_div">
                      <span className="info_title">Psychiatrist:</span>
                      <div className="inner_div">
                        <img
                          src={randomUser}
                          alt="psychiarist.png"
                          className="info_img"
                        />
                        <span className="info_name">{"Dr.Jones, MD"}</span>
                        <span className="info_div">|</span>
                        <span className="info_date">{"Date"}</span>
                      </div>
                    </div>
                    <div className="lower_div">
                      <span className="info_desc">
                        {
                          "Patient started 100mg 2010ft in addition to treatment."
                        }
                      </span>
                    </div>
                  </div>
                  <div className="therapist_div mt-3">
                    <div className="upper_div">
                      <span className="info_title">Primary therapist:</span>
                      <div className="inner_div">
                        <img
                          src={randomUser}
                          alt="psychiarist.png"
                          className="info_img"
                        />
                        <span className="info_name">{"Dr.Jones, MD"}</span>
                        <span className="info_div">|</span>
                        <span className="info_date">{"Date"}</span>
                      </div>
                    </div>
                    <div className="lower_div">
                      <span className="info_desc">
                        {
                          "Patient started 100mg 2010ft in addition to treatment."
                        }
                      </span>
                    </div>
                  </div>
                  <div className="group_therapist mt-3">
                    <div className="upper_div">
                      <span className="info_title">Group therapist:</span>
                      <div className="inner_div">
                        <img
                          src={randomUser}
                          alt="psychiarist.png"
                          className="info_img"
                        />
                        <span className="info_name">{"Dr.Jones, MD"}</span>
                        <span className="info_div">|</span>
                        <span className="info_date">{"Date"}</span>
                      </div>
                    </div>
                    <div className="lower_div">
                      <span className="info_desc my_line_height">
                        {
                          "Patient participated in DBT group, patient showed distress during process when other patient talked about family physical abuse."
                        }
                      </span>
                    </div>
                  </div>
                  <div className="codes mt-3">
                    <span className="info_title">ICD-10 Codes:</span>
                    <ul className="codes_list">
                      <li className="info_name">
                        F31.9 Bipolar Disorder, specified
                      </li>
                      <li className="info_name">
                        F43.10 - Post-traumatic stress disorder, unspecified
                      </li>
                    </ul>
                  </div>
                </div>
              </Card.Body>

              <div className="col-md-12 mt-1 d-flex justify-content-between align-items-center">
                {child.intakeForm == 0 && (
                  <div className="d-flex align-items-center dischargeTxt">
                    <i className="fas fa-minus-circle me-1"></i>
                    <label className="me-2 dischargeTxt">
                      <Link
                        to={`/profile/newPatient/${child?.patient_id}/page1`}
                        className="text-decoration-none"
                      >
                        Intake patient
                      </Link>
                    </label>
                  </div>
                )}
                <h6
                  onClick={() => navigate(`/profile/${child?.patient_id}`)}
                  className="card_footer_text"
                  style={{ marginTop: "-18px", marginBottom: "15px" }}
                >
                  {"See more"}
                </h6>
              </div>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );
};

export default CardsComponent;
