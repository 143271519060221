import {
  border,
  borderRadius,
  color,
  height,
  margin,
  padding,
  width,
} from "@mui/system";
import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import toast from "react-hot-toast";

const InsuranceBenefits = ({
  formData,
  handleChange,
  benefitsData,
  setBenefitsData,
  handleSubmit,
  setFormData,
  listingallPatients,
  listingProfile,
}) => {
  // const [benefitsData, setBenefitsData] = useState({
  //   familyOpp: { firstValue: "", secondValue: "", percentageResult: "0" },
  //   individualOpp: { firstValue: "", secondValue: "", percentageResult: "0" },
  //   familyDeductible: {
  //     firstValue: "",
  //     secondValue: "",
  //     percentageResult: "0",
  //   },
  //   individualDeductible: {
  //     firstValue: "",
  //     secondValue: "",
  //     percentageResult: "0",
  //   },
  // });

  // useEffect(() => {
  //   if (listingallPatients?.[0]?.insurancebenefit?.benefitsDataArray) {
  //     const benefitsArray =
  //       listingallPatients[0].insurancebenefit.benefitsDataArray;

  //     setBenefitsData((prevData) => {
  //       const updatedData = { ...prevData };

  //       benefitsArray.forEach((item) => {
  //         const category = item.category;

  //         updatedData[category] = {
  //           firstValue:
  //             prevData[category]?.firstValue || item.firstValue || "0",
  //           secondValue:
  //             prevData[category]?.secondValue || item.secondValue || "0",
  //           percentageResult: item.percentageResult
  //             ? item.percentageResult
  //             : prevData[category]?.firstValue &&
  //               prevData[category]?.secondValue
  //             ? (
  //                 (prevData[category].firstValue /
  //                   prevData[category].secondValue) *
  //                 100
  //               ).toFixed(2)
  //             : "0",
  //         };
  //       });

  //       return updatedData;
  //     });
  //   } else {
  //     // Assign "0" for all categories when benefitsDataArray is not found
  //     setBenefitsData({
  //       familyOpp: {
  //         firstValue: "0",
  //         secondValue: "0",
  //         percentageResult: "0",
  //       },
  //       individualOpp: {
  //         firstValue: "0",
  //         secondValue: "0",
  //         percentageResult: "0",
  //       },
  //       familyDeductible: {
  //         firstValue: "0",
  //         secondValue: "0",
  //         percentageResult: "0",
  //       },
  //       individualDeductible: {
  //         firstValue: "0",
  //         secondValue: "0",
  //         percentageResult: "0",
  //       },
  //     });
  //   }
  // }, [listingallPatients, setBenefitsData]);

  useEffect(() => {
    if (listingallPatients?.[0]?.insurancebenefit?.benefitsDataArray) {
      const benefitsArray =
        listingallPatients[0].insurancebenefit.benefitsDataArray;

      setBenefitsData((prevData) => {
        const updatedData = { ...prevData };

        benefitsArray.forEach((item) => {
          const category = item.category;

          updatedData[category] = {
            firstValue:
              item.firstValue !== undefined
                ? item.firstValue
                : prevData[category]?.firstValue,
            secondValue:
              item.secondValue !== undefined
                ? item.secondValue
                : prevData[category]?.secondValue,
            percentageResult:
              item.percentageResult !== undefined
                ? item.percentageResult
                : prevData[category]?.firstValue &&
                  prevData[category]?.secondValue
                ? (
                    (parseFloat(prevData[category].firstValue) /
                      parseFloat(prevData[category].secondValue)) *
                    100
                  ).toFixed(2)
                : "",
          };
        });

        // console.log("ckeck update");
        return updatedData;
      });
    } else {
      // Assign "0" for all categories when benefitsDataArray is not found
      setBenefitsData({
        familyOpp: {
          firstValue: "0",
          secondValue: "0",
          percentageResult: "0",
        },
        individualOpp: {
          firstValue: "0",
          secondValue: "0",
          percentageResult: "0",
        },
        familyDeductible: {
          firstValue: "0",
          secondValue: "0",
          percentageResult: "0",
        },
        individualDeductible: {
          firstValue: "0",
          secondValue: "0",
          percentageResult: "0",
        },
      });
    }

    if (listingallPatients?.[0]?.insurancebenefit) {
      // console.log("check update 2");
      setFormData((prevFormData) => ({
        ...prevFormData,
        coverage1:
          listingallPatients[0].insurancebenefit.coverage1 ||
          prevFormData.coverage1,
        copayment1:
          listingallPatients[0].insurancebenefit.copayment1 ||
          prevFormData.copayment1,
        coverage2:
          listingallPatients[0].insurancebenefit.coverage2 ||
          prevFormData.coverage2,
        copayment2:
          listingallPatients[0].insurancebenefit.copayment2 ||
          prevFormData.copayment2,
        coverage3:
          listingallPatients[0].insurancebenefit.coverage3 ||
          prevFormData.coverage3,
        copayment3:
          listingallPatients[0].insurancebenefit.copayment3 ||
          prevFormData.copayment3,
        coverage4:
          listingallPatients[0].insurancebenefit.coverage4 ||
          prevFormData.coverage4,
        copayment4:
          listingallPatients[0].insurancebenefit.copayment4 ||
          prevFormData.copayment4,
        coverage5:
          listingallPatients[0].insurancebenefit.coverage5 ||
          prevFormData.coverage5,
        copayment5:
          listingallPatients[0].insurancebenefit.copayment5 ||
          prevFormData.copayment5,
        coverage6:
          listingallPatients[0].insurancebenefit.coverage6 ||
          prevFormData.coverage6,
        deductible:
          listingallPatients[0].insurancebenefit.deductible ||
          prevFormData.deductible,
      }));
    }
  }, [listingallPatients, setBenefitsData, listingProfile]);

  const validateInput = (value) => {
    if (/^[0-9]+(\.[0-9]+)?$/.test(value) || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleBenefitsData = (event) => {
    const { name, value } = event.target;

    if (!validateInput(value)) {
      toast.error("Invalid number");
    }

    setBenefitsData((prevValue) => {
      const [key, field] = name.split(".");

      const newValue = {
        ...prevValue,
        [key]: {
          ...prevValue[key],
          [field]: value,
        },
      };

      const firstValue = parseFloat(newValue[key].firstValue);
      const secondValue = parseFloat(newValue[key].secondValue);

      newValue[key].percentageResult = "0";

      if (!isNaN(firstValue) && !isNaN(secondValue)) {
        if (secondValue > 0 && secondValue >= firstValue) {
          const percentage = (firstValue / secondValue) * 100;
          newValue[key].percentageResult = percentage.toFixed(2);
        } else if (secondValue <= 0) {
          console.warn("secondValue must be greater than zero.");
        } else {
          console.warn("secondValue must be greater than firstValue.");
        }
      } else {
        console.warn("Please ensure both values are valid numbers.");
      }

      return newValue;
    });
  };

  // console.log(listingallPatients);
  // console.log(listingallPatients?.[0]?.insurancebenefit?.benefitsDataArray);
  // console.log("adgasfgasdf", benefitsData.familyOpp.percentageResult);

  return (
    <div id="treatmentPlan">
      <div className="headingTable cardPaitent">
        <div className="row d-flex mt-4">
          <div className="col-4">
            <div className="row d-flex mb-2">
              <h1 className="mb-2">Outpatient Mental Health Services</h1>
            </div>
            <br />
            <span>Individual therapy</span>
            <br />
            <br />
            <br />
            <span>Group therapy</span>
            <br />
            <br />
            <br />
            <span>Family therapy</span>
            <br />
            <br />
            <br />
            <span>Psychiatric consultations</span>
            <br />
            <br />
            <br />
            <span>Medication management</span>
            <div className="row d-flex mt-4">
              <h1 className="mb-2 mt-4">Inpatient Mental Health Services</h1>
            </div>
            <span>Inpatient psychiatric hospitalization</span>
            <div className="row d-flex mt-5">
              <h1 className="mb-1">Network Providers</h1>
            </div>
            <span>Preferred network</span>
          </div>
          <div className="col-4">
            <div className="row d-flex mb-4">
              <h1 className="mb-2">Insurance coverage</h1>
            </div>
            <span>
              Covered at
              <input
                // placeholder={
                //   listingallPatients?.[0]?.insurancebenefit?.coverage1 !==
                //   undefined
                //     ? `${listingallPatients[0].insurancebenefit.coverage1}`
                //     : "$0"
                // }
                type="text"
                name="coverage1"
                value={formData.coverage1}
                placeholder={"%0"}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.copayment1}` ||
                //   "$0"
                // }
                type="text"
                name="copayment1"
                value={formData.copayment1}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.coverage2}` ||
                //   "$0"
                // }
                type="text"
                name="coverage2"
                value={formData.coverage2}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.copayment2}` ||
                //   "$0"
                // }
                type="text"
                name="copayment2"
                value={formData.copayment2}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.coverage3}` ||
                //   "$0"
                // }
                type="text"
                name="coverage3"
                value={formData.coverage3}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.copayment3}` ||
                //   "$0"
                // }
                type="text"
                name="copayment3"
                value={formData.copayment3}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at{" "}
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.coverage4}` ||
                //   "$0"
                // }
                type="text"
                name="coverage4"
                value={formData.coverage4}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.copayment4}` ||
                //   "$0"
                // }
                type="text"
                name="copayment4"
                value={formData.copayment4}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              copayment per visit.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.coverage5}` ||
                //   "$0"
                // }
                type="text"
                name="coverage5"
                value={formData.coverage5}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.copayment5}` ||
                //   "$0"
                // }
                type="text"
                name="copayment5"
                value={formData.copayment5}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              copayment per visit.
            </span>
            <br />
            <br />
            <br />
            <br />
            <span>
              Covered at
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.coverage6}` ||
                //   "$0"
                // }
                type="text"
                name="coverage6"
                value={formData.coverage6}
                placeholder={"%0"}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                // placeholder={
                //   `${listingallPatients?.[0]?.insurancebenefit?.deductible}` ||
                //   "$0"
                // }
                type="text"
                name="deductible"
                value={formData.deductible}
                placeholder={"$0"}
                onChange={handleChange}
                style={style}
              />
              deductible per admission. Requires preauthorization.
            </span>
            <br />
            <br />
            <br />
            <span>
              Trustory Insurance has a network of mental health providers. Using
              in-network providers will result in lower out-of-pocket costs.
            </span>
          </div>
          <div className="col-4" style={{ background: "#F8F9FE" }}>
            <div className="row d-flex mt-4">
              <div className="col-2">
                <div className="flex-shrink-0">
                  <img
                    src="/images/Salary_Date.png"
                    alt=""
                    width={50}
                    height={50}
                  />
                </div>
              </div>
              <div className="col-10">
                <h2
                  className="mb-2"
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#5B6680",
                    lineHeight: "21px",
                  }}
                >
                  Benefits plan
                </h2>
                <h1 className="text-center">
                  {/* {listingallPatients[0]?.Inr_com_name} */}
                  Insurance Company
                </h1>
              </div>
            </div>
            {/* Benefit 1 */}
            <h1 className="text-left">
              {benefitsData.familyOpp.percentageResult
                ? `${benefitsData.familyOpp.percentageResult}%`
                : "N/A"}
            </h1>
            <ProgressBar now={benefitsData.familyOpp.percentageResult || 0} />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="$0"
                  type="text"
                  name="familyOpp.firstValue"
                  value={benefitsData.familyOpp.firstValue}
                  onChange={handleBenefitsData}
                  style={style2}
                />
                of your
                <input
                  placeholder="$0"
                  type="text"
                  name="familyOpp.secondValue"
                  value={benefitsData.familyOpp.secondValue}
                  onChange={handleBenefitsData}
                  style={style3}
                />
                Family Out-of-Pocket has been met for 2023
              </span>
            </div>

            {/* Benefit 2 */}
            <h1 className="text-left">
              {benefitsData.individualOpp.percentageResult
                ? `${benefitsData.individualOpp.percentageResult}%`
                : "N/A"}
            </h1>
            <ProgressBar
              now={benefitsData.individualOpp.percentageResult || 0}
            />
            <div className="row">
              <span className="mt-4">
                <input
                  type="text"
                  name="individualOpp.firstValue"
                  value={benefitsData.individualOpp.firstValue}
                  placeholder={
                    listingallPatients?.[0]?.insurancebenefit
                      ?.benefitsDataArray?.[1]?.firstValue || "$0"
                  }
                  onChange={handleBenefitsData}
                  style={style2}
                />
                of your
                <input
                  placeholder={
                    listingallPatients?.[0]?.insurancebenefit
                      ?.benefitsDataArray?.[1]?.secondValue || "$0"
                  }
                  type="text"
                  name="individualOpp.secondValue"
                  value={benefitsData.individualOpp.secondValue}
                  onChange={handleBenefitsData}
                  style={style3}
                />
                Individual Out-of-Pocket Max has been met for 2023
              </span>
            </div>

            {/* Benefit 3 */}
            <h1 className="text-left">
              {benefitsData.familyDeductible.percentageResult
                ? `${benefitsData.familyDeductible.percentageResult}%`
                : "N/A"}
            </h1>
            <ProgressBar
              now={benefitsData.familyDeductible.percentageResult || 0}
            />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder={
                    listingallPatients?.[0]?.insurancebenefit
                      ?.benefitsDataArray?.[2]?.firstValue || "$0"
                  }
                  type="text"
                  name="familyDeductible.firstValue"
                  value={benefitsData.familyDeductible.firstValue}
                  onChange={handleBenefitsData}
                  style={style2}
                />
                of your
                <input
                  placeholder={
                    listingallPatients?.[0]?.insurancebenefit
                      ?.benefitsDataArray?.[2]?.secondValue || "$0"
                  }
                  type="text"
                  name="familyDeductible.secondValue"
                  value={benefitsData.familyDeductible.secondValue}
                  onChange={handleBenefitsData}
                  style={style3}
                />
                Family Deductible has been met for 2023
              </span>
            </div>

            {/* Benefit 4 */}
            <h1 className="text-left">
              {benefitsData.individualDeductible.percentageResult
                ? `${benefitsData.individualDeductible.percentageResult}%`
                : "N/A"}
            </h1>
            <ProgressBar
              now={benefitsData.individualDeductible.percentageResult || 0}
            />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder={
                    listingallPatients?.[0]?.insurancebenefit
                      ?.benefitsDataArray?.[3]?.firstValue || "$0"
                  }
                  type="text"
                  name="individualDeductible.firstValue"
                  value={benefitsData.individualDeductible.firstValue}
                  onChange={handleBenefitsData}
                  style={style2}
                />
                of your
                <input
                  placeholder={
                    listingallPatients?.[0]?.insurancebenefit
                      ?.benefitsDataArray?.[3]?.secondValue || "$0"
                  }
                  type="text"
                  name="individualDeductible.secondValue"
                  value={benefitsData.individualDeductible.secondValue}
                  onChange={handleBenefitsData}
                  style={style3}
                />
                Individual Deductible has been met for 2023
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  // border: "1px solid #ECECEC",
  border: "1px solid #ccc",
  padding: "2px",
  width: "40px",
  height: "24px",
  textAlign: "center",
  marginLeft: "5px",
  marginRight: "5px",
  borderRadius: "5px",
};

const style2 = {
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "0 4px",
  marginRight: "7px",
  width: "60px",
  height: "24px",
  color: "grey",
};

const style3 = {
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "0 4px",
  margin: "0 7px",
  width: "60px",
  height: "24px",
  color: "grey",
};

export default InsuranceBenefits;
