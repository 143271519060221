import React from "react";
import { Box } from "@mui/system";
import  styled  from 'styled-components';

export const TextWrapper = styled.div`
color: #0d6efd;
text-align: left;

& > p {
  display: block;
  margin-bottom: 0;
}
`;

const EventCards = ({ data, openModal,isDate=true }) => {
  const { event } = data;

  return (
    <Box
      sx={{
        ...styles.eventcard,
        borderLeft: event.extendedProps.borderLeft,
        backgroundColor: event.backgroundColor,
        position: "relative", // Ensure the Box has position relative for absolute positioning
      }}
      onClick={openModal}
      position="relative" // Ensure the Box itself has position relative for absolute positioning
    >
      {/* Custom left border */}
      <Box
        sx={{
          position: "absolute",
          content: '""',
          height: "100%",
          background: event?.borderColor ,
          width: "4px",
          borderRadius: "20px",
          left: 0,
          top: 0,
        }}
      />

      <TextWrapper>
        {isDate && (<p>
          <b>{`${event?.extendedProps?.starttime} - ${event?.extendedProps?.endtime}`}</b>
          </p>)
          }
        <p className="m-0">{event?.title}</p>
      </TextWrapper>
    </Box>
  );
};

export default EventCards;

const styles = {
  eventcard: {
    height: "100%",
    padding: "8px 10px",
    width: "100%",
    display: "flex",
    backgroundColor: "rgb(230, 248, 239)", // Default background color
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    position: "relative", // Ensure the Box has position relative for absolute positioning
  },
};
