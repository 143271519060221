import React, { useState } from 'react';
import { Autocomplete, TextField, Chip, Box, ListItem } from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import { TiDelete } from "react-icons/ti";
import { FaCheck } from "react-icons/fa";
import { AiOutlineStop } from "react-icons/ai";

const MultiselectAutocomplete = ({ options, placeholder,onChange,name,selectedOptions=[], isScheduleEditMode, readOnly }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleSuggestionClick = (event, value) => {
    if (!selectedOptions.find(option => option.email === value.email)) {
      onChange([...selectedOptions, value]);
    }
    setInputValue('');
  };

  const removeOption = (optionToRemove) => {
    onChange(selectedOptions.filter(option => option.email !== optionToRemove.email));
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Autocomplete
        options={options.filter(option => !selectedOptions.find(selected => selected.email === option.email))}
        getOptionLabel={(option) => option.name}
        value={null}
        name={name}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSuggestionClick}
        readOnly={readOnly}
        renderInput={(params) => (
          <Box sx={styles.input}>
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <CiSearch style={{ marginRight: 8 }} />
                    {params.InputProps.startAdornment}
                  </>
                ),
                style: { paddingLeft: 30 }, // Adjust to fit search icon
              }}
            />
          </Box>
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id || option.email}>
            {option.name}
          </ListItem>
        )}
      />
      <Box sx={{ mt: 2 }}>
        {selectedOptions.map((option) => (
          <Chip
            key={option.id || option.email}
            label={option.name}
            className='mt-1'
            // disabled prop can be used for below logic but it show remove button and reduces the opacity
            {...(!readOnly && {onDelete: ()  => { removeOption(option)} }) }
            deleteIcon={<TiDelete />}
            icon={option.isAvailable || isScheduleEditMode === "isActive" ? <FaCheck color="green" /> : <AiOutlineStop color="red" />}
            sx={{ ...styles.chip, background: option.isAvailable  || isScheduleEditMode === "isActive" ? "#e6f8ef" : "#fff0ef" }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MultiselectAutocomplete;

const styles = {
  input: {
    position: 'relative',
    width: '100%',
    background: "#F5F5F5",
    border: 0
  },
  chip: {
    borderRadius: "10px",
    ml: 1
  }
};
