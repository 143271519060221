import Slider from "@mui/material/Slider";

const CustomSlider = ({
  _id,
  sliderValue,
  setQuestionnaireData,
  questionnaireData,
}) => {
  const handleChange = (event, newSliderValue) => {
    setQuestionnaireData((prevValue) => {
      const newValue = prevValue.map((item) => {
        if (item.id === _id) {
          return { ...item, value: newSliderValue };
        } else {
          return item;
        }
      });

      return newValue;
    });
  };

  const valuetext = (sliderValue) => {
    return `${sliderValue}`;
  };

  const marks = Array.from({ length: 11 }, (_, index) => ({
    value: index,
    label: index.toString(),
  }));

  return (
    <div style={{ width: 425 }}>
      <Slider
        aria-label="Custom Slider"
        value={sliderValue}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={0}
        max={10}
        onChange={handleChange}
        sx={{
          height: "6px",
          color: sliderValue > 0 ? "#00BB61" : "primary.main",
        }}
      />
    </div>
  );
};

export default CustomSlider;
