import { Form, Row, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MultiselectAutocomplete from 'components/FormComponents/MultiselectAutocomplete';
import CustomDropdown from "components/forms/therapist/customDropdown/CustomDropdown";
import styled from 'styled-components';
import { createGroup, updateGroup } from '../../../src/lib/api/service';
import { useState, useEffect, useContext } from 'react';
import { transformPatientData, transformProviderData } from './utils';

const ModalButton = styled(Button)`
    max-width: 200px;
    width: 100%;
    font-size: 14px;
`;

const GroupModal = ({ providers = [], patients = [], groupTypes=[], saveAndFetch, selectedGroup }) => {

    const type = selectedGroup === null ? 'create' : 'edit';

    const defaultData = {
        name: '',
        selectedProviders: [],
        selectedGroupType: '',
        selectedPatients: [],
    };

    const formSchema = Yup.object().shape({
        name: Yup.string().required("Group Name is required"),
        selectedProviders: Yup.array()
            .min(1, 'At least one option must be selected')
            .required('Selection is required'),
        selectedGroupType: Yup.string().required('Group Type is required'),
        selectedPatients: Yup.array()
            .min(1, 'At least one option must be selected')
            .required('Selection is required')
    });

    const { control, register, handleSubmit, watch, formState, setValue } =
        useForm({
            resolver: yupResolver(formSchema),
            defaultValues: defaultData,
        });

    const { errors } = formState;

    const [selectedProviders, selectedPatients, selectedGroupType] = watch(
        ['selectedProviders', 'selectedPatients', 'selectedGroupType']);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (selectedGroup !== null) {
            const { name, group_type, patients, providers } = selectedGroup;

            const parsedGroupType = typeof group_type === 'string' ? parseInt(group_type) : group_type;

            const transformedProviders = transformProviderData(providers);
            const transformedPatients = transformPatientData(patients);
    
            defaultData.name = name;
            defaultData.selectedProviders = transformedProviders;
            defaultData.selectedGroupType = parsedGroupType;

            defaultData.selectedPatients = transformedPatients;
    
            setValue('name', name);
            setValue('selectedProviders', transformedProviders);
            setValue('selectedGroupType', parsedGroupType);
            setValue('selectedPatients', transformedPatients);
        }
    }, []);

    const onSubmit = async (data) => {
        const { name, selectedGroupType, selectedProviders, selectedPatients } = data;
        const obj = {
            name: name,
            group_type: selectedGroupType,
            providers: selectedProviders.map((x) => ({
                id: x.id,
                firstName: x.firstName,
                lastName: x.lastName,
                name: x.firstName + ' ' + x.lastName,
                email: x.email
            })),
            patients: selectedPatients.map((x) => ({
                id: x.id,
                firstName: x.firstName,
                lastName: x.lastName,
                name: x.firstName + ' ' + x.lastName,
                email: x.email
            })),
        };

        try {
            setSubmitting(true);
            
            if (selectedGroup !== null) {
                await updateGroup({...obj, id: selectedGroup.id})
            } else {
                await createGroup(obj);
            }
            
            saveAndFetch(true);
        } catch (e) {
            console.error(e);
        }

        setSubmitting(false);
    }

    return (
        <div className="px-4">
            <h1 className="modal-heading">{type === 'create' ? 'Create' : 'Manage'} Group</h1>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        {...register("name")}
                        id="name"
                        type="text"
                        name="name"
                        maxLength={35}
                    />
                    <div className="text-danger">{errors.name?.message}</div>
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Providers</Form.Label>
                            <Controller
                                control={control}
                                name="selectedProviders"
                                render={({ field }) => (
                                    <MultiselectAutocomplete
                                        {...field}
                                        name="providers"
                                        options={providers}
                                        selectedOptions={selectedProviders}
                                        placeholder="Search options"
                                        onChange={(providers) => field.onChange(providers)}
                                    />
                                )}
                            />
                            <div className="text-danger">{errors.selectedProviders?.message}</div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Group Types</Form.Label>
                            <Controller
                                control={control}
                                name="selectedGroupType"
                                render={({ field }) => (
                                    <CustomDropdown
                                        {...field}
                                        aria-label="types"
                                        id="types"
                                        name="selectedGroupType"
                                        options={groupTypes}
                                        selectedValue={selectedGroupType} // Use watch from react-hook-form
                                        onChange={(value) => field.onChange(value)} // Set value from react-hook-form
                                    />
                                )}
                            />
                            <div className="text-danger">{errors.selectedGroupType?.message}</div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Patients</Form.Label>
                            <Controller
                                control={control}
                                name="selectedPatients"
                                render={({ field }) => (
                                    <MultiselectAutocomplete
                                        {...field}
                                        name="patients"
                                        options={patients}
                                        selectedOptions={selectedPatients}
                                        placeholder="Search options"
                                        onChange={(patients) => field.onChange(patients)}
                                    />
                                )}
                            />
                            <div className="text-danger">
                                {errors.selectedPatients?.message}
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        <Form.Group>
                            <ModalButton size="lg" className="me-4" variant="outline-secondary" onClick={(e) => {
                                saveAndFetch(false)
                            }}>
                                Cancel
                            </ModalButton>
                            <ModalButton size="lg" variant="primary" type="submit" {...(submitting && {disabled: true})}>
                                {type === 'create' ? 'Create' : 'Update'}
                            </ModalButton>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </div>
    )

}

export default GroupModal;