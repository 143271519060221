import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import toast, { LoaderIcon } from "react-hot-toast";
import { approveDischargePatient, assignUserGetDetails } from "lib/api/service";
// import axios from "axios";
import Modal from "react-modal";
const ProfileCard = ({ patientId }) => {
  // const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");
  const [listingSinglePatient, setListingSinglePatient] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const getSingleViewProfile = async () => {
    try {
      const data = JSON.stringify({
        id: patientId,
        task: "patientdetails",
      });
      const response = await assignUserGetDetails(data);
      setListingSinglePatient(response?.data);
    } catch (error) {
      console.error(error);
    }
    // try {
    //   const data = {
    //     id: patientId,
    //     task: "patientdetails",
    //   };
    //   const config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: `${process.env.REACT_APP_BASE_API_URL}/assignuser/getdetails`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //     data: data,
    //   };
    //   const response = await axios(config);
    //   setListingSinglePatient(response?.data?.data);
    // } catch (error) {
    //   console.error(error, "Error");
    // }
  };

  useEffect(() => {
    getSingleViewProfile();
  }, [patientId]);

  const patientDetail = listingSinglePatient[0];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // previous button
  const handleGoBack = () => {
    window.history.back();
  };

  const acceptPatientRequest = async () => {
    try {
      if (patientDetail.disCharge == 2) {
        const response = await approveDischargePatient(patientId);
        if (response?.message) {
          toast.success(response.message);
          setModalOpen(false);
          handleGoBack();
        } else {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Discharge not completed");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving insurance details");
    }
  };

  return (
    <>
      <div className="col-md-4">
        {patientDetail ? (
          <div className="bg-white rounded-3 p-4 cardPaitent">
            <h2
              className=""
              style={{
                fontSize: "24px",
              }}
            >
              {patientDetail.firstName} {patientDetail.lastName}
            </h2>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img
                  src="/images/avatar.png"
                  alt="profile"
                  width={75}
                  height={75}
                />
              </div>
              <div className="flex-grow-1 ms-4">
                <div className="d-flex justify-content-between">
                  <h4>{patientDetail.patientName}</h4>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label>
                      Patient No : <span>{patientDetail.id}</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <label>
                      Age : <span>{patientDetail.dob} years</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <label>
                      Gender : <span>{patientDetail.gender}</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <label>
                      DOB : <span>{patientDetail.dob}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {["psychiatrist", "primaryTherapist", "groupTherapist"].map(
              (role, index) => (
                <div className="col-md-12 mt-3" key={index}>
                  <div className="d-flex align-items-center">
                    <label className="me-2">
                      {" "}
                      {role.charAt(0).toUpperCase() + role.slice(1)}:{" "}
                    </label>
                    <h6 className="mb-0">
                      <img src="images/doctor-pic.png" alt="" />{" "}
                      {patientDetail[`${role}`]}
                    </h6>
                    <span className="ms-2 date">
                      {" "}
                      | {patientDetail[`${role}Date`]}
                    </span>
                  </div>
                  <p className="mb-0">{patientDetail[`${role}Note`]}</p>
                </div>
              )
            )}

            <div className="col-md-12 mt-3">
              <div className="d-flex align-items-center">
                <label className="me-2">ICD-10 codes : </label>
                <p className="mb-0">{patientDetail.icdCodes}</p>
              </div>
            </div>

            <div className="col-md-12 mt-2 border-bottom pb-3">
              <div className="d-flex align-items-center">
                <label className="me-2">Clinical Tasks : </label>
              </div>
              <p className="mb-0">
                <span className="text-success">
                  {patientDetail?.clinicalTask[0]?.performer_name}
                </span>{" "}
                {patientDetail?.clinicalTask[0]?.note} <br />
                <span className="text-success text-capitalize">
                  {patientDetail?.clinicalTask[1]?.performer_name}
                </span>{" "}
                {patientDetail?.clinicalTask[1]?.note}
              </p>
            </div>

            <div className="col-md-12 mt-3">
              <div className="d-flex align-items-center mb-2">
                <label className="me-2">Previous review: </label>
                <span className="ms-2 date"> Nov 6 2023</span>
              </div>
              <div className="d-flex align-items-center">
                <label className="me-2">Upcoming review: </label>
                <span className="ms-2 date">Nov 16 2024</span>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="d-flex align-items-center">
                <label className="me-2">Insurance feedback :</label>
                <p className="mb-0">
                  Wants to avoid HLOC and approved IOP not PHP, and agrees with
                  current treatment plan.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="d-flex align-items-center">
                <label className="me-2">Claim status: </label>
                <span className="ms-2 date">
                  <i className="fas fa-check-circle text-success"></i> 5 day IOP
                </span>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="d-flex align-items-center">
                <label className="me-2">Financial communication:</label>
              </div>
              <p className="mb-0">patient has outstanding bill </p>
            </div>

            <div className="col-md-12 mt-3">
              <div className="d-flex align-items-center">
                <Link
                  to={`/profile/inatkepaperwork/${patientId}/page1`}
                  className="text-decoration-none"
                  style={{ fontWeight: 700 }}
                >
                  View intake paperwork
                </Link>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="d-flex align-items-center">
                <Link
                  to={`/profile/dischargepaperwork/${patientId}/page1`}
                  className="text-decoration-none"
                  style={{ fontWeight: 700 }}
                >
                  View discharge paperwork
                </Link>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <hr />
            </div>
            <div className="col-md-12 mt-2">
              <div className="d-flex align-items-center">
                <label className="me-2">Intake date :</label>
                <p className="mb-0">July 24 2023</p>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="d-flex align-items-center">
                <label className="me-2">Insurance firm :</label>
                <p className="mb-0">Aetna PPO</p>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="d-flex align-items-center">
                <label className="me-2">Dischaerge :</label>
                <p className="mb-0">jan 24 2024</p>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="d-flex align-items-center">
                <label className="me-2">Level of care :</label>
                <p className="mb-0">VIOP</p>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="d-flex align-items-center dischargeTxt">
                <i className="fas fa-minus-circle me-1"></i>
                <label className="me-2 dischargeTxt">
                  {userRole === "SuperAdmin" ? (
                    <Link
                      to={"#"}
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      className="text-decoration-none"
                    >
                      Discharge patient
                    </Link>
                  ) : (
                    <Link
                      to={`/profile/discharge/${patientId}/page1`}
                      className="text-decoration-none"
                    >
                      Discharge patient
                    </Link>
                  )}
                </label>
              </div>
            </div>
          </div>
        ) : (
          <LoaderIcon
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "50%",
              left: "20%",
            }}
          />
        )}
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalOpen(false)}
              ></button>
            </div>
            <div
              className="modal-body pt-0 px-4"
              style={{ width: "78%", margin: "auto" }}
            >
              <p
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontWeight: "bold",
                }}
              >
                If you discharge, this patient will be removed from active
                patient and moved to discharge patient. Do you want to proceed?
              </p>
            </div>
            <div className="col-12">
              <div className="row my-4">
                <div className="col-md-12">
                  <div className="gap-4 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary px-5 btnSize rounded-3"
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary px-5 btnSize rounded-3"
                      onClick={() => {
                        acceptPatientRequest();
                      }}
                    >
                      Discharge
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileCard;
