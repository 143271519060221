import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../../pages/Schedule/Schedule.css";
import Modal from "react-modal";
import RequestAppoinmentModal from "./RequestAppoinmentModal";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getFormattedTimes } from "utils";
import dayjs from "dayjs";
import { setScheduleStatus } from "lib/api/service";
import EventCards from "components/atoms/cards/EventCards";
import ScheduleNotes from "./ScheduleNotes";
import Loader from "components/loader/Loader";
import toast from "react-hot-toast";

import { AppointmentModalContext } from '../../pages/Schedule/ScheduleWeekly';

const ScheduleModal = ({ assessmentInfo }) => {
  const { setAppointmentModalOpen } = useContext(AppointmentModalContext);

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleShowNotes = () => setShow(true);
  const [isScheduleAppointmentModalOpen, setIsScheduleAppointmentModalOpen] =
    useState(false);
  const { setOpenAddMessageModal, carddata = {}, refetch } = assessmentInfo;
  const { data, originalData } = carddata;
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isPostActive, setIsPostActive] = useState(false);
  const [userData, setUserData] = useState(null);
  const userRole = localStorage.getItem("userRole");
  const handleCloseNotes = async () => { 
    await refetch();
    setShow(false);
    setAppointmentModalOpen(false);
   }

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    if (storedData) {
      setUserData(storedData.rows);
    }
  }, []);

  const handleOpenModel = () => {
    setIsScheduleAppointmentModalOpen(true);
    setTimeout(() => {
      handleClose();
    }, 100);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { formattedStartTime, formattedEndTime } = getFormattedTimes(
    data.date,
    data.time,
    data.duration
  );
  const date = dayjs(data.date);
  const formattedDate = date.format("dddd, D MMMM YYYY");

  const handleStatus = async (status) => {
    setLoader(true);
    handleClose();
    const response = await setScheduleStatus({
      id: data.id,
      email: userData?.email,
      accepted: status,
    });
    if (response.success) {
      toast.success(response.message);
    }
    if (response && refetch) {
      refetch();
      setOpenAddMessageModal(false);
      setLoader(false);
    }
  };

  const updatedparticipants = data?.participants?.map(({name, email, type}) => ({
    name,
    email,
    type
  }));

  const updatedData = {
    ...data,
    participants: updatedparticipants,
    addemail: data?.addemail?.[0],
  };

  const parseDuration = (duration) => {
    const [value, unit] = duration.split(" ");
    switch (unit) {
      case "mins":
        return value * 60 * 1000;
      case "hours":
        return value * 60 * 60 * 1000;
      default:
        return 0;
    }
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const meetingTime = new Date(`${updatedData.date} ${updatedData.time}`);
    const durationInMs = parseDuration(updatedData.duration);
    const meetingEndTime = new Date(meetingTime.getTime() + durationInMs);

    const difference = meetingTime - now;
    const postDifference = meetingEndTime - now;

    if (postDifference <= 0) {
      setIsPostActive(true);
    } else if (difference <= 0) {
      setIsActive(true);
      setTimeLeft(null);
    } else if (difference <= 10 * 60 * 1000) {
      setIsActive(true);
      setTimeLeft(difference);
    } else {
      setTimeLeft(difference);
    }
  };

  useEffect(() => {
    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [updatedData.date, updatedData.time, updatedData.duration]);

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="col-md-12 p-4 m modalDetails">
          <div className="roundedCustom cardPaitent">
            <div className="d-flex justify-content-between align-items-start">
              <h3 className="mb-1 schedule overflow-x-hidden">{data?.title}</h3>
              {(data?.isCreator && (userRole === "Doctor" || userRole === "SuperAdmin")) && (
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="p-0"
                  style={{ minWidth: "auto" }}>
                  <i className="fas fa-ellipsis-h"></i>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                    <MenuItem
                      onClick={handleOpenModel}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleStatus(false)}>Delete</MenuItem>
                </Menu>
              </>)}
            </div>
            <p className="mb-0">
              {formattedDate} {formattedStartTime} {formattedEndTime}
            </p>
            {userRole === "Doctor" && (
              <div className="d-flex justify-content-between gap-5 my-3 align-items-center">
                <EventCards data={originalData} isDate={false} />
                <Button
                  size="small"
                  variant="text"
                  disabled={data?.noteCreated === 1}
                  className="w-100 clinical_documentation"
                  onClick={handleShowNotes}>
                  Clinical documentation
                </Button>
              </div>)
            }

            <div className="participants mt-4">
              <h4>Participants:</h4>
              <ul className="p-0 gap-2 d-flex ">
                {data?.participants?.map((item, index) => (
                  <li
                    key={item.name + index}
                    className="d-flex flex-wrap align-items-center"
                  >
                    <i className="far fa-check"></i>
                    <img
                      src="images/avatar.png"
                      className="mx-2"
                      width={25}
                      alt=""
                    />
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="my-4">
              <h4>Appointment link:</h4>
              <p className="mb-0">
                You’re meeting with {updatedData?.appointmenttype}.
                <br />
                scheduled on{" "}
                <b>
                  {updatedData.date} {updatedData.time}
                </b>
              </p>
              {userRole === "Patient" && (<><p>Please fill Pre-session assessment before meeting</p><Link
                to={{
                  pathname: `/schudle-assisment/${updatedData.id}`,
                }}
                state={{ meetLink: updatedData?.meetLink }}
                className="mb-0"
                style={{ fontSize: "12px" }}>
                Pre-session assessment
              </Link></>)}
              <br />
              <p>{!isPostActive ? 'To join the appointment, visit this link:' : ''}</p>
              {isActive ? (
                <>
                  {timeLeft ? (
                    <p>
                      Your meeting starts in{" "}
                      <b>{formatTimeLeft(timeLeft)}...</b>
                    </p>
                  ) : null}
                  <Link
                    to={updatedData?.meetLink}
                    target="_blank"
                    className="mb-0"
                    style={{ fontSize: "12px" }}
                  >
                    {"Join Meeting"}
                  </Link>
                </>
              ) : (
                <p>
                  {!isPostActive
                    ? "The join link will be available 10 minutes before the meeting."
                    : ""}
                </p>
              )}
              {isPostActive ? (
                <>
                  {userRole === "Patient" && (<><p className="mt-2">Please fill Post-session assessment after meeting</p><Link
                    to={{
                      pathname: `/schudle-post-assisment/${updatedData.id}`,
                    }}
                    className="mb-0"
                    style={{ fontSize: "12px" }}>
                    Post-session assessment
                  </Link></>)}
                </>
              ) : null}
            </div>

            <div className="mt-4">
              <h4>Your response:</h4>
              <div className="d-flex gap-3 align-items-center">
                {!data?.accept ? (
                  <>
                    {!data?.isCreator && (<span
                      style={{ color: "green", cursor: 'pointer' }}
                      onClick={() => handleStatus(true)}>
                      <img src="icons/success.svg" />
                      Accept
                    </span>)}
                    <span
                      style={{ color: "red", cursor: 'pointer' }}
                      onClick={() => handleStatus(false)}>
                      <img src="icons/cancel.svg" alt="img" />
                      Decline
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      style={{ color: "grey" }}
                      // onClick={() => handleStatus(true)}
                    >
                      <img src="icons/success.svg" />
                      Accepted
                    </span>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleStatus(false)}
                    >
                      <img src="icons/cancel.svg" alt="img" />
                      Decline
                    </span>
                  </>
                  // <p>
                  //   <i className="far fa-check" onClick={() => handleStatus(true)}></i>Accepted
                  // </p>
                )}
                <span style={{ color: "gray" }}>
                  <img src="icons/Vector.svg" alt="Tentative icon" /> Tentative
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      <Modal
        isOpen={isScheduleAppointmentModalOpen}
        onRequestClose={() => setIsScheduleAppointmentModalOpen(false)}
      >
        {/* RequestAppoinmentModal component */}
        <RequestAppoinmentModal
          title='Edit Appointment'
          onModalhandle={setIsScheduleAppointmentModalOpen}
          defaultdata={updatedData}
          refetch={refetch}
          isScheduleEditMode={"isActive"}
        />
      </Modal>
      <ScheduleNotes
        type='create'
        data={{
          scheduleId: data.id,
          updatedData
        }}
        controls={{
          onClose: handleCloseNotes,
          show
        }}
      />
    </div>
  );
};

export default ScheduleModal;
