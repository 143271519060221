// import { DASHBOARD_TASK_DATA } from "Data";
import { usersAllPatients } from "lib/api/service";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
const AdminDashboardTaskTable = () => {
  const [listingallPatients, setListingallPatients] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await usersAllPatients();
      setListingallPatients(response?.patients);
    } catch (error) {
      console.error(error);
    }
  };

  const getDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  const getButtonDetails = (status) => {
    let className, buttonText;
    if (status === null || status === "0") {
      className = "pendingBtn";
      buttonText = "Pending";
    } else if (status === "1") {
      className = "completeBtn";
      buttonText = "Accepted";
    } else if (status === "2") {
      className = "Denied";
      buttonText = "Denied";
    }
    return { className, buttonText };
  };

  return (
    <>
      <div className="tableList">
        <Table borderless>
          <thead>
            <tr>
              <th scope="col" style={{ width: "33%", paddingBottom: "5px" }}>
                Patient Name
              </th>
              <th
                scope="col"
                style={{ width: "33%", paddingBottom: "5px" }}
                className="text-center"
              >
                Date received
              </th>
              <th
                scope="col"
                style={{ width: "33%", paddingBottom: "5px" }}
                className="text-center"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {listingallPatients?.map((data, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex">
                    <h6 className="h6_font">{`${data?.firstName} ${data?.lastName}`}</h6>
                  </div>
                </td>

                <td className="text-center">
                  <h6 className="h6_font">
                    {/* <img src={data.providerImage} alt="" /> {data.createdDate} */}
                    {getDate(data?.createdAt)}
                  </h6>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className={`btn my-btn-cls ${
                      getButtonDetails(data?.status).className
                    }`}
                  >
                    {getButtonDetails(data?.status).buttonText}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default AdminDashboardTaskTable;
